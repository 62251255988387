import {
  AddressLocationOptionType,
  FormInstance,
  LOCATION_PORT_TYPE_OPTION,
} from '@shipmnts/pixel-hub';
import { BusinessVerticalValue } from 'operations/models/BusinessVertical';
import { ServicesMap } from 'operations/modules/shipment/components/ShipmentServiceDetails';
import {
  AIRPORT_DROP_MOVEMENT_MODE,
  AIRPORT_PICKUP_MOVEMENT_MODE,
  CARGO_PICKUP_AND_DROP_MOVEMENT_MODE,
  CONTAINER_DESTUFFING_MOVEMENT_MODE,
  CONTAINER_PICKUP_AND_DROP_MOVEMENT_MODE,
  CONTAINER_STUFFING_MOVEMENT_MODE,
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
  TypeFreightType,
} from 'sales_hub/utils/constants';

// constants
export const PICKUP_NODE = 'origin';
export const PCR_NODE = 'place_of_carrier_receipt';
export const POL_NODE = 'port_of_loading';
export const CONTAINER_STUFFING_NODE = 'container_stuffing_location';
export const CONTAINER_DESTUFFING_NODE = 'container_destuffing_location';
export const POD_NODE = 'port_of_discharge';
export const PCD_NODE = 'place_of_carrier_delivery';
export const DELIVERY_NODE = 'destination';
export const INQUIRY_ROUTING_NODES = [
  PICKUP_NODE,
  DELIVERY_NODE,
  PCR_NODE,
  POL_NODE,
  POD_NODE,
  PCD_NODE,
  CONTAINER_STUFFING_NODE,
  CONTAINER_DESTUFFING_NODE,
] as const;

// helpers
export const shouldShow = (freigthType?: TypeFreightType, service?: string, node?: string) => {
  if (!freigthType || !service) return false;
  switch (node) {
    case PICKUP_NODE:
      return (
        service.startsWith('door_to_') ||
        service.endsWith('_pickup') ||
        [CARGO_PICKUP_AND_DROP_MOVEMENT_MODE, CONTAINER_PICKUP_AND_DROP_MOVEMENT_MODE].includes(
          service
        ) ||
        freigthType === FREIGHT_TYPE_ROAD
      );
    case PCR_NODE:
      return freigthType === FREIGHT_TYPE_OCEAN && service.startsWith('door_to_');
    case POL_NODE:
      return [FREIGHT_TYPE_AIR, FREIGHT_TYPE_OCEAN].includes(freigthType);
    case CONTAINER_STUFFING_NODE:
      return freigthType === FREIGHT_TYPE_ROAD && service === CONTAINER_STUFFING_MOVEMENT_MODE;
    case CONTAINER_DESTUFFING_NODE:
      return freigthType === FREIGHT_TYPE_ROAD && service === CONTAINER_DESTUFFING_MOVEMENT_MODE;
    case POD_NODE:
      return [FREIGHT_TYPE_AIR, FREIGHT_TYPE_OCEAN].includes(freigthType);
    case PCD_NODE:
      return freigthType === FREIGHT_TYPE_OCEAN && service.endsWith('_to_door');
    case DELIVERY_NODE:
      return (
        service.endsWith('_to_door') ||
        [CARGO_PICKUP_AND_DROP_MOVEMENT_MODE, CONTAINER_PICKUP_AND_DROP_MOVEMENT_MODE].includes(
          service
        ) ||
        freigthType === FREIGHT_TYPE_ROAD
      );
    default:
      return false;
  }
};

export const getDefaultNodeType = (
  freigthType?: TypeFreightType,
  service?: string | null,
  node?: string
): AddressLocationOptionType | null => {
  if (freigthType === FREIGHT_TYPE_ROAD) {
    switch (node) {
      case PICKUP_NODE:
        return [CONTAINER_STUFFING_MOVEMENT_MODE, CONTAINER_DESTUFFING_MOVEMENT_MODE].includes(
          service || ''
        ) || service === AIRPORT_PICKUP_MOVEMENT_MODE
          ? LOCATION_PORT_TYPE_OPTION
          : null;
      case DELIVERY_NODE:
        return [CONTAINER_STUFFING_MOVEMENT_MODE, CONTAINER_DESTUFFING_MOVEMENT_MODE].includes(
          service || ''
        ) || service === AIRPORT_DROP_MOVEMENT_MODE
          ? LOCATION_PORT_TYPE_OPTION
          : null;
    }
  }
  return null;
};

const formatBusinessVerticalService = (business_vertical: any): any => {
  if (!business_vertical.fields) return [];
  const extractedKeys = Object.keys(business_vertical?.fields)
    .filter((key) => key.startsWith('services.') && business_vertical.fields[key] === true)
    .map((val) => {
      return val.split('.')[1];
    });
  return extractedKeys;
};

const getServicesFieldObject = (business_vertical_services: string[]) => {
  const mergedObject = business_vertical_services.reduce((acc: any, path: string) => {
    acc[path] = true;
    return acc;
  }, {});
  return { services: mergedObject };
};
const resetServicesFieldObject = (business_vertical_services: string[]) => {
  const mergedObject = business_vertical_services.reduce((acc: any, path: string) => {
    acc[path] = false;
    return acc;
  }, {});
  return { services: mergedObject };
};

export const setBusinessVerticalValueInquiry = (
  form: FormInstance,
  BusinessVertical: BusinessVerticalValue
) => {
  form?.setFieldsValue({ ...BusinessVertical?.fields });
  form?.setFieldsValue({
    ...resetServicesFieldObject(Object.keys(ServicesMap)),
  });
  form?.setFieldsValue({
    ...getServicesFieldObject(formatBusinessVerticalService(BusinessVertical)),
  });
};
