/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import Company from 'network/models/Company';

export type FeatureKey =
  | 'stock_management'
  | 'enforce_credit_control'
  | 'allow_house_summary_screen'
  | 'consol_helper'
  | 'show_only_permitted_branch_est'
  | 'inventory_management'
  | 'restrict_change_allocation';

const CompanyAccount = types.model({
  id: types.identifier,
  display_name: types.maybe(types.maybeNull(types.string)),
  registered_name: types.maybe(types.maybeNull(types.string)),
  subdomain: types.maybe(types.maybeNull(types.string)),
  default_currency: types.maybe(types.maybeNull(types.string)),
  country_of_incorporation: types.maybe(types.maybeNull(types.string)),
  default_company: types.maybe(types.late((): IAnyModelType => Company)),

  features: types.maybeNull(
    types.model({
      stock_management: types.maybe(types.maybeNull(types.boolean)),
      enforce_credit_control: types.maybe(types.maybeNull(types.boolean)),
      allow_house_summary_screen: types.maybe(types.maybeNull(types.boolean)),
      consol_helper: types.maybe(types.maybeNull(types.boolean)),
      inventory_management: types.maybe(types.maybeNull(types.boolean)),
      show_only_permitted_branch_est: types.maybe(types.maybeNull(types.boolean)),
      restrict_change_allocation: types.maybe(types.maybeNull(types.boolean)),
    })
  ),
  is_iata_agent: types.maybe(types.maybeNull(types.boolean)),
  agent_iata_code: types.maybe(types.maybeNull(types.string)),
});

export interface CompanyAccountValue extends Instance<typeof CompanyAccount> {}
export interface CompanyAccountIn extends SnapshotIn<typeof CompanyAccount> {}
export interface CompanyAccountOut extends SnapshotOut<typeof CompanyAccount> {}

export default CompanyAccount;
