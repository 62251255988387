import { Button, Typography, FilePdfOutlined, PrintPreviewDocType } from '@shipmnts/pixel-hub';
import React, { useState } from 'react';
import BillngFilters from '../BillingFilters';
import { axiosInstance as axios } from 'common/utils/axiosDefaults';
import { generateFilters, STOCK_SUMMARY_REPORT } from '../BillingFilterHelpers';
import {
  BillingReportProvider as StockSummaryReportProvider,
  useBillingReportContext as useStockSummaryReportContext,
} from '../BillingReportProvider';
import StockSummaryReportTable from './StockSummaryReportTable';

export const fieldsRequired = [
  'wms_stock_summary_product_code',
  'wms_stock_summary_product_name',
  'wms_stock_summary_packing_type',
  'wms_stock_summary_lot_number',
  'wms_stock_summary_qty',
  'wms_stock_summary_gross_volume',
];

function StockSummaryReport() {
  const { form, setReportRows } = useStockSummaryReportContext();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [context, setContext] = useState<any>(null);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const transformFormData = (values: any) => {
    const filterMapping: { [key: string]: { [key: string]: any } } = {
      customer: {
        id: 'wms_stock_summary_customer_company_id',
        operator: 'equals',
      },
      warehouse: {
        id: 'wms_stock_summary_branch_id',
        operator: 'equals',
      },
    };
    const formattedValues: { [key: string]: any } = {
      customer: values?.customer,
      warehouse: values?.warehouse?.branch,
    };
    return {
      fields: fieldsRequired.map((e) => {
        return {
          id: e,
        };
      }),
      filters: generateFilters(filterMapping, formattedValues),
      doc_type_id: 'Wms::StockSummary',
      apply_limit_offset: false,
    };
  };
  const fetchData = async (values: any) => {
    const payload = transformFormData(values);
    try {
      setDataLoading(true);
      const response = await axios.post(
        `${process.env.REPORT_MANAGER_API_URL}/doc_type/execute`,
        payload
      );
      const responseData = {
        data: response.data.data,
      };
      setReportRows(responseData);
      setContext({ ...payload, values }); //Setting context data for PDF Preview with generated report data
      return responseData;
    } catch (error) {
      console.error(error);
      return { error };
    } finally {
      setDataLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          borderBottom: '1px solid #dadee7',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 24px',
        }}
      >
        <Typography.Title level={4} style={{ margin: 0 }}>
          Warehouse Management / Stock Summary Report
        </Typography.Title>

        <div>
          <Button
            disabled={dataLoading}
            loading={dataLoading}
            size="small"
            htmlType="submit"
            onClick={form.submit}
            style={{ fontWeight: 'bold', marginRight: '10px' }}
            type="primary"
          >
            Generate
          </Button>
          <Button
            disabled={!context}
            size="small"
            style={{ marginLeft: '10px' }}
            icon={<FilePdfOutlined />}
            onClick={() => setShowPreview(true)}
          >
            PDF
          </Button>
          {showPreview && (
            <PrintPreviewDocType
              docType={'Wms::StockSummary'}
              resource_id={'report'}
              context={context}
              onClose={() => setShowPreview(false)}
            />
          )}
        </div>
      </div>

      <BillngFilters onSuccess={fetchData} report_type={STOCK_SUMMARY_REPORT} />
      <StockSummaryReportTable />
    </>
  );
}

const StockSummaryReportLayout = () => {
  return (
    <StockSummaryReportProvider>
      <StockSummaryReport />
    </StockSummaryReportProvider>
  );
};
export default StockSummaryReportLayout;
