import React, { useEffect, useMemo } from 'react';
import { startCase as _startCase } from 'lodash';
import {
  Col,
  CompanySearch,
  CustomIcon,
  DatePicker,
  Form,
  GlobalSearch,
  Input,
  Radio,
  Row,
  Select,
} from '@shipmnts/pixel-hub';

import { useSession } from 'common';
import {
  ALL_PRIORITIES,
  ALL_SERVICES,
  BUSINESS_RECEIVED_THROUGH_OPTION,
  BUSINESS_THROUGH_DIRECT_OPTION,
  FREIGHT_TYPE_ROAD,
  freightTypeIconProps,
  LOAD_TYPE_OPTIONS,
  LOAD_TYPE_TOOLTIP_TEXT,
  MOVEMENT_MODES,
  RENDER_INQUIRY_FOR_WON,
  SALES_PERSON_DISABLED_FEATURE,
  TRADE_TYPE_CROSS_TRADE,
  TRADE_TYPE_DOMESTIC,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
} from 'sales_hub/utils/constants';
import { useInquiryForm } from './InquiryForm';
import { TypeFreightType } from 'operations/utils/constants';
import { useLazyQuery } from '@apollo/client';
import { GET_SALES_PERSON_FOR_CUSTOMER } from 'sales_hub/graphql/contact';
import { TeamValue } from 'common/models/Team';
import { INQUIRY_ROUTING_NODES, shouldShow, setBusinessVerticalValueInquiry } from './helper';
import { getSnapshot } from 'mobx-state-tree';
const InquiryBasicDetails = () => {
  const session = useSession();
  const {
    freightType,
    isDetailForm,
    customer,
    branchAccount,
    form,
    businessReceivedThrogh,
    isAmend,
    disable,
    renderForm,
    getInitialServices,
  } = useInquiryForm();

  const [getSalesPersonForCustomer, { data: getSalesPersonData, loading, error }] = useLazyQuery(
    GET_SALES_PERSON_FOR_CUSTOMER
  );

  const isClearanceInquiry = useMemo(() => {
    const services = getInitialServices();
    return (
      !services.includes('freight_forwarding') &&
      (services.includes('origin_custom_clearance') ||
        services.includes('destination_custom_clearance'))
    );
  }, [getInitialServices]);

  const isSalesPersonDisabled = useMemo(
    () => session?.isFeatureEnabled(SALES_PERSON_DISABLED_FEATURE),
    [session]
  );

  // when customer changes, we fetch sales person
  useEffect(() => {
    const customerId = customer?.party_company?.id;
    if (!customerId) return;
    getSalesPersonForCustomer({
      variables: {
        customer_company_id: customerId,
        branch_id: branchAccount?.id,
      },
    });
  }, [customer, branchAccount, getSalesPersonForCustomer]);
  // once seles person\ is fetched we change form field value
  useEffect(() => {
    if (getSalesPersonData && !loading && !error) {
      form.setFieldValue('salesPerson', getSalesPersonData.get_sales_person_for_customer);
    }
  }, [getSalesPersonData, loading, error, form]);
  return (
    <>
      <h3 className="inq-form-section-title">{'Basic Details'}</h3>
      <Row gutter={{ lg: 16, sm: 8, xs: 8 }}>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="business_vertical"
            label="Business Vertical"
          >
            <GlobalSearch
              disabled={renderForm === RENDER_INQUIRY_FOR_WON}
              onChange={(val) => {
                const business_vertical: any = getSnapshot(val);
                if (!business_vertical && isAmend) return;
                setBusinessVerticalValueInquiry(form, business_vertical);
              }}
              doc_type="Network::BusinessVertical"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="freight_type" label="Freight Type" rules={[{ required: true }]}>
            <Radio.Group disabled={isAmend || disable}>
              {Object.keys(freightTypeIconProps).map((type) => {
                const { height, width, icon } = freightTypeIconProps[type as TypeFreightType] || {};
                return (
                  <Radio.Button value={type} key={type}>
                    <span>
                      <CustomIcon
                        height={height || '12'}
                        width={width || '12'}
                        style={{ marginRight: '6px' }}
                        icon={icon || '12'}
                      />
                      {_startCase(type)}
                    </span>
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item noStyle shouldUpdate>
            {() => {
              const commonTradeTypes = [TRADE_TYPE_IMPORT, TRADE_TYPE_EXPORT]; // first option as default value
              return (
                <Form.Item name="trade_type" label="Trade Type" rules={[{ required: true }]}>
                  {freightType === FREIGHT_TYPE_ROAD ? (
                    // using dropdown as road have more than 2 trade types
                    <Select
                      disabled={isAmend || disable}
                      defaultValue={commonTradeTypes[0]}
                      options={[
                        ...commonTradeTypes,
                        TRADE_TYPE_DOMESTIC,
                        TRADE_TYPE_CROSS_TRADE,
                      ].map((type) => ({ value: type, label: _startCase(type.replace('_', ' ')) }))}
                    />
                  ) : (
                    <Radio.Group defaultValue={commonTradeTypes[0]} disabled={isAmend || disable}>
                      {[TRADE_TYPE_IMPORT, TRADE_TYPE_EXPORT].map((type) => (
                        <Radio.Button key={type} value={type}>
                          {_startCase(type.replace('_', ' '))}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  )}
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item noStyle shouldUpdate>
            {() => {
              return (
                <Form.Item
                  name="load_type"
                  label="Load Type"
                  dependencies={['freight_type']}
                  rules={[{ required: true }]}
                  tooltip={LOAD_TYPE_TOOLTIP_TEXT[freightType]}
                >
                  {freightType === FREIGHT_TYPE_ROAD ? (
                    <Select
                      disabled={isAmend || disable}
                      options={LOAD_TYPE_OPTIONS[FREIGHT_TYPE_ROAD]}
                    />
                  ) : (
                    <Radio.Group disabled={isAmend || disable}>
                      {LOAD_TYPE_OPTIONS[freightType || 'air'].map((type) => (
                        <Radio.Button key={type.value} value={type.value}>
                          {type.label}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  )}
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        {!isClearanceInquiry && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const freightType = getFieldValue('freight_type');
                const isRoadInquiry = freightType === FREIGHT_TYPE_ROAD;
                const options = isRoadInquiry ? MOVEMENT_MODES : ALL_SERVICES;
                return (
                  <Form.Item
                    label={isRoadInquiry ? 'Movement Mode' : 'Service Type'}
                    name={isRoadInquiry ? 'movement_mode' : 'service_type'}
                    initialValue={options[0]}
                    tooltip={
                      isRoadInquiry
                        ? 'Routing will change basis the movement mode selection. Eg: Select Container Pickup & Drop option to add details around pickup location, stuffing location & delivery location. Or select Container / Cargo Pickup & Drop to add details around pickup location and delivery location.'
                        : 'Routing will change basis the service type selection. Eg: Select Door - Door option, if you need to add pickup / delivery location. Select Port - Port option to add port details.'
                    }
                  >
                    <Select
                      onSelect={(val) => {
                        const resetRoutingNodes = [...INQUIRY_ROUTING_NODES]
                          .filter((node) => !shouldShow(freightType, val, node)) // Filter out nodes that should not be shown
                          .reduce((acc, node) => {
                            acc[node] = undefined;
                            return acc;
                          }, {} as Record<string, undefined>);
                        form.setFieldsValue(resetRoutingNodes);
                      }}
                      options={options.map((option) => ({
                        value: option,
                        label: _startCase(option.replace('_', ' ')),
                      }))}
                      defaultValue={options[0]}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}

        {!isDetailForm && (
          <>
            <Col xs={24} sm={12} lg={!isClearanceInquiry ? 9 : 6}>
              <Form.Item
                name="business_received_through"
                label="Business Received Through"
                rules={[{ required: true }]}
                tooltip="Source of business generated - allows you to classify self generated or business referred from an overseas nomination or a third-party subagent"
              >
                <Radio.Group
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === 'direct') {
                      form.setFieldValue('billing_party', customer);
                    }
                  }}
                  options={BUSINESS_RECEIVED_THROUGH_OPTION.map((opt) => ({
                    value: opt.value,
                    label: opt.label || _startCase(opt.value),
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={!isClearanceInquiry ? 9 : 6}>
              <Form.Item
                name={['customer', 'party_company']}
                label="Customer"
                rules={[{ required: true }]}
              >
                <CompanySearch searchProps={{ is_lead: true }} showCreditStatusTag />
              </Form.Item>
            </Col>

            {businessReceivedThrogh !== BUSINESS_THROUGH_DIRECT_OPTION && (
              <Col xs={24} sm={12} lg={6}>
                <Form.Item dependencies={['business_received_through']}>
                  {({ getFieldValue }) => {
                    const businessReceivedThrough = getFieldValue('business_received_through');
                    return (
                      businessReceivedThrough !== BUSINESS_THROUGH_DIRECT_OPTION && (
                        <Form.Item
                          name={['billing_party', 'party_company']}
                          label="Agent Company"
                          rules={[{ required: true }]}
                          dependencies={['business_received_through', 'customer']}
                          tooltip="Agent through which you received customer."
                        >
                          <GlobalSearch doc_type="Network::Company" />
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>
              </Col>
            )}
          </>
        )}
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="branchAccount" label="Branch" rules={[{ required: true }]}>
            <GlobalSearch
              doc_type="Network::BranchAccount"
              extraProps={{
                options: session.branch_accounts,
              }}
              selectProps={{ popupMatchSelectWidth: true }}
            />
          </Form.Item>
        </Col>
        {freightType !== FREIGHT_TYPE_ROAD && !isClearanceInquiry && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item
              name="incoterms"
              label="Incoterms"
              tooltip="Standardized international trade terms that clarify the responsibilities between buyers and sellers for the delivery of goods"
            >
              <GlobalSearch doc_type="Global::Incoterms" />
            </Form.Item>
          </Col>
        )}
        {!isDetailForm && (
          <>
            <Col xs={24} sm={12} lg={6}>
              <Form.Item name="salesPerson" label="Sales Person" rules={[{ required: true }]}>
                <GlobalSearch
                  doc_type="Network::SalesPerson"
                  onChange={(val) => {
                    const team: TeamValue[] = form.getFieldValue('teams');
                    // if there is already team field
                    if (!!team) {
                      form.setFieldValue(
                        'teams',
                        team.map((t) => {
                          // replacing salesperson value
                          if (t.role === 'sales') {
                            return {
                              ...t,
                              sales_person: val,
                            };
                          }
                          return t;
                        })
                      );
                      return;
                    }
                    // if no team exists, create one...
                    form.setFieldValue('teams', [
                      ...(team || []),
                      { role: 'sales', sales_person: val },
                    ]);
                  }}
                  disabled={isSalesPersonDisabled}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <Form.Item name="pricingPerson" label="Pricing Person">
                <GlobalSearch
                  doc_type="Network::UserContact"
                  onChange={(val) => {
                    const team: TeamValue[] = form.getFieldValue('teams');
                    const hasPricing = team?.some((t) => t.role === 'pricing');
                    if (hasPricing) {
                      form.setFieldValue(
                        'teams',
                        team.map((t) => {
                          if (t.role === 'pricing') {
                            return {
                              ...t,
                              user_contact: val,
                            };
                          }
                          return t;
                        })
                      );
                      return;
                    }

                    form.setFieldValue('teams', [
                      ...(team || []),
                      { role: 'pricing', user_contact: val },
                    ]);
                  }}
                />
              </Form.Item>
            </Col>
          </>
        )}
        {isDetailForm && (
          <>
            <Col xs={24} sm={12} lg={6}>
              <Form.Item
                name="priority"
                label="Priority"
                tooltip="The urgency of the Inquiry, Highest priority indicates that the Inquiry needs faster turn around on pricing"
              >
                <Select
                  placeholder="Please choose the type"
                  options={ALL_PRIORITIES.map((opt) => ({ value: opt, label: _startCase(opt) }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <Form.Item name="received_at" label="Inquiry Received On">
                <DatePicker showTime={true} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="inquiryDescription" label="Inquiry Description">
                <Input.TextArea
                  rows={3}
                  placeholder="Enter comments or inquiry as received from customer"
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default InquiryBasicDetails;
