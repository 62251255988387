import { GridOptions, RowNode } from '@ag-grid-community/core';
import { Form, message, Modal, Select, Col, Row, BaseTable, Button } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { Column } from 'operations/models/Report';
import { observer } from 'mobx-react-lite';
import { startCase as _startCase, uniq as _uniq, get as _get } from 'lodash';
import {
  ShipmentEstimateValue,
  useShipmentEstimateStore,
} from 'operations/models/ShipmentEstimate';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DOC_TYPE_SALES_INVOICE,
  DOC_TYPE_PURCHASE_INVOICE,
  NATURE_OF_TRANSACTION_JOB,
  NATURE_OF_TRANSACTION_INTERBRANCH,
} from '../Accounts/constants';
import { COMPANY_GROUP_INTERBRANCH } from 'network/constants';
import AddMissingFieldsBeforeInvoice from './AddMissingFieldsBeforeInvoice';
import { useShipmentEstimate } from './ShipmentEstimateLayout';
import { useMutation } from '@apollo/client';
import { HANDOVER_TO_BILLING } from 'operations/graphql/shipmentEstimate';
import { errorMessageHandlerGraphQLString } from 'common';
import { ItemCellRenderer } from './ItemCellRenderer';
import { ShipmentValue } from 'operations/models/Shipment';
import { ActionRendererDetailReport } from 'operations';
import { useErpNextConfig } from 'network/utils/ErpNextConfigDataWrapper';
import { SP_FLOW } from 'network/constants';

interface CreateInvoiceModalType {
  onClose: () => void;
  invoice_type: string;
  shipment: ShipmentValue;
}

const validateSalesInvoiceRows = (nodes: RowNode[]) => {
  const firstNode = nodes[0];
  const matches = nodes.every(
    (node) =>
      node.data.customer_company?.id === firstNode.data.customer_company?.id &&
      node.data.sell_branch_id === firstNode.data.sell_branch_id
  );
  if (!matches)
    return {
      success: false,
      error: 'Please make sure all the rows selected have the same customer and branch',
    };
  const allDataPresent = nodes.every(
    (node) =>
      node.data.sell_branch_id &&
      (node.data.sell_rate !== null || node.data.sell_rate !== undefined) &&
      node.data.sell_currency &&
      node.data.sell_exchange_rate &&
      node.data.quantity
  );
  if (!allDataPresent)
    return {
      success: false,
      error: 'Selected rows have data missing. Please add all data in the rows',
    };
  return { success: true };
};

const validatePurchaseInvoiceRows = (nodes: RowNode[]) => {
  const firstNode = nodes[0];
  const matches = nodes.every(
    (node) =>
      node.data.supplier_company?.id === firstNode.data.supplier_company?.id &&
      node.data.buy_branch_id === firstNode.data.buy_branch_id
  );
  if (!matches)
    return {
      success: false,
      error: 'Please make sure all the rows selected have the same supplier and branch',
    };
  const allDataPresent = nodes.every(
    (node) =>
      node.data.supplier_company &&
      node.data.buy_branch_id &&
      (node.data.buy_rate !== null || node.data.buy_rate !== undefined) &&
      node.data.buy_currency &&
      node.data.buy_exchange_rate &&
      node.data.quantity
  );
  if (!allDataPresent)
    return {
      success: false,
      error: 'Selected rows have data missing. Please add all data in the rows',
    };
  return { success: true };
};

// warning for sell less than buy

function mergeArrays(arrayA: any, arrayB: any) {
  return arrayA.map((a: any) => {
    const match = arrayB.find((b: any) => b.name === a.erp_cost_center_id);
    return match ? { ...match, ...a } : a;
  });
}

const CreateInvoiceModal = observer(function CreateInvoiceModal(props: CreateInvoiceModalType) {
  const { store } = useShipmentEstimateStore();
  const { branches, configData } = useShipmentEstimate();
  const { onClose, invoice_type, shipment } = props;
  const gridRef = useRef<GridOptions>();

  let shipmentSubNature = _startCase(`${shipment?.freight_type} ${shipment?.trade_type}`);
  if (shipment?.shipment_type === 'general') {
    shipmentSubNature = _startCase(shipment.shipment_type);
  }
  if (shipment?.shipment_type === 'warehouse') {
    if (shipment?.services?.warehouse?.storage) shipmentSubNature = 'Warehouse Storage';
    if (shipment?.services?.warehouse?.handling) shipmentSubNature = 'Warehouse Handling';
  }
  const subNatures = [shipmentSubNature, 'Rebate'].map((s) => {
    return { value: s, label: s };
  });

  const [branchOptions, setBranchOptions] = useState<any[]>([]);
  const [supplierOptions, setSupplierOptions] = useState<any[]>([]);
  const [customerOptions, setCustomerOptions] = useState<any[]>([]);
  const formattedBranchOptions = branchOptions.map((s) => {
    return { value: s.id, label: s.name };
  });
  const formattedSupplierOptions = supplierOptions.map((s) => {
    return { value: s.id, label: s.name };
  });
  const formattedCustomerOptions = customerOptions.map((s) => {
    return { value: s.id, label: s.name };
  });
  const [selectedBranch, setSelectedBranch] = useState(undefined);
  const [selectedSupplier, setSelectedSupplier] = useState(undefined);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [subNature, setSubNature] = useState(shipmentSubNature);
  const [filteredDataSource, setFilteredDataSource] = useState<any[]>([]);

  const [handoverToBilling, { data, error, loading }] = useMutation(HANDOVER_TO_BILLING);

  const { company_account } = useSession();
  const costCenters = _get(configData, 'cost_centers');
  const erpnextContext = useErpNextConfig();
  const { erpnextConfigData } = erpnextContext;

  useEffect(() => {
    let dataSource;
    let BRANCH_KEY: 'buy_branch_id' | 'sell_branch_id';
    if (invoice_type === DOC_TYPE_SALES_INVOICE) {
      dataSource = store.estimatesForSalesInvoice(shipment.id);
      BRANCH_KEY = 'sell_branch_id';
      const allCustomers = Object.values(
        dataSource.reduce((acc: any, i: ShipmentEstimateValue) => {
          if (i['customer_company']?.id && !acc[i['customer_company'].id])
            acc[i['customer_company']?.id] = i['customer_company'];
          return acc;
        }, {})
      ).map((s: any) => ({ ...s, name: s.registered_name }));

      if (allCustomers.length === 0) {
        message.error('Please update Customer in Estimate');
      }

      const selectedCustomer = allCustomers.length >= 1 ? allCustomers[0].id : undefined;
      setSelectedCustomer(selectedCustomer);
      setCustomerOptions(allCustomers);
    } else {
      dataSource = store.estimatesForPurchaseInvoice(shipment.id);
      BRANCH_KEY = 'buy_branch_id';
      const allSuppliers = Object.values(
        dataSource.reduce((acc: any, i: ShipmentEstimateValue) => {
          if (i['supplier_company']?.id && !acc[i['supplier_company'].id])
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            acc[i['supplier_company']?.id] = i['supplier_company'];
          return acc;
        }, {})
      ).map((s: any) => ({ ...s, name: s.registered_name }));

      if (allSuppliers.length === 0) {
        message.error('Please update Supplier in Estimate');
      }

      const selectedSupplier = allSuppliers.length >= 1 ? allSuppliers[0].id : undefined;
      setSelectedSupplier(selectedSupplier);
      setSupplierOptions(allSuppliers);
    }
    const allBranches = _uniq(dataSource.map((i) => i[BRANCH_KEY]))
      .map((branch_id) => branches.find((branch: any) => branch.id === branch_id))
      .filter(Boolean) as any[];
    const selectedBranch = allBranches.length >= 1 ? _get(allBranches, '[0].id') : undefined;
    setSelectedBranch(selectedBranch);

    const mergeBranches = mergeArrays(allBranches, costCenters);
    setBranchOptions(mergeBranches);
  }, [branches, costCenters, invoice_type, shipment.id, store]);

  useEffect(() => {
    let dataSource: any[] = [];
    if (invoice_type === DOC_TYPE_SALES_INVOICE) {
      dataSource = store
        .estimatesForSalesInvoice(shipment.id)
        .filter(
          (estimate) =>
            estimate['sell_branch_id'] === selectedBranch &&
            estimate['customer_company_id'] === selectedCustomer
        );
    } else {
      dataSource = store
        .estimatesForPurchaseInvoice(shipment.id)
        .filter(
          (estimate) =>
            estimate['buy_branch_id'] === selectedBranch &&
            estimate['supplier_company_id'] === selectedSupplier
        );
    }
    setFilteredDataSource(dataSource);
  }, [selectedBranch, selectedSupplier, selectedCustomer, invoice_type, shipment.id, store]);

  const openInvoiceUrl = useCallback(() => {
    const getParams = (node: RowNode) => {
      const params: any = {};

      if (invoice_type !== DOC_TYPE_SALES_INVOICE) {
        params['supplier'] = node.data.supplier_company?.registered_name;
      }
      params['nature_of_transaction'] = NATURE_OF_TRANSACTION_JOB;
      params['sub_nature'] = subNature;

      const isNatureInterbranch =
        invoice_type === DOC_TYPE_SALES_INVOICE
          ? node.data.customer_company?.company_group === COMPANY_GROUP_INTERBRANCH
          : node.data.supplier_company?.company_group === COMPANY_GROUP_INTERBRANCH;

      const isSpEnabled = erpnextConfigData?.create_interbranch_invoices_automatically === SP_FLOW;

      if (isNatureInterbranch && isSpEnabled) {
        params['nature_of_transaction'] = NATURE_OF_TRANSACTION_INTERBRANCH;
        params['sub_nature'] = NATURE_OF_TRANSACTION_INTERBRANCH;
      }
      const branch_to_consider =
        invoice_type === DOC_TYPE_SALES_INVOICE
          ? node.data.sell_branch_id
          : node.data.buy_branch_id;
      const matchedBranch = branches.find((branch: any) => branch.id === branch_to_consider);

      params['cost_center'] = matchedBranch?.erp_cost_center_id;
      params['company'] = matchedBranch?.company;
      const additionalParams: string[] = [];

      Object.keys(params).forEach(function (key) {
        const value = params[key];
        additionalParams.push(`${key}=${window.encodeURIComponent(value)}`);
      });

      return additionalParams.join('&');
    };

    const subdomain = company_account.subdomain;
    if (!subdomain) {
      message.error('There is a problem in configuring Finance domain. Please contact support');
      return;
    }
    const selectedNodes = (gridRef.current?.api?.getSelectedNodes() || []).filter(
      (node) => !node.group
    );
    const selectedRows = selectedNodes.map((node) => node.data.id);
    window.open(
      window.encodeURI(
        `${subdomain}/desk#Form/${invoice_type}/New ${invoice_type}/estimate_ids/${window.encodeURIComponent(
          JSON.stringify(selectedRows)
        )}?${getParams(selectedNodes[0])}`
      )
    );
    onClose();
  }, [company_account.subdomain, branches, subNature, invoice_type, onClose, erpnextConfigData]);

  useEffect(() => {
    if (data?.handover_to_billing) {
      openInvoiceUrl();
    }
    if (error) {
      console.error(error);
      message.error(errorMessageHandlerGraphQLString(error));
    }
  }, [data, error, openInvoiceUrl]);

  if (!shipment) return <></>;

  if (
    invoice_type === DOC_TYPE_SALES_INVOICE &&
    (!shipment.customer_company || !shipment.sales_agent)
  ) {
    return (
      <AddMissingFieldsBeforeInvoice
        shipment={shipment}
        onCancel={onClose}
        onOk={(values) => {
          shipment.updateCustomerAndSalesAgent(values);
        }}
      />
    );
  }

  let columnDefs: Column[] = [
    {
      headerName: 'Item Name',
      field: 'item',
      width: 250,
      minWidth: 200,
      pinned: 'left',
      columnType: 'String',
      cellRenderer: 'ItemCellRenderer',
      cellRendererParams: {
        shipment: shipment,
        linkTarrifEditable: false,
      },
    },
    {
      headerName: 'Basis',
      field: 'uom',
      columnType: 'String',
      minWidth: 100,
      valueGetter: (params) =>
        params.data?.equipment_name
          ? `${params.data.uom}.${params.data.equipment_name}.${params.data.equipment_type}`
          : params.data?.uom,
      valueFormatter: (params) =>
        params.data?.equipment_name
          ? `${params.data.uom} / ${params.data.equipment_name}`
          : params.data?.uom,
    },
    {
      headerName: 'Qty',
      field: 'quantity',
      columnType: 'Float',
      minWidth: 100,
      cellClass: (params) => {
        if (!params.data?.quantity && params.data.id) {
          return 'table-missing-rows';
        } else return '';
      },
      aggFunc: undefined,
    },
  ];

  if (invoice_type === DOC_TYPE_SALES_INVOICE) {
    columnDefs = columnDefs.concat([
      {
        headerName: 'Rate',
        field: 'sell_rate',
        columnType: 'Currency',
        minWidth: 160,
        aggFunc: undefined,
        cellClass: (params) => {
          if (
            (params.data?.sell_rate === null ||
              params.data?.sell_rate === undefined ||
              params.data?.sell_rate === 0) &&
            params.data.id
          ) {
            return 'table-missing-rows';
          } else return '';
        },
        cellRendererParams: {
          currency_column: 'sell_currency',
          precision: 4,
        },
      },
      {
        headerName: 'Ex. Rate',
        field: 'sell_exchange_rate',
        columnType: 'Float',
        cellRendererParams: {
          precision: 5,
        },
        cellClass: (params) => {
          if (!params.data?.sell_exchange_rate && params.data.id) return 'table-missing-rows';
          else return '';
        },
        minWidth: 100,
        aggFunc: undefined,
      },
      {
        headerName: 'Sell Total',
        field: 'total_sell_amount',
        columnType: 'Float',
        cellRendererParams: {
          precision: 2,
        },
        minWidth: 100,
      },
      {
        headerName: 'Terms',
        field: 'sell_terms',
        columnType: 'String',
        minWidth: 100,
      },
      {
        headerName: 'Customer',
        field: 'customer_company',
        columnType: 'String',
        valueGetter: (params) => params.data?.customer_company?.registered_name,
        minWidth: 100,
      },
      {
        headerName: 'Cost Center',
        field: 'sell_branch_id',
        valueGetter: (params) =>
          branches.find((branch: any) => branch.id === params.data?.sell_branch_id)?.name,
        columnType: 'String',
        minWidth: 100,
        cellClass: (params) => {
          if (!params.data?.sell_branch_id && params.data.id) return 'table-missing-rows';
          else return '';
        },
      },
    ]);
  } else {
    columnDefs = columnDefs.concat([
      {
        headerName: 'Rate',
        field: 'buy_rate',
        columnType: 'Currency',
        minWidth: 160,
        aggFunc: undefined,
        cellClass: (params) => {
          if (
            (params.data?.buy_rate === null ||
              params.data?.buy_rate === undefined ||
              params.data?.buy_rate === 0) &&
            params.data.id
          ) {
            return 'table-missing-rows';
          } else return '';
        },
        cellRendererParams: {
          currency_column: 'buy_currency',
          precision: 4,
        },
      },
      {
        headerName: 'Ex. Rate',
        field: 'buy_exchange_rate',
        columnType: 'Float',
        cellRendererParams: {
          precision: 5,
        },
        cellClass: (params) => {
          if (!params.data?.buy_exchange_rate && params.data.id) {
            return 'table-missing-rows';
          } else return '';
        },
        minWidth: 100,
        aggFunc: undefined,
      },
      {
        headerName: 'Buy Total',
        field: 'total_buy_amount',
        columnType: 'Float',
        cellRendererParams: {
          precision: 2,
        },
        minWidth: 100,
      },
      {
        headerName: 'Terms',
        field: 'buy_terms',
        columnType: 'String',
        minWidth: 100,
      },
      {
        headerName: 'Supplier',
        field: 'supplier_company',
        columnType: 'String',
        valueGetter: (params) => params.data?.supplier_company?.registered_name,
        cellClass: (params) => {
          if (!params.data?.supplier_company && params.data.id) return 'table-missing-rows';
          else return '';
        },
        minWidth: 100,
      },
      {
        headerName: 'Cost Center',
        field: 'buy_branch_id',
        valueGetter: (params) =>
          branches.find((branch: any) => branch.id === params.data?.buy_branch_id)?.name,
        columnType: 'String',
        cellClass: (params) => {
          if (!params.data?.buy_branch_id && params.data.id) return 'table-missing-rows';
          else return '';
        },
        minWidth: 100,
      },
    ]);
  }

  const onOk = async () => {
    const selectedNodes = (gridRef.current?.api?.getSelectedNodes() || []).filter(
      (node) => !node.group
    );
    if (selectedNodes.length === 0) {
      message.error('Please select rows');
      return;
    }
    const response =
      invoice_type === DOC_TYPE_SALES_INVOICE
        ? validateSalesInvoiceRows(selectedNodes)
        : validatePurchaseInvoiceRows(selectedNodes);
    if (!response.success) {
      message.error(response.error);
      return;
    }

    const dt = new Date();
    const tz = dt.getTimezoneOffset() * 60 * -1; // convert it into seconds.
    handoverToBilling({
      variables: {
        shipment_id: shipment.id,
        utc_time_zone_in_seconds: tz,
      },
    });
  };
  const createText = `Create ${invoice_type}`;

  const hasCostCenterPermission = !!_get(
    branchOptions.find((ele) => ele.id === selectedBranch),
    'has_permission'
  );

  return (
    <Modal
      title={`Select Items for ${invoice_type}`}
      open
      width="80vw"
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          {'Close'}
        </Button>,
        <Button
          key="Create"
          disabled={!hasCostCenterPermission}
          loading={loading}
          onClick={onOk}
          type="primary"
        >
          {createText}
        </Button>,
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Form.Item label="Sub Nature">
            <Select
              options={subNatures}
              value={subNature}
              onChange={(value) => setSubNature(value)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Branch">
            <Select
              options={formattedBranchOptions}
              value={selectedBranch}
              onChange={(value) => setSelectedBranch(value)}
            />
          </Form.Item>
        </Col>
        {invoice_type === DOC_TYPE_PURCHASE_INVOICE ? (
          <Col span={12}>
            <Form.Item label="Supplier">
              <Select
                options={formattedSupplierOptions}
                value={selectedSupplier}
                onChange={(value) => setSelectedSupplier(value)}
              />
            </Form.Item>
          </Col>
        ) : (
          <Col span={12}>
            <Form.Item label="Customer">
              <Select
                options={formattedCustomerOptions}
                value={selectedCustomer}
                onChange={(value) => setSelectedCustomer(value)}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <BaseTable
        reportName={'create_invoice'}
        rowSelection="multiple"
        height={'55vh'}
        gridRef={gridRef}
        columns={columnDefs}
        rowData={filteredDataSource}
        showCheckBoxOnHeader
        reportConfig={{
          groupDefaultExpanded: 2,
          groupSelectsChildren: true,
          autoGroupColumnDef: {
            headerName: 'Group',
            field: 'item',
            filter: 'agTextColumnFilter',
            minWidth: 100,
            maxWidth: 400,
            pinned: 'left',
          },
          components: {
            ItemCellRenderer,
            ActionRendererDetailReport,
          },
        }}
      />
    </Modal>
  );
});

export default CreateInvoiceModal;
