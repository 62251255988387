import React, { ReactNode } from 'react';
import './index.css';
import { Link, Route, Switch, useLocation, useParams, Redirect } from 'wouter';
import ErrorBoundary from 'common/utils/ErrorBoundary';
import App from 'common/App';
import ApplicationLayoutContent, {
  useApplicationContentContext,
  useEmailTemplatesContext,
  useEmailDrawerContext,
} from 'common/ApplicationLayoutContent';
import { ConfigProvider } from '@shipmnts/pixel-hub';
import { enUS } from '@shipmnts/pixel-hub';
import { defaultValidateMessages } from './utils/antFormMessages';
import {
  CompanyForm,
  ErpNextCompanyConfigDataWrapper,
  ErpNextConfigDataWrapper,
  TemplateDetailLayout,
  useErpNextConfig,
  AddressForm,
  useErpNextCompanyConfigDataContext,
} from 'network';
import { axiosInstance, erpNextAxios } from './utils/axiosDefaults';
import { useSession } from './utils/SessionContext';
import { useRouteConfirmation } from './utils/hooks';
import CreateVehicleDrawer from './components/Vehicle/CreateVehicleDrawer';
import CreateDriverDrawer from './components/Driver/CreateDriverDrawer';
import { AuthKitProvider } from '@workos-inc/authkit-react';

export type WrapperProps = {
  children?: ReactNode;
  graphqlUrl?: string; //to create client based on mfe env
};

const useLocationWrapper = () => {
  const { 1: navigate } = useLocation();
  return navigate;
};
const useParamsWrapper = () => {
  const params = useParams();
  return params;
};

const AppWrapper = (props: WrapperProps) => {
  const clientId = process.env.WORKOS_CLIENT_ID || '';
  const devMode = process.env.WORKOS_DEVMODE === 'true' || false;
  const apiHostname = 'auth.api.shipmnts.com';
  const { 1: navigate } = useLocation();
  return (
    <ErrorBoundary>
      <ConfigProvider
        utils={{
          axios: axiosInstance,
          erpAxios: erpNextAxios,
          dependent_component: {
            TemplateDetailLayout: TemplateDetailLayout,
            CompanyForm: CompanyForm,
            CreateVehicleDrawer: CreateVehicleDrawer,
            CreateDriverDrawer: CreateDriverDrawer,
            AddressForm: AddressForm,
          },
          hooks: {
            useLocation: useLocationWrapper,
            useParams: useParamsWrapper,
            useSession: useSession,
            Link: Link,
            useRouteConfirmation: useRouteConfirmation,
            Route: Route,
            Switch: Switch,
            Redirect: Redirect,
            useEmailTemplatesContext: useEmailTemplatesContext,
            useApplicationContentContext: useApplicationContentContext,
            useEmailDrawerContext: useEmailDrawerContext,
            useErpNextConfig: useErpNextConfig,
            useErpNextCompanyConfigDataContext: useErpNextCompanyConfigDataContext,
          },
          ENV: {
            FIREBASE_APPLICATION_CONFIG: process.env.FIREBASE_APPLICATION_CONFIG,
            NETWORK_API_URL: process.env.NETWORK_API_URL,
            GLOBAL_API_URL: process.env.GLOBAL_API_URL,
            REPORT_MANAGER_API_URL: process.env.REPORT_MANAGER_API_URL,
            SHIPMNTS_WEB_URL: process.env.SHIPMNTS_WEB_URL,
            SHIPMNTS_MANAGE_UI_URL: process.env.SHIPMNTS_MANAGE_URL,
            DOCGEN_URL: process.env.DOCGEN_URL,
            ALEX_URL: process.env.ALEX_URL,
          },
        }}
        ui={{
          locale: enUS,
          form: { validateMessages: defaultValidateMessages },
          theme: {
            cssVar: { key: 'app' },
            token: {
              colorPrimary: '#141414',
              colorLink: '#5e1b0c',
              colorSuccess: '#29845a',
              colorTextBase: 'var(--text-primary-gray-1)',
              colorTextDescription: 'var(--text-secondary-gray-2)',
              colorSuccessText: '#0c5132',
              colorWarning: '#e86427',
              colorError: '#d91f11',
              colorBgContainer: '#fff',
              colorBgLayout: '#fff',
              fontFamily: 'Inter',
              colorText: 'var(--text-primary-gray-1)',
              controlItemBgActive: 'var(--bg-secondary-gray-4)',
              colorLinkHover: 'var(--brand-burnt-red)',
            },
          },
        }}
      >
        <AuthKitProvider
          clientId={clientId}
          devMode={devMode}
          apiHostname={apiHostname}
          onRedirectCallback={(params) => {
            const { state } = params;
            // setTimeout just to wait for page to load in history stack after redirection from auth system
            setTimeout(() => {
              navigate(`~${state?.path}${state?.search}`, { replace: true });
            }, 1);
          }}
        >
          <iframe
            title="Session Iframe"
            id="session_iframe"
            src={process.env.SESSION_SHIPMNTS_URL}
            style={{ display: 'none' }}
            loading="eager"
          />
          <App graphqlUrl={props.graphqlUrl}>
            <ErpNextCompanyConfigDataWrapper>
              <ErpNextConfigDataWrapper>
                <ApplicationLayoutContent>{props.children}</ApplicationLayoutContent>
              </ErpNextConfigDataWrapper>
            </ErpNextCompanyConfigDataWrapper>
          </App>
        </AuthKitProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export default AppWrapper;
