import { Button, Typography } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import BillingFilter from '../BillingFilters';
import { axiosInstance as axios } from 'common/utils/axiosDefaults';
import { useMutation } from '@apollo/client';
import { CREATE_HANDLING_JOB } from '../../graphql/stockLedger';
import { generateFilters, HANDLING_REPORT } from '../BillingFilterHelpers';
import { BillingReportProvider, useBillingReportContext } from '../BillingReportProvider';
import HandlingReportTable from './HandlingReportTable';

export const fieldsRequired = [
  'wms_warehouse_transactions_transaction_date',
  'wms_warehouse_transactions_transaction_number',
  'shipment_estimates_item',
  'shipment_estimates_quantity',
  'shipment_estimates_uom',
  'shipment_estimates_sell_currency',
  'shipment_estimates_sell_rate',
  'shipment_estimates_sell_exchange_rate',
  'shipment_estimates_total_sell_amount',
  'shipment_estimates_sell_remarks',
];
export const defaultFilters = [
  {
    field_id: 'wms_warehouse_transactions_transaction_number',
    condition: {
      type: 'isNotNull',
      value: 'null',
    },
  },
  {
    field_id: 'wms_warehouse_transactions_handling_job_id',
    condition: {
      type: 'isNull',
      value: 'null',
    },
  },
  {
    field_id: 'wms_warehouse_transactions_status',
    condition: {
      type: 'equals',
      value: 'submitted',
    },
  },
];
const parseData = (data: any) => {
  const key_to_parse = [
    'shipment_estimates_quantity',
    'shipment_estimates_sell_rate',
    'shipment_estimates_total_sell_amount',
  ];
  return (data || []).map((e: any) => {
    Object.keys(e).forEach((key) => {
      if (key_to_parse.includes(key)) {
        e[key] = !isNaN(Number(e[key])) ? Number(e[key]) : 0;
      }
    });
    return e;
  });
};

function HandlingReport() {
  const { form, setReportRows, reportRows } = useBillingReportContext();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [context, setContext] = useState<any>(null);
  // const [showPreview, setShowPreview] = useState<boolean>(false);
  const [createHandlingJob, { data, loading: jobLoading }] = useMutation(CREATE_HANDLING_JOB);

  useEffect(() => {
    if (data?.create_handling_job) {
      window.open(`/view/shipment/${data?.create_handling_job?.shipment?.id}`, '_blank');
    }
  }, [data]);

  const transformFormData = (values: any) => {
    const filterMapping: { [key: string]: { [key: string]: any } } = {
      customer: {
        id: 'wms_warehouse_transactions_customer_company_id',
        operator: 'equals',
      },
      warehouse: {
        id: 'wms_warehouse_transactions_branch_id',
        operator: 'equals',
      },
      transaction_date: {
        id: 'wms_warehouse_transactions_transaction_date',
        operator: 'dateRange',
      },
    };
    const formattedValues: { [key: string]: any } = {
      customer: values?.customer,
      warehouse: values?.warehouse?.branch,
      transaction_date: values?.transaction_date,
    };
    return {
      fields: fieldsRequired.map((e) => {
        return {
          id: e,
        };
      }),
      filters: generateFilters(filterMapping, formattedValues),
      doc_type_id: 'NewAccounting::ShipmentEstimate',
      default_filters: defaultFilters,
      apply_limit_offset: false,
    };
  };
  const fetchData = async (values: any) => {
    const payload = transformFormData(values);
    try {
      setDataLoading(true);
      const response = await axios.post(
        `${process.env.REPORT_MANAGER_API_URL}/doc_type/execute`,
        payload
      );
      const responseData = {
        data: parseData(response.data.data),
      };
      setReportRows(responseData);
      setContext(values); //Setting context data for PDF Preview with generated report data
      return responseData;
    } catch (error) {
      console.error(error);
      return { error };
    } finally {
      setDataLoading(false);
    }
  };

  const generateJob = (values: any) => {
    const payload = {
      definitions: transformFormData(values),
      customer_company_id: values?.customer?.id,
      branch_id: values?.warehouse?.branch?.id,
    };
    createHandlingJob({
      variables: payload,
    });
  };

  return (
    <>
      <div
        style={{
          borderBottom: '1px solid #dadee7',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 24px',
        }}
      >
        <Typography.Title level={4} style={{ margin: 0 }}>
          Warehouse Management / Handling Charges
        </Typography.Title>

        <div>
          <Button
            disabled={dataLoading}
            loading={dataLoading}
            size="small"
            htmlType="submit"
            onClick={form.submit}
            style={{ fontWeight: 'bold', marginRight: '10px' }}
            type="primary"
          >
            Generate
          </Button>
          <Button
            loading={jobLoading}
            size="small"
            onClick={() => generateJob(context)}
            disabled={jobLoading || dataLoading || !reportRows.data || !reportRows.data?.length}
          >
            Create Job
          </Button>
          {/* <Button
            disabled={!context}
            size="small"
            style={{ marginLeft: '10px' }}
            icon={<FilePdfOutlined />}
            onClick={() => setShowPreview(true)}
          >
            PDF
          </Button>
          {showPreview && (
            <PrintPreviewDocType
              docType={'Wms::WarehouseBilling'}
              resource_id={'report'}
              context={context}
              onClose={() => setShowPreview(false)}
            />
          )} */}
        </div>
      </div>

      <BillingFilter onSuccess={fetchData} report_type={HANDLING_REPORT} />
      <HandlingReportTable />
    </>
  );
}

const HandlingReportLayout = () => {
  return (
    <BillingReportProvider>
      <HandlingReport />
    </BillingReportProvider>
  );
};
export default HandlingReportLayout;
