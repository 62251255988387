import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import MBLReleaseWrapper from 'operations/modules/reports/components/ShipmentDocumentReports/MBLReleaseDrawer';
import UnreleaseDocument from 'operations/modules/reports/components/ShipmentDocumentReports/UnreleaseDocument';
import UpdateShipmentDocumentStatus from 'operations/modules/reports/components/ShipmentDocumentReports/UpdateShipmentDocumentStatus';
import {
  DOCUMENT_DISPLAY_MAPPING,
  DOCUMENT_STATUS_DRAFT_RECEIVED,
  DOCUMENT_STATUS_FIRST_DRAFT_SHARED,
  DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
  DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  DOCUMENT_STATUS_SI_FILED,
  DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
  SHIPMENT_DOCUMENT_TYPE_MASTER,
} from 'operations/modules/reports/constants';
import {
  DocumentToNewDocument,
  documentType,
  getDocumentType,
} from './shipmentDocumentActionHelper';
import CancelAirShipmentDrawer from 'operations/modules/reports/components/AirBookingReports/CancelAirShipmentDrawer';
import PreAlertAndDispatchDrawer from 'operations/modules/reports/components/ShipmentReports/PreAlertAndDispatchDrawer';
import { PerformAction } from '../models';
import { SessionDataValue } from 'operations/models/SessionData';
import BLReleaseToCustomerWrapper from 'operations/modules/reports/components/ShipmentDocumentReports/BLReleaseToCustomer';
import CancelShipmentDrawer from 'operations/modules/reports/components/ShipmentReports/CancelShipmentDrawer';
import { fetchShipmentDocumentParents, getFetchDocumentFunction } from 'operations/modules/helpers';
import { EmailProps } from 'operations/components/ChangeEvent/EventEmailDrawer';
import { lazy } from 'react';
import ProofOfDeliveryReceived from 'operations/modules/reports/components/ShipmentDocumentReports/ProofOfDeliveryReceived';
import { ApolloClient } from '@apollo/client';
import UnreleaseSwitchBl from 'operations/modules/reports/components/ShipmentDocumentReports/UnreleaseSwitchBl';

const CreateDocument = lazy(
  () => import('operations/modules/reports/components/ShipmentDocumentReports/CreateDocument')
);
interface ShipmentDocumentActionType {
  shipment_document: ShipmentDocumentValue;
  shipment: ShipmentValue;
  setEmailProps: (emailProps: EmailProps) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
  config_data?: any;
  client?: ApolloClient<object>;
}

export const releaseMblRenderer = (payload: ShipmentDocumentActionType): PerformAction => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment,
    },
    component: MBLReleaseWrapper,
  };
};

export const editMbl = (payload: ShipmentDocumentActionType): PerformAction => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment,
      isEdit: true,
    },
    component: MBLReleaseWrapper,
  };
};

export const surrenderedAtOriginActionRenderer = (
  payload: ShipmentDocumentActionType
): PerformAction => {
  const {
    shipment_document,
    shipment,
    setEmailProps,
    setVisible,
    sessionData,
    client,
    config_data,
  } = payload;
  return {
    actionParams: {
      onSuccess: (sendEmail: boolean) => {
        if (sendEmail) {
          setEmailProps({
            title: `Mark ${documentType(shipment_document, shipment)} as Surrendered At Origin`,
            action_name:
              documentType(shipment_document, shipment) === 'MBL'
                ? 'mbl_surrender'
                : 'hbl_surrender',
            resource_ids: [shipment_document?.id],
            companies_roles_mapping: shipment.getAllPartiesRolesMapping(sessionData),
            fetchDocuments: client
              ? getFetchDocumentFunction(shipment, client, sessionData, config_data)
              : undefined,
          });
          setVisible(true);
        }
      },
      shipment,
      shipment_document,
      document_status: DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
      action_name: 'hbl_surrender', // make new email template for this.
      title: `Mark ${documentType(shipment_document, shipment)} as Surrendered At Origin`,
    },
    component: UpdateShipmentDocumentStatus,
  };
};

export const documentStatusUpdateActionRenderer = (
  payload: ShipmentDocumentActionType
): PerformAction => {
  const { shipment_document, shipment, extraProps } = payload;
  return {
    actionParams: {
      shipment,
      shipment_document,
      document_status: extraProps?.document_status,
      action_name: extraProps?.action_name,
      title: `Mark ${documentType(shipment_document, shipment)} as ${extraProps?.title}`,
    },
    component: UpdateShipmentDocumentStatus,
  };
};

export const receivedAtDestinationActionRenderer = (
  payload: ShipmentDocumentActionType
): PerformAction => {
  const { shipment_document, shipment } = payload;
  return {
    actionParams: {
      shipment,
      shipment_document,
      document_status: DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
      title: `Mark ${documentType(shipment_document, shipment)} as Received At Destination`,
    },
    component: UpdateShipmentDocumentStatus,
  };
};

export const unreleaseBlActionRenderer = (payload: ShipmentDocumentActionType) => {
  const {
    shipment_document,
    shipment,
    setEmailProps,
    setVisible,
    sessionData,
    client,
    config_data,
  } = payload;
  const documentType = getDocumentType(shipment_document, shipment);
  return {
    actionParams: {
      onSuccess: (sendEmail: boolean) => {
        if (sendEmail) {
          setEmailProps({
            title: `Send ${
              DOCUMENT_DISPLAY_MAPPING[documentType] || ''
            } Reopened Notification Email`,
            action_name: 'unrelease_document',
            resource_ids: [shipment_document.id],
            companies_roles_mapping: shipment.getAllPartiesRolesMapping(sessionData),
            fetchDocuments: client
              ? getFetchDocumentFunction(shipment, client, sessionData, config_data)
              : undefined,
          });
          setVisible(true);
        }
      },
      shipment,
      shipmentDocument: shipment_document,
      documentType: documentType,
    },
    component: UnreleaseDocument,
  };
};

export const unreleaseSwitchBlActionRenderer = (payload: ShipmentDocumentActionType) => {
  const { shipment_document, shipment } = payload;
  const documentType = getDocumentType(shipment_document, shipment);
  return {
    actionParams: {
      shipment,
      shipmentDocument: shipment_document,
      documentType: documentType,
    },
    component: UnreleaseSwitchBl,
  };
};

export const onCancelRenderer = (payload: ShipmentDocumentActionType) => {
  const { shipment } = payload;
  const master_document = shipment?.shipment_documents?.filter(
    (doc) => doc.document_type === 'master'
  )[0];
  return {
    actionParams: {
      shipment: shipment,
      shipment_document_master:
        master_document || (shipment?.shipment_documents ? shipment?.shipment_documents[0] : null),
      cancellationType: 'Cancellation',
    },
    component: shipment.freight_type === 'air' ? CancelAirShipmentDrawer : CancelShipmentDrawer,
  };
};

export const onBackToTownRenderer = (payload: ShipmentDocumentActionType) => {
  const { shipment } = payload;
  const master_document = shipment?.shipment_documents?.filter(
    (doc) => doc.document_type === 'master'
  )[0];
  return {
    actionParams: {
      shipment: shipment,
      shipment_document_master:
        master_document || (shipment?.shipment_documents ? shipment?.shipment_documents[0] : null),
      cancellationType: 'Back to Town',
    },
    component: shipment.freight_type === 'air' ? CancelAirShipmentDrawer : CancelShipmentDrawer,
  };
};

export const sifiledRenderer = (payload: ShipmentDocumentActionType) => {
  const { shipment_document, shipment } = payload;
  return {
    actionParams: {
      shipment,
      shipment_document,
      document_status: DOCUMENT_STATUS_SI_FILED,
      title: `SI Filled`,
    },
    component: UpdateShipmentDocumentStatus,
  };
};

export const firstDraftSharedWithCustomerRenderer = (payload: ShipmentDocumentActionType) => {
  const { shipment_document, shipment } = payload;
  return {
    actionParams: {
      shipment,
      shipment_document,
      document_status: DOCUMENT_STATUS_FIRST_DRAFT_SHARED,
      title: `First Draft Shared With Customer`,
    },
    component: UpdateShipmentDocumentStatus,
  };
};

export const firstDraftReceived = (payload: ShipmentDocumentActionType) => {
  const { shipment_document, shipment } = payload;
  return {
    actionParams: {
      shipment,
      shipment_document,
      document_status: DOCUMENT_STATUS_DRAFT_RECEIVED,
      title: `First Draft Received from Carrier`,
      showUploadSection: true,
      uploadDocTags: ['First Draft Received'],
    },
    component: UpdateShipmentDocumentStatus,
  };
};

export const createDocumentRenderer = (payload: ShipmentDocumentActionType): PerformAction => {
  const { shipment_document, shipment } = payload;
  return {
    actionParams: {
      shipment,
      docType: shipment.isRoadShipment()
        ? 'consignment_note'
        : DocumentToNewDocument[documentType(shipment_document, shipment)],
    },
    component: CreateDocument,
  };
};

export const sendPreAlertRenderer = (payload: ShipmentDocumentActionType) => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment: shipment,
      actionName: 'send_pre_alert',
      defaultShipmentPartyType: 'destination_agent',
      emailModaltitle: `Send Pre-alert email for JOB # ${
        shipment.job_number ? shipment.job_number : shipment.id
      }`,
      emailTemplateActionName: 'pre_alert_notification',
    },
    component: PreAlertAndDispatchDrawer,
  };
};

export const blReleaseToCustomerRenderer = (payload: ShipmentDocumentActionType) => {
  const {
    shipment,
    shipment_document,
    setEmailProps,
    setVisible,
    sessionData,
    config_data,
    client,
  } = payload;
  const companies_roles_mapping = shipment.getAllPartiesRolesMapping(sessionData);

  const resource_ids = (shipment: ShipmentValue) => {
    const master_documents = shipment?.shipment_documents?.find(
      (document: ShipmentDocumentValue) => document.document_type === SHIPMENT_DOCUMENT_TYPE_MASTER
    );
    if (master_documents?.id) return [master_documents?.id];
    return [];
  };

  return {
    actionParams: {
      shipmentDocument: shipment_document,
      shipment,
      nextStatus: DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
      onSuccess: (sendEmail: boolean) => {
        if (sendEmail) {
          setEmailProps({
            title: 'Send MBL to Customer',
            action_name: 'mbl_final_release',
            resource_ids: resource_ids(shipment),
            fetchDocumentParents: fetchShipmentDocumentParents(shipment),
            companies_roles_mapping: companies_roles_mapping,
            fetchDocuments: client
              ? getFetchDocumentFunction(shipment, client, sessionData, config_data)
              : undefined,
          });
          setVisible(true);
        }
      },
    },
    component: BLReleaseToCustomerWrapper,
  };
};

export const proofOfDeliveryRecievedRenderer = (payload: ShipmentDocumentActionType) => {
  const { shipment_document, shipment } = payload;
  const documentType = getDocumentType(shipment_document, shipment);
  return {
    actionParams: {
      shipment,
      shipmentDocument: shipment_document,
      documentType: documentType,
    },
    component: ProofOfDeliveryReceived,
  };
};

export const switchBlRelaseAction = (payload: ShipmentDocumentActionType): PerformAction => {
  const { shipment_document, shipment, extraProps } = payload;
  return {
    actionParams: {
      shipment,
      shipment_document,
      document_status: extraProps?.document_status,
      action_name: extraProps?.action_name,
      title: `Mark ${documentType(shipment_document, shipment)} as ${extraProps?.title}`,
    },
    component: UpdateShipmentDocumentStatus,
  };
};
