import { gql } from '@apollo/client';
import { businessVerticalFields } from 'operations/graphql/inquiries';
import { salesPersonFields } from 'operations/graphql/salesPerson';
import { globalCarrierFields, OceanVesselFields } from 'operations/graphql/shipment';
import { addressFields, shipmentEstimateFields } from 'operations/graphql/shipmentEstimate';

export const inquiryOptionFields = gql`
  fragment inquiryOptionFields on InquiryOptionObject {
    id
    remarks
    amend_from {
      id
      quotation_number
    }
    shipment_estimates {
      id
    }
    port_of_loading {
      id
      type
      name
      unlocode
      country_code
    }
    port_of_discharge {
      id
      type
      name
      unlocode
      country_code
    }
    place_of_carrier_receipt {
      id
      type
      name
      unlocode
      country_code
    }
    place_of_carrier_delivery {
      id
      type
      name
      unlocode
      country_code
    }
    ocean_vessel {
      ...OceanVesselFields
    }
    carrier {
      ...globalCarrierFields
    }
    voyage_number
    estimated_time_of_departure
    estimated_time_of_arrival
    quotation_number
    status
    inquiry {
      load_type
      service_type
      movement_mode
      routing_remarks
      business_vertical {
        ...businessVerticalFields
      }
      priority
      origin {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
      destination {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
      container_stuffing_location {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
      container_destuffing_location {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
      origin_custom_clearance_location {
        id
        type
        name
        unlocode
        country_code
      }
      destination_custom_clearance_location {
        id
        type
        name
        unlocode
        country_code
      }
      business_received_through
      fulfilled_container_map
      last_action_status
      inquiry_options {
        id
        status
        quotation_number
        shipment_estimates {
          id
        }
      }
      preferred_carriers {
        id
        carrier_type
        iata_awb_prefix
        iata_carrier_code
        name
        standard_alpha_carrier_code
        meta_information
        short_code
      }
      sales_person {
        ...salesPersonFields
      }
      id
      incoterms
      services
      involved_branch {
        id
        name
      }
      freight_type
      trade_type
      customer_company {
        id
        registered_name
        status
        is_disabled
        company_type
        logo
        company_group
        country_of_incorporation
      }
      customer_address {
        id
        company_id
        name
        print_address
        entity_type
      }
      billing_party {
        id
        registered_name
        status
        is_disabled
        company_type
        logo
        company_group
        country_of_incorporation
      }
      billing_party_address {
        id
        company_id
        name
        print_address
        entity_type
      }
      container_requests {
        id
        container_settings {
          is_active_reefer
          temperature
          temperature_unit
          ventilation_requested
          air_flow
          air_flow_unit
          humidity_control_requested
          relative_humidity_percent
          vent_setting
          additional_instructions
          genset_requested
          controlled_atmosphere_requested
          oxygen_level_percent
          nitrogen_level_percent
          carbon_dioxide_level_percent
          length
          width
          height
          lbh_unit
        }
        container_type
        container_type_code
        is_shipper_owned
        quantity
        weight_per_container
        weight_unit
      }
      cargos {
        id
        cargo_properties {
          is_perishable
          is_temp_controlled
          is_hazardous
          is_battery
          un_number
          un_imo_class
          proper_shipping_name
          technical_name
          packaging_group
          net_weight
          net_weight_unit
          flash_point
          flash_point_unit
          ems_number
          emergency_contact_name
          emergency_contact_number
          is_radioactive
          aircraft_type
          battery_type
        }
        product_name
        outer_package_qty
        outer_package_type
        gross_volume
        gross_weight
        net_weight
        weight_unit
        volume_unit
        commodity {
          id
          name
          commodity_classification_code
          commodity_coding_system
          dangerous_cargo
          dry_cargo
          live_reefer_cargo
          oog_cargo
        }
        height
        outer_package_qty
        outer_per_packet_wt
        width
        length
        dimension_unit
        volumetric_weight
        chargeable_weight
      }
      port_of_loading {
        id
        type
        name
        unlocode
      }
      port_of_discharge {
        id
        type
        name
        unlocode
      }
      shipment_parties {
        id
        name
        party_address {
          id
          name
          print_address
        }
        party_company {
          id
          registered_name
          company_group
          company_type
          country_of_incorporation
          is_disabled
          status
        }
      }
    }
  }
  ${globalCarrierFields}
  ${OceanVesselFields}
  ${salesPersonFields}
  ${addressFields}
  ${businessVerticalFields}
`;

export const GET_INQUIRY_OPTION = gql`
  query get_inquiry_option($id: ID!) {
    get_inquiry_option(id: $id) {
      ...inquiryOptionFields
    }
  }
  ${inquiryOptionFields}
`;

export const inquiryOptionFieldsWithEstimates = gql`
  fragment inquiryOptionFieldsWithEstimates on InquiryOptionObject {
    id
    shipment_estimates {
      ...shipmentEstimateFields
    }
  }
  ${shipmentEstimateFields}
`;

export const GET_INQUIRY_OPTION_WITH_ESTIMATES = gql`
  query get_inquiry_option($id: ID!) {
    get_inquiry_option(id: $id) {
      ...inquiryOptionFieldsWithEstimates
    }
  }
  ${inquiryOptionFieldsWithEstimates}
`;
