import React, { useEffect, useState } from 'react';
import { Avatar, Button, Divider, Tag, Tooltip, PrioritySelect } from '@shipmnts/pixel-hub';
import { PageHeader, RenderCollaborators, useActivityContext } from '@shipmnts/pixel-hub';
import { get as _get, startCase as _startCase, upperCase as _upperCase } from 'lodash';
import { useShipmentDetail } from './ShipmentDetailLayout';
import { EditOutlined } from '@shipmnts/pixel-hub';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import { DoubleLeftOutlined, UpOutlined, DownOutlined, PauseOutlined } from '@shipmnts/pixel-hub';
import {
  SHIPMENT_TYPE_HOUSE,
  FREIGHT_TYPE_ROAD,
  FREIGHT_TYPE_OCEAN,
  SHIPMENT_TYPE_WAREHOUSE,
  SHIPMENT_TYPE_GENERAL,
} from '../../constants';
import {
  MOVEMENT_TYPE_DPD,
  SHIPMENT_STATUS_BACK_TO_TOWN,
  SHIPMENT_STATUS_CANCELLED,
  SHIPMENT_STATUS_PLANNED,
  STUFFING_TYPE_CFS,
  STUFFING_TYPE_FACTORY,
} from 'operations/modules/reports/constants';
import { useLocation } from 'wouter';
import { LOAD_TYPE_FCL } from 'operations/baseConstants';
import { dividerStyles } from './Common/common';
import { useSession } from 'common';
import { ColorPickerTool, CustomIcon, getLighterColour } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { EDIT_FIELD } from 'common/utils/graphql';

type Props = {
  onClose?: () => void;
  externalLink?: boolean;
};

const ShipmentDetailHeader = (props: Props) => {
  const { onClose, externalLink } = props;
  const { 1: navigate } = useLocation();
  const sessionData = useSession();
  const company_details = _get(sessionData, ['company_account', 'default_company']);
  const [updateShipmentField, { error: updateShipmentFieldError }] = useMutation(EDIT_FIELD);
  // Contexts
  const { shipment, refetchShipments } = useShipmentDetail();
  const { setActivityProps } = useActivityContext();
  const [colorCode, setColorCode] = useState(shipment?.color_code || '#FFFFFF');
  const [selectedPriority, setSelectedPriority] = useState(shipment?.priority || 'Lowest');

  useEffect(() => {
    if (updateShipmentFieldError) {
      setSelectedPriority(shipment?.priority || 'Lowest');
    }
  }, [updateShipmentFieldError, shipment?.priority]);

  if (!shipment) {
    return <></>;
  }

  // Constants
  const shipmentType = _get(shipment, 'shipment_type', '');

  const priorityOptions = [
    {
      value: 'Highest',
      label: 'Highest',
      icon: (
        <div>
          <DoubleLeftOutlined style={{ color: '#FF0000', transform: 'rotate(90deg)' }} />
        </div>
      ),
    },
    {
      value: 'High',
      label: 'High',
      icon: <UpOutlined style={{ color: '#FFA500' }} />,
    },
    {
      value: 'Medium',
      label: 'Medium',
      icon: <PauseOutlined style={{ color: '#FFDDBB', transform: 'rotate(90deg)' }} />,
    },
    {
      value: 'Low',
      label: 'Low',
      icon: <DownOutlined style={{ color: '#00FF00' }} />,
    },
    {
      value: 'Lowest',
      label: 'Lowest',
      icon: <DoubleLeftOutlined style={{ color: '#0000FF', transform: 'rotate(-90deg)' }} />,
    },
  ];

  const handlePriorityChange = (value: string) => {
    setSelectedPriority(value);
    updateShipmentField({
      variables: {
        changes: [
          {
            id: shipment.id,
            doc_type_id: 'Shipment::Shipment',
            fields_changes: `{"shipments_priority": "${value}"}`,
          },
        ],
      },
    });
  };

  const isEnable = (): boolean => {
    return shipment.canEdit();
  };

  const freight_forwarding_agent = shipment.shipment_parties?.find(
    (party: any) => party.name === 'freight_forwarding_agent' && party?.party_company?.id
  );

  const booking_type = (): string => {
    if (shipment?.freight_type === 'air') {
      return shipment?.services?.freight_forwarding &&
        freight_forwarding_agent?.party_company?.id === _get(company_details, 'id')
        ? 'AIRLINE BOOKING'
        : 'VENDOR BOOKING';
    } else {
      return shipment?.booking_type || '';
    }
  };
  const extras = [
    <RenderCollaborators
      id={shipment?.job_number}
      referenceId={shipment?.id}
      referenceType={'Shipment::Shipment'}
      key=""
    />,
    <Button
      key="shipment_header_activity" // updated
      size={'small'}
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={() => {
        setActivityProps({
          visible: true,
          resource_id: shipment.id,
          resource_type: 'Shipment::Shipment',
          showDrawer: true,
          resource: shipment,
        });
      }}
    >
      Activity
      <span style={{ marginLeft: '5px', display: 'flex' }}>
        <CustomIcon icon="ActivityIcon" />
      </span>
    </Button>,
    <Button
      key={'edit'}
      size={'small'}
      onClick={() => {
        if (externalLink) {
          if (
            sessionData.company_account.primary_business === 'manufacturer' ||
            sessionData.company_account.primary_business === 'trader'
          )
            window.open(`/form/shipment/${shipment?.id}`);
          else if (shipment?.freight_type === FREIGHT_TYPE_ROAD)
            window.open(`/form/shipment/${shipment?.id}`);
          else if (shipment?.freight_type === FREIGHT_TYPE_OCEAN) {
            if (shipment.status === SHIPMENT_STATUS_PLANNED) {
              window.open(`/form/new_shipment/${shipment?.id}`);
            } else {
              window.open(`/form/new_shipment/${shipment?.id}`);
            }
          } else window.open(`${process.env.SHIPMNTS_WEB_URL}/shipment/${shipment?.id}/edit`);
        } else {
          if (
            sessionData.company_account.primary_business === 'manufacturer' ||
            sessionData.company_account.primary_business === 'trader'
          )
            navigate(`~/form/shipment/${shipment?.id}`);
          else if (shipment?.freight_type === FREIGHT_TYPE_ROAD)
            navigate(`~/form/shipment/${shipment?.id}`);
          else if (shipment?.freight_type === FREIGHT_TYPE_OCEAN) {
            navigate(`~/form/new_shipment/${shipment?.id}`);
          } else {
            window.open(`${process.env.SHIPMNTS_WEB_URL}/shipment/${shipment?.id}/edit`, '_self');
          }
        }
      }}
      disabled={!isEnable()}
    >
      Edit
      <span style={{ marginLeft: '5px' }}>
        <EditOutlined />
      </span>
    </Button>,
    <ActionRenderer
      id={shipment.id}
      doc_type_id="Shipment::Shipment"
      isDetailScreen
      data={shipment}
      refetchData={refetchShipments}
      key="actions"
    />,
  ];

  const showStatus =
    shipment?.status === SHIPMENT_STATUS_CANCELLED ||
    shipment?.status === SHIPMENT_STATUS_BACK_TO_TOWN;
  const tags_to_display: { value: string; tooltipData: string }[] = [];

  // Coppied from shipmentWeb
  if (shipment.trade_type || shipment.freight_type)
    tags_to_display.push({
      value: `${_startCase(_get(shipment, 'freight_type', '') || '')} ${_startCase(
        _get(shipment, 'trade_type', '') || ''
      )} `,
      tooltipData: 'Trade Type',
    });

  const loadTypeMap: { [key: string]: string } = {
    breakbulk: 'FTL - Break Bulk',
    bulk: 'FTL - Bulk',
    liquid: 'FTL - Liquid',
    fcl: 'FCL',
    ltl: 'LTL',
    ftl: 'FTL',
    lcl: 'LCL',
    loose: 'Loose',
    uld: 'ULD',
  };

  if (shipment.load_type)
    tags_to_display.push({
      value: `${loadTypeMap[_get(shipment, 'load_type', '') || '']}`,
      tooltipData: 'Load Type',
    });

  if (shipment.cargo_type) {
    tags_to_display.push({
      value: `${_upperCase(_get(shipment, 'cargo_type', '') || '')}`,
      tooltipData: 'Cargo Type',
    });
  }

  if (shipment.vehicle?.ownership_type) {
    tags_to_display.push({
      value: `${_upperCase(shipment.vehicle?.ownership_type || '')}`,
      tooltipData: 'Ownership Type',
    });
  }

  if (shipment.incoterms) {
    tags_to_display.push({
      value: `${_upperCase(_get(shipment, 'incoterms', '') || '')}`,
      tooltipData: 'Incoterms',
    });
  }
  if (!!shipment?.forwarding_invoice_status) {
    tags_to_display.push({
      value: `${_upperCase(_get(shipment, 'forwarding_invoice_status', '') || '')}`,
      tooltipData: 'Carrier Invoice Status',
    });
  }

  if (shipment.isOceanShipment()) {
    const default_stuffing_value =
      shipment.load_type === LOAD_TYPE_FCL ? STUFFING_TYPE_FACTORY : STUFFING_TYPE_CFS;
    const movementType = _get(shipment, 'movement_type', MOVEMENT_TYPE_DPD);
    const destuffingType = _get(shipment, 'destuffing_type', default_stuffing_value);
    const stuffingType: string | null = _get(shipment, 'stuffing_type', default_stuffing_value);
    if (shipment.isImportShipment()) {
      if (movementType)
        tags_to_display.push({ value: _upperCase(movementType), tooltipData: 'Movement Type' });
      if (destuffingType)
        tags_to_display.push({
          value: _upperCase(destuffingType),
          tooltipData: ' Destuffing Type',
        });
    } else {
      if (stuffingType)
        tags_to_display.push({ value: _upperCase(stuffingType), tooltipData: 'Stuffing Type' });
    }
  }

  if (
    shipmentType !== SHIPMENT_TYPE_WAREHOUSE &&
    shipmentType !== SHIPMENT_TYPE_GENERAL &&
    shipmentType !== SHIPMENT_TYPE_HOUSE
  ) {
    if (shipment.isOceanShipment()) {
      if ((shipment?.ocean_transport_orders || []).length > 0)
        tags_to_display.push({
          value: _upperCase(booking_type()),
          tooltipData: 'Booking Type',
        });
    } else if (shipment.freight_type !== FREIGHT_TYPE_ROAD) {
      tags_to_display.push({
        value: _upperCase(booking_type()),
        tooltipData: 'Booking Type',
      });
    }
  }

  if (shipmentType === SHIPMENT_TYPE_WAREHOUSE) {
    tags_to_display.push({
      value: shipment?.services?.warehouse?.handling ? 'Handling' : 'Storage',
      tooltipData: 'Job Type',
    });
  }

  const isShipmentHazardous = () => {
    return _get(shipment, ['shipment_properties', 'is_hazardous'], false);
  };

  const isShipmentPerishable = () => {
    return _get(shipment, ['shipment_properties', 'is_perishable'], false);
  };

  const isTempControlled = () => {
    return _get(shipment, ['shipment_properties', 'is_temp_controlled'], false);
  };

  const titleAndBackButton = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '0px',
        }}
      >
        {shipmentType && (
          <Tooltip title={_startCase(shipment?.getShipmentType() || '')}>
            <Avatar
              avatarContent={
                shipment?.getShipmentType() === 'Customer Order'
                  ? 'O'
                  : shipment?.getShipmentType() || ''
              }
              shape="square"
              style={{
                color: 'rgba(175, 10, 202, 1)',
                backgroundColor: 'rgba(175, 10, 202, 0.3)',
                border: '1px solid #AF0ACA',
                fontWeight: 600,
                fontSize: '18px',
                borderRadius: '4px',
                flexShrink: 0,
              }}
            />
          </Tooltip>
        )}

        <ColorPickerTool
          onChange={setColorCode}
          value={colorCode}
          docType={'Shipment::Shipment'}
          docId={shipment?.id}
        />

        <div
          style={{
            fontSize: '18px',
            fontWeight: 600,
            color: 'var(--color-primary)', //Todo: constants
            marginLeft: '14px',
          }}
        >
          {shipment?.job_number || shipment?.shipment_booking_number}

          {externalLink && (
            <span>
              <Tooltip title="Open Detail Screen">
                <a href={`/view/shipment/${shipment.id}`} target="_blank" rel="noreferrer">
                  <CustomIcon icon="ExternalLinkIcon" />
                </a>
              </Tooltip>
            </span>
          )}
        </div>
        {showStatus && (
          <div style={{ fontSize: '12px', color: 'var(--text-primary-gray-1)' }}>
            <Tag type="critical" style={{ marginLeft: '5px' }}>
              {_startCase(shipment.status || '')}
            </Tag>
          </div>
        )}
        <div style={{ display: 'flex', marginLeft: '5px', flexWrap: 'wrap' }}>
          {tags_to_display.map((tag) => (
            <Tooltip title={tag.tooltipData} key={tag.value}>
              <Tag type="info">{tag.value}</Tag>
            </Tooltip>
          ))}
          {shipment.isPlanned() && <Tag type="critical">Job Creation Pending</Tag>}
          {shipment.isAccountingClosed() && <Tag type="critical-secondary">Job Closed</Tag>}
          {isShipmentHazardous() && (
            <Tooltip title={<div>Contains Hazardous Cargo</div>}>
              <CustomIcon icon="HazardIcon" />
            </Tooltip>
          )}
          {isShipmentPerishable() && (
            <div style={{ marginLeft: '3px', display: 'inline-flex' }}>
              <Tooltip title={<div>Perishable</div>}>
                <CustomIcon icon="MdiLeafIcon" />
              </Tooltip>
            </div>
          )}
          {isTempControlled() && (
            <Tooltip title={<div>Temperature Controlled</div>}>
              <CustomIcon icon="NormalTemperatureIcon" />
            </Tooltip>
          )}
        </div>
        <PrioritySelect
          selectedPriority={selectedPriority}
          handlePriorityChange={handlePriorityChange}
          priorityOptions={priorityOptions}
        />
      </div>
    );
  };
  const handleOnClose = () => {
    if (onClose) onClose();
    else {
      navigate(`~/workspace?doc_type=Shipment::Shipment&resource_id=${shipment.id}`);
    }
  };
  return (
    <PageHeader
      ghost={false}
      onBack={handleOnClose}
      title={titleAndBackButton()}
      extra={extras}
      className={'shipment-detail-header'}
      style={{
        background: getLighterColour(colorCode) || '#ffffff',
      }}
    >
      <Divider style={dividerStyles} />
    </PageHeader>
  );
};

export default ShipmentDetailHeader;
