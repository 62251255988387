export const STORAGE_REPORT = 'wms_storage_report';
export const HANDLING_REPORT = 'wms_handling_report';
export const STOCK_SUMMARY_REPORT = 'wms_stock_summary_report';
export const generateFilters = (
  filterMapping: { [key: string]: { [key: string]: any } },
  values: { [key: string]: any }
) => {
  const payload: Array<{ [key: string]: any }> = [];
  Object.keys(values).forEach((key) => {
    if (filterMapping[key]) {
      payload.push({
        field_id: filterMapping[key].id,
        condition: {
          type: filterMapping[key].operator,
          ...(Array.isArray(values[key]) ? { values: values[key] } : { value: values[key] }),
        },
      });
    }
  });
  return payload;
};
export const transformFormData = (values: any) => {
  const filterMapping: { [key: string]: { [key: string]: any } } = {
    customer: {
      id: 'wms_stock_ledgers_customer_company_id',
      operator: 'in',
    },
    warehouse: {
      id: 'wms_stock_ledgers_branch_id',
      operator: 'in',
    },
    transaction_date: {
      id: 'wms_stock_ledgers_transaction_date',
      operator: 'dateRange',
    },
    pro_rata: {
      id: 'wms_stock_ledgers_warehouse_transaction_type',
      operator: 'in',
    },
    include_existing_job_records: {
      id: 'wms_warehouse_transactions_storage_job_id',
      operator: 'isNull',
    },
  };
  const formattedValues: { [key: string]: any } = {
    customer: [values?.customer],
    warehouse: [values?.warehouse?.branch],
    transaction_date: values?.transaction_date,
    pro_rata: values?.pro_rata ? ['Wms::Receipt', 'Wms::Delivery'] : ['Wms::Receipt'],
    ...(!values?.include_existing_job_records && { include_existing_job_records: null }),
  };
  const openingFilterMapping: { [key: string]: { [key: string]: any } } = {
    customer: {
      id: 'wms_stock_ledgers_customer_company_id',
      operator: 'in',
    },
    warehouse: {
      id: 'wms_stock_ledgers_branch_id',
      operator: 'in',
    },
    transaction_date: {
      id: 'wms_stock_ledgers_transaction_date',
      operator: 'lessThan',
    },
  };
  const openingFormattedValues: { [key: string]: any } = {
    customer: [values?.customer],
    warehouse: [values?.warehouse?.branch],
    transaction_date: values?.transaction_date[0],
  };
  const form_payload: { [key: string]: any } = {
    customer: values?.customer,
    warehouse: values?.warehouse,
    transaction_date: [
      values?.transaction_date[0]?.unix() || null,
      values?.transaction_date[1]?.unix() || null,
    ],
    pro_rata: values?.pro_rata || false,
    include_delivery_day: values?.include_delivery_day || false,
    rates: values?.rates || 0,
  };

  const payload: { [key: string]: any } = {
    filters: generateFilters(filterMapping, formattedValues),
    opening_filters: generateFilters(openingFilterMapping, openingFormattedValues),
  };
  return { ...payload, ...form_payload };
};

export const generateCreateShipmentPayload = (values: any) => {
  return {
    definitions: values,
    customer_company_id: values?.customer?.id,
    branch_id: values?.warehouse?.branch?.id,
  };
};
