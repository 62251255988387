import { CustomIcon, getDateFromUnix, getDateTimeFromUnix } from '@shipmnts/pixel-hub';
import {
  TRANSACTION_TYPE_DELIVERY,
  TRANSACTION_TYPE_GTO,
  TRANSACTION_TYPE_INCOMING,
  TRANSACTION_TYPE_OUTGOING,
  TRANSACTION_TYPE_RECEIPT,
  WarehouseTransactionValue,
} from 'operations/models/WarehouseTransaction';
import React from 'react';
import { collapsibleCard } from 'operations/modules/shipment/components/DetailLayout/Common/common';
import WarehouseTransactionTimlineRenderer from './WarehouseTransactionTimlinedTag';
import { Link } from 'wouter';

interface WMSCreationDetailsProps {
  warehouseTransaction: WarehouseTransactionValue;
}

const WMSCreationDetails = (props: WMSCreationDetailsProps) => {
  const { warehouseTransaction } = props;
  const CreationDetails = [];
  CreationDetails.push({
    Status: <WarehouseTransactionTimlineRenderer transaction={warehouseTransaction} />,
  });
  CreationDetails.push({
    Warehouse: warehouseTransaction?.target_warehouse?.node_name,
  });
  if (warehouseTransaction?.type === TRANSACTION_TYPE_INCOMING)
    CreationDetails.push({
      'Target Receipt Date': warehouseTransaction?.target_date ? (
        <div>{getDateTimeFromUnix(warehouseTransaction?.target_date)}</div>
      ) : null,
    });
  if (warehouseTransaction?.type === TRANSACTION_TYPE_OUTGOING)
    CreationDetails.push({
      'Target Delivery Date': warehouseTransaction?.target_date ? (
        <div>{getDateTimeFromUnix(warehouseTransaction?.target_date)}</div>
      ) : null,
    });
  if (warehouseTransaction?.type === TRANSACTION_TYPE_GTO) {
    CreationDetails.push({
      'Transferred by': warehouseTransaction?.transaction_by
        ? [
            warehouseTransaction?.transaction_by?.first_name,
            warehouseTransaction?.transaction_by?.last_name,
          ]
            .filter(Boolean)
            .join(' ')
        : '',
    });
    CreationDetails.push({
      'Transferred At': warehouseTransaction?.transaction_date
        ? getDateFromUnix(warehouseTransaction?.transaction_date)
        : null,
    });
    CreationDetails.push({
      Purpose: warehouseTransaction?.purpose_of_transfer,
    });
  } else {
    CreationDetails.push({
      'Created by': warehouseTransaction.created_by
        ? [warehouseTransaction.created_by.first_name, warehouseTransaction.created_by.last_name]
            .filter(Boolean)
            .join(' ')
        : '',
    });
    CreationDetails.push({
      'Created At': warehouseTransaction?.created_at
        ? getDateFromUnix(warehouseTransaction?.created_at)
        : null,
    });
  }
  if (
    warehouseTransaction?.type === TRANSACTION_TYPE_RECEIPT ||
    warehouseTransaction?.type === TRANSACTION_TYPE_DELIVERY
  ) {
    CreationDetails.push({
      'Storage Job': !!warehouseTransaction?.storage_job ? (
        <Link to={`~/view/shipment/${warehouseTransaction?.storage_job?.id}`}>
          {warehouseTransaction?.storage_job?.job_number}
        </Link>
      ) : null,
    });
    CreationDetails.push({
      'Handling Job': !!warehouseTransaction?.handling_job ? (
        <Link to={`~/view/shipment/${warehouseTransaction?.handling_job?.id}`}>
          {warehouseTransaction?.handling_job?.job_number}
        </Link>
      ) : null,
    });
  }

  return (
    <>
      {collapsibleCard({
        icon: <CustomIcon icon="Info" />,
        header: 'Creation Detail',
        data: CreationDetails,
      })}
    </>
  );
};

export default WMSCreationDetails;
