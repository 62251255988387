import React, { lazy } from 'react';
import { Route, Switch } from 'wouter';
import './operations.css';
import { useSession } from 'common';
import { CreateProductFormLayout } from './modules/productCatalogue/CreateProductForm';
import { FocusView } from '@shipmnts/pixel-hub';
import { CompanyView, ContactView } from 'network';
import { CreateWarehouseFormLayout } from './modules/warehouseManagement/CreateWarehouseUnit';

// Lazy Loading View Components
const BookingOrderView = lazy(
  () => import('./modules/booking/components/BookingOrderView/BookingOrderView')
);
const ShipmentDetailLayoutWrapper = lazy(
  () => import('./modules/shipment/components/DetailLayout/ShipmentDetailLayout')
);
const DocumentLayout = lazy(
  () => import('./modules/shipment/components/DetailLayout/Documents/DocumentLayout')
);
const OMSDetailLayoutWrapper = lazy(() => import('./modules/orderManagement/OMS/OMSDetailLayout'));

// Lazy Loading Form Components
const BookingOrderForm = lazy(
  () => import('./modules/booking/components/BookingOrderForm/BookingOrderForm')
);
const VoyageScheduleForm = lazy(
  () => import('./modules/booking/components/BookingSchedules/VoyageScheduleForm')
);
const CreateShipmentIMPEX = lazy(
  () => import('./modules/orderManagement/CreateShipmentIMPEX/CreateShipmentIMPEX')
);
const CreateShipment = lazy(
  () => import('./modules/shipment/components/ShipmentForm/CreateShipment')
);
const DuplicateShipmentIMPEX = lazy(
  () => import('./modules/orderManagement/CreateShipmentIMPEX/DuplicateShipmentIMPEX')
);
const DuplicateShipment = lazy(
  () => import('./modules/shipment/components/ShipmentForm/DuplicateShipment')
);
const ProductOrderFormWrapper = lazy(() => import('./modules/orderManagement/ProductOrderForm'));
const CommercialInvoiceFormWrapper = lazy(
  () => import('./modules/orderManagement/CommercialInvoice/CommercialInvoiceForm')
);

const IncomingShipmentForm = lazy(
  () => import('./modules/warehouseManagement/IncomingShipmentForm')
);
const ReceiptForm = lazy(() => import('./modules/warehouseManagement/ReceiptForm'));
const OutgoingShipmentForm = lazy(
  () => import('./modules/warehouseManagement/OutgoingShipmentForm')
);
const DeliveryForm = lazy(() => import('./modules/warehouseManagement/DeliveryForm'));
const WMSDetailLayoutWrapper = lazy(() => import('./modules/warehouseManagement/WMSDetailLayout'));
const GoodsTransferForm = lazy(() => import('./modules/warehouseManagement/GoodsTransferForm'));

const CreateRoute = lazy(() => import('./modules/shipment/components/Route/CreateRoute'));
const DuplicateNewShipmentFormWrapper = lazy(
  () => import('./modules/shipment/components/NewShipmentForm/DuplicateNewShipmentForm')
);
const NewShipmentForm = lazy(
  () => import('./modules/shipment/components/NewShipmentForm/NewShipmentForm')
);

const OperationsViewLayout = () => {
  return (
    <Switch>
      <Route path="/booking_order/:id">
        <BookingOrderView />
      </Route>
      <Route path="/shipment/:id/:tab">
        <ShipmentDetailLayoutWrapper />
      </Route>
      <Route path="/shipment/:id">
        <ShipmentDetailLayoutWrapper />
      </Route>
      <Route path="/documents/:document_id">
        <DocumentLayout />
      </Route>
      <Route path="/product_order/:id">
        <OMSDetailLayoutWrapper />
      </Route>
      <Route path="/tasks/focus">
        <FocusView
          ViewMap={{
            'Network::Company': CompanyView,
            'Network::UserContact': ContactView,
            'Shipment::Shipment': ShipmentDetailLayoutWrapper,
          }}
        />
      </Route>

      <Route path="/warehouse_transaction/:id">
        <WMSDetailLayoutWrapper />
      </Route>
    </Switch>
  );
};

const OperationsFormLayout = () => {
  const session = useSession();
  return (
    <Switch>
      <Route path="/booking_order/:id">
        <BookingOrderForm />
      </Route>
      <Route path="/voyage_schedule/:id">
        <VoyageScheduleForm />
      </Route>
      <Route path="/shipment/:id">
        {session?.company_account?.primary_business === 'manufacturer' ||
        session?.company_account?.primary_business === 'trader' ? (
          <CreateShipmentIMPEX />
        ) : (
          <CreateShipment />
        )}
      </Route>
      <Route path="/shipment/duplicate/:id">
        {session?.company_account?.primary_business === 'manufacturer' ||
        session?.company_account?.primary_business === 'trader' ? (
          <DuplicateShipmentIMPEX />
        ) : (
          <DuplicateShipment />
        )}
      </Route>
      <Route path="/product_order/:id">
        <ProductOrderFormWrapper />
      </Route>
      <Route path="/commercial_invoice/:id">
        <CommercialInvoiceFormWrapper />
      </Route>

      <Route path="/create_product/:id">
        <CreateProductFormLayout />
      </Route>

      <Route path="/incoming_shipment/:id">
        <IncomingShipmentForm />
      </Route>

      <Route path="/receipt/:id">
        <ReceiptForm />
      </Route>

      <Route path="/goods_transfer/:id">
        <GoodsTransferForm />
      </Route>

      <Route path="/outgoing_shipment/:id">
        <OutgoingShipmentForm />
      </Route>

      <Route path="/delivery/:id">
        <DeliveryForm />
      </Route>

      <Route path="/create_warehouse_unit/:id">
        <CreateWarehouseFormLayout />
      </Route>

      <Route path="/route/duplicate/:id">
        <CreateRoute />
      </Route>

      <Route path="/route/:id">
        <CreateRoute />
      </Route>
      <Route path="/view/tasks/focus">
        <FocusView
          ViewMap={{
            'Network::Company': CompanyView,
            'Network::UserContact': ContactView,
            'Shipment::Shipment': ShipmentDetailLayoutWrapper,
          }}
        />
      </Route>
      <Route path="/new_shipment/duplicate/:id">
        <DuplicateNewShipmentFormWrapper />
      </Route>
      <Route path="/new_shipment/:id?">
        <NewShipmentForm />
      </Route>
    </Switch>
  );
};

export { OperationsFormLayout, OperationsViewLayout };
