import React, { MutableRefObject, useCallback, useContext, useEffect, useState } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import { Action, PerformAction } from 'operations/modules/actionHelper/models';
import { CheckCircleFilled, CloseOutlined, ViewTableContext } from '@shipmnts/pixel-hub';
import { getShipmentBulkActions } from 'operations/modules/bulkActionHelper/ShipmentBulkActions/shipmentBulkActionHelper';
import { getContainerBulkActions } from 'operations/modules/bulkActionHelper/ContainerBulkActions/containerBulkActionHelper';
import { getShipmentCustomDetailBulkActions } from 'operations/modules/bulkActionHelper/ShipmentCustomDetailActions/shipmentCustomDetailActionHelper';
import { DOC_TYPE_METHODS } from 'common/baseConstants';
import {
  NEGATIVE_TYPE,
  PRIMARY_TYPE,
  SECONDARY_TYPE,
  TERTIARY_TYPE,
} from 'operations/modules/actionHelper/constants';
import { groupBy, uniq } from 'lodash';
import { Button, Divider, Drawer, Dropdown } from '@shipmnts/pixel-hub';
import { getCargoBulkActions } from 'operations/modules/actionHelper/CargoActions/cargoActionHelper';
import getUserContactBulkActions from 'operations/modules/bulkActionHelper/UserContactBulkActions/UserContactBulkActionsHelper';
import getTemplateBulkActions from 'operations/modules/bulkActionHelper/TemplateBulkActions/TemplateBulkActionsHelper';
import getSalesPersonBulkActions from 'operations/modules/bulkActionHelper/SalesPersonBulkActions/SalesPersonBulkActionsHelper';
import getTicketBulkActions from 'operations/modules/bulkActionHelper/TicketBulkActions/TicketBulkActionsHelper';
import getCompanyBulkActions from 'operations/modules/bulkActionHelper/CompanyBulkAction/CompanyBulkActionHelper';
import { useSession } from 'common';
import getInquiryBulkAction from 'operations/modules/bulkActionHelper/InquiryBulkAction/InquiryBulkActionHelper';

import { getEstimateBulkActions } from 'operations/modules/bulkActionHelper/EstimateBulkActions/estimateBulkActionHelper';
import { useErpNextConfig } from 'network';
interface ExtendedRefOptions extends GridOptions {
  isAction?: boolean;
}

interface BulkActionDrawerProps {
  isBulkActionVisible: boolean;
  setIsBulkActionVisible: (isVisible: boolean) => void;
  gridRef: MutableRefObject<ExtendedRefOptions | undefined>;
  doc_type_id: string;
  selectedNodeCounts: number;
  refetch?: () => void;
}

const BulkActionDrawer = React.memo(function BulkActionDrawer(prop: BulkActionDrawerProps) {
  const {
    isBulkActionVisible,
    setIsBulkActionVisible,
    gridRef,
    doc_type_id,
    selectedNodeCounts,
    refetch,
  } = prop;
  const [actionParams, setActionParams] = useState<any>({});
  const [currentId, setCurrentId] = useState<string>('');
  const [menuItems, setMenuItem] = useState<Action[]>([]);
  const [TaskComponent, setTaskComponent] = useState<React.JSXElementConstructor<any>>();
  const [ChildTaskComponent, setChildTaskComponent] = useState<React.JSXElementConstructor<any>>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { refreshReportRows } = useContext(ViewTableContext);
  const erpnextContext = useErpNextConfig();
  const { erpnextConfigData } = erpnextContext;
  const isSpEnabled =
    erpnextConfigData?.create_interbranch_invoices_automatically ===
    'Interbranch sales invoice creation (SP)';
  const selectedNodes = (gridRef?.current?.api?.getSelectedNodes() || []).filter(
    (node) => !node.group
  );
  const session = useSession();
  const { permissions } = session;
  const onCloseBulkActionDrawer = () => {
    if (gridRef?.current) {
      gridRef.current.isAction = false;
    }
    setIsBulkActionVisible(false);
    gridRef?.current?.api?.deselectAll();
    setTaskComponent(undefined);
    setChildTaskComponent(undefined);
    setCurrentId('');
    setMenuItem([]);
  };
  const deselectRows = useCallback(() => {
    gridRef?.current?.api?.deselectAll();
  }, [gridRef]);
  const onSuccess = () => {
    if (gridRef.current) {
      gridRef.current.isAction = true;
    }
    const ids = uniq(selectedNodes.map((node) => node.data.id));
    deselectRows();
    setTaskComponent(undefined);
    setChildTaskComponent(undefined);
    setCurrentId('');
    setActionParams({});
    setIsBulkActionVisible(false);
    if (refreshReportRows) refreshReportRows(ids);
    if (refetch) refetch();
  };
  const onClose = () => {
    if (gridRef?.current) {
      gridRef.current.isAction = false;
    }
    setTaskComponent(undefined);
    setChildTaskComponent(undefined);
    setCurrentId('');
    setActionParams({});
  };
  useEffect(() => {
    if (selectedNodeCounts > 0) {
      if (doc_type_id === 'Shipment::Shipment') {
        setMenuItem(getShipmentBulkActions(selectedNodes));
      } else if (doc_type_id === 'Shipment::ShipmentContainer') {
        setMenuItem(getContainerBulkActions(selectedNodes));
      } else if (doc_type_id === 'Shipment::ShipmentCustomDetail') {
        setMenuItem(getShipmentCustomDetailBulkActions(selectedNodes));
      } else if (doc_type_id === 'Shipment::Cargo') {
        setMenuItem(getCargoBulkActions(selectedNodes));
      } else if (doc_type_id === 'Network::UserContact') {
        setMenuItem(getUserContactBulkActions(selectedNodes));
      } else if (doc_type_id === 'Network::Template') {
        setMenuItem(getTemplateBulkActions(selectedNodes));
      } else if (doc_type_id === 'Network::SalesPerson') {
        setMenuItem(getSalesPersonBulkActions(selectedNodes, permissions));
      } else if (doc_type_id === 'Network::Company') {
        setMenuItem(getCompanyBulkActions(selectedNodes));
      } else if (doc_type_id === 'ServiceManagement::Ticket::Ticket') {
        setMenuItem(getTicketBulkActions(selectedNodes));
      } else if (doc_type_id === 'SalesHub::Inquiry') {
        setMenuItem(getInquiryBulkAction(selectedNodes, session));
      } else if (doc_type_id === 'NewAccounting::ShipmentEstimate') {
        setMenuItem(getEstimateBulkActions(selectedNodes, isSpEnabled));
      }
    } else {
      onCloseBulkActionDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeCounts]);
  const handleActions = async (action: Action) => {
    let result: PerformAction | undefined;
    if (
      DOC_TYPE_METHODS[doc_type_id].has_bulk_actions &&
      selectedNodes.length > 0 &&
      action.performAction
    ) {
      result = await action.performAction(
        selectedNodes,
        onSuccess,
        selectedNodeCounts,
        action.extraProps,
        onClose
      );
    }
    const render_inside = action.extraProps?.render_inside || false;
    if (result && render_inside) {
      setActionParams(result?.actionParams);
      setChildTaskComponent(() => result?.component);
    } else if (result) {
      setActionParams(result?.actionParams);
      setTaskComponent(() => result?.component);
    }
  };
  const getRenderAction = useCallback(() => {
    const renderActions: Action[][] = [];
    const actionsWithType = groupBy(menuItems, 'type');
    const actionTypesArr = [PRIMARY_TYPE, SECONDARY_TYPE, TERTIARY_TYPE, NEGATIVE_TYPE];
    actionTypesArr.forEach((type: string) => {
      if (actionsWithType && actionsWithType[type]) {
        const currentAction = actionsWithType[type].filter((action) => action.isEnable);
        if (currentAction.length > 0) {
          renderActions.unshift(currentAction);
        }
      }
    });
    return renderActions;
  }, [menuItems]);
  const renderActionByMenuItems = () => {
    if (selectedNodes.length > 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
          {(getRenderAction() || []).map((actions: Action[], actionsId: number) => {
            return actions.map((item, index) => {
              let description = item.displayComponent;
              if (typeof item.displayComponent === 'function') {
                const ComponentWrapper = item.displayComponent;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                description = <ComponentWrapper key={item.key} {...item.componentProps} />;
              }
              if (!item.isEnable) {
                return null;
              }
              if (item.childComponents && item.childComponents.length === 0) return null;
              if (item.childComponents && item.childComponents.length > 0) {
                const childComponentsLength = item.childComponents.filter((c) => c.isEnable).length;
                return childComponentsLength > 0 ? (
                  <React.Fragment key={item.key}>
                    <Dropdown
                      placement="bottom"
                      menu={{
                        style: { minWidth: '200px' },
                        className: 'actions-menu',
                        items: item.childComponents.map((child) =>
                          child.isEnable
                            ? {
                                key: child.key,
                                onClick: () => {
                                  handleActions(child);
                                  setCurrentId(item.key);
                                  if (child.onClick) {
                                    child.onClick();
                                  }
                                },
                                label: (
                                  <div className={item.type === NEGATIVE_TYPE ? 'color-red' : ''}>
                                    <span className="action-icon">{child.icon}</span>
                                    {child.displayComponent}
                                  </div>
                                ),
                              }
                            : null
                        ),
                      }}
                      trigger={['click']}
                    >
                      <Button
                        className={item.type === NEGATIVE_TYPE ? 'color-red' : ''}
                        onClick={() => {
                          handleActions(item);
                          setCurrentId(item.key);
                          if (item.onClick) {
                            item.onClick();
                          }
                        }}
                        key={item.key}
                        type="link"
                        style={{
                          color: '#FFFFFF',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <span className="action-icon">{item.icon}</span>
                        {description}
                      </Button>
                    </Dropdown>
                    {currentId === item.key && ChildTaskComponent && (
                      <ChildTaskComponent
                        {...actionParams}
                        visible={true}
                        onClose={onClose} // make sure that you are handling onClose and onSuccess
                        onSuccess={onSuccess}
                      />
                    )}
                  </React.Fragment>
                ) : (
                  <></>
                );
              }
              return (
                <React.Fragment key={item.key}>
                  <Button
                    className={item.type === NEGATIVE_TYPE ? 'color-red' : ''}
                    onClick={() => {
                      handleActions(item);
                      setCurrentId(item.key);
                      if (item.onClick) {
                        item.onClick();
                      }
                    }}
                    key={item.key}
                    type="link"
                    style={{
                      color: '#FFFFFF',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <span className="action-icon">{item.icon}</span>
                    {description}
                  </Button>
                  {index === actions.length - 1 &&
                  actionsId !== (getRenderAction() || []).length - 1 ? (
                    <Divider type="vertical" style={{ color: '#FFFFFF' }} />
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              );
            });
          })}
        </div>
      );
    } else {
      return <></>;
    }
  };
  if (!DOC_TYPE_METHODS[doc_type_id].has_bulk_actions) {
    return <></>;
  }
  return (
    <>
      <Drawer
        placement="top"
        onClose={onCloseBulkActionDrawer}
        open={isBulkActionVisible}
        height="4rem"
        mask={false}
        maskClosable={false}
        styles={{ body: { background: 'var(--bg-primary-black-1)', padding: '0px 12px' } }}
        closable={false}
        title={null}
      >
        <div
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
            height: '100%',
          }}
        >
          <div style={{ color: '#FFFFFF' }}>
            <CheckCircleFilled />
            <span style={{ marginLeft: '3px' }}>{selectedNodeCounts} Selected</span>
          </div>
          <div style={{ textAlign: 'center', display: 'inline-block' }}>
            {(selectedNodeCounts && renderActionByMenuItems()) || <></>}
          </div>
          <div>
            <Button
              style={{ background: '#FFFFFF', color: 'var(--color-primary)' }}
              type="default"
              key="dismiss"
              shape="circle"
              icon={<CloseOutlined />}
              size="small"
              onClick={() => onCloseBulkActionDrawer()}
            />
          </div>
        </div>
      </Drawer>
      {TaskComponent && (
        <TaskComponent
          {...actionParams}
          visible={true}
          onClose={onClose} // make sure that you are handling onClose and onSuccess
          onSuccess={onSuccess}
        />
      )}
    </>
  );
});

export default BulkActionDrawer;
