import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMutation } from '@apollo/client';
import { Card, Descriptions, Button, Tooltip, message, Form, Row } from '@shipmnts/pixel-hub';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { getMainHopEtd } from 'operations/models/RoutingLeg';
import { UPDATE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { convertDatesTounix, convertToStringFormat } from '@shipmnts/pixel-hub';
import BookingOrderConfirmation from 'operations/modules/booking/components/BookingOrderForm/BookingOrderConfirmation';
import { errorMessageHandlerGraphQL } from 'common';

import {
  BOOKING_TYPE_SELF,
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_VENDOR,
  LOAD_TYPE_FCL,
} from 'operations/baseConstants';
import BookingOrderNewDesign from '../BookingOrderForm/BookingOrderNewDesign';
import { ROW_GUTTER } from 'operations/modules/shipment/constants';

const date_fields = [
  'booking_date',
  'valid_till_date',
  'gate_open_date',
  'vgm_cutoff_date',
  'icd_cutoff_date',
  'ams_cutoff_date',
  'gate_close_date',
  'doc_cutoff_date',
];

const getCutoffPickupDetails = (oto: OceanTransportOrderValue) => {
  const cols = [
    ...(oto.load_type === LOAD_TYPE_FCL
      ? [
          {
            key: 'valid_till_date',
            label: 'Validity date',
            value: convertToStringFormat(oto.valid_till_date),
          },
          {
            key: 'empty_pickup_location',
            label: 'Empty Pickup Yard',
            value: oto.empty_pickup_location?.name || '',
          },
          {
            key: 'empty_return_location',
            label: 'Empty Return Yard',
            value: oto.empty_return_location?.name || '',
          },
          {
            key: 'gate_open_date',
            label: 'Gate Open Date',
            value: convertToStringFormat(oto.gate_open_date),
          },
          {
            key: 'gate_close_date',
            label: 'Gate Close Date',
            value: convertToStringFormat(oto.gate_close_date),
          },
          {
            key: 'doc_cutoff_date',
            label: 'Docs Cutoff',
            value: convertToStringFormat(oto.doc_cutoff_date),
          },
        ]
      : []),
    {
      key: 'si_cutoff_date',
      label: 'SI Cutoff',
      value: convertToStringFormat(oto.si_cutoff_date),
    },
    {
      key: 'ams_cutoff_date',
      label: 'AMS Cutoff',
      value: convertToStringFormat(oto.ams_cutoff_date),
    },
    {
      key: 'icd_cutoff_date',
      label: 'ICD Cutoff',
      value: convertToStringFormat(oto.icd_cutoff_date),
    },
    {
      key: 'vgm_cutoff_date',
      label: 'VGM Cutoff',
      value: convertToStringFormat(oto.vgm_cutoff_date),
    },
    {
      key: 'origin_detention_free_days',
      label: 'Origin Detention Free Days',
      value: oto.origin_detention_free_days || '',
    },
    {
      key: 'origin_demurrage_free_days',
      label: 'Origin Demurrage Free Days',
      value: oto.origin_demurrage_free_days || '',
    },
    {
      key: 'destination_detention_free_days',
      label: 'Destination Detention Free Days',
      value: oto.destination_detention_free_days || '',
    },
    {
      key: 'destination_demurrage_free_days',
      label: 'Destination Demurrage Free Days',
      value: oto.destination_demurrage_free_days || '',
    },
  ];
  if (oto.booking_type === BOOKING_TYPE_SELF) {
    cols.push({
      key: 'surveyor_company',
      label: 'Surveyor',
      value: oto.surveyor_company?.registered_name || '',
    });
  }
  return cols;
};

const CutoffEmptyPickupDetailsCard = observer(function CutoffEmptyPickupDetailsCard(props: {
  oto: OceanTransportOrderValue;
  disabled?: boolean;
  disableReason?: string;
}): JSX.Element {
  const { oto, disabled, disableReason } = props;
  const [cutoffEdit, setCutoffEdit] = useState<{
    edit: boolean;
    beforeUpdate?: OceanTransportOrderValue;
  }>({ edit: false });

  const [updateOceanTransportOrder, { data, loading, error }] = useMutation(
    UPDATE_OCEAN_TRANSPORT_ORDER
  );

  useEffect(() => {
    if (!error && data && data.update_ocean_transport_order) {
      message.success('Cutoff And Empty Pickup Details Updated!');
      setCutoffEdit({ edit: false });
    }
  }, [data, error]);

  const etd = getMainHopEtd(oto.routing_legs);

  if (cutoffEdit.edit) {
    return (
      <Form
        scrollToFirstError
        name="booking_cutoff_details"
        layout="vertical"
        onFinish={(values: any) => {
          const { empty_pickup_location, empty_return_location, surveyor, ...restProps } = values;
          const payload = {
            ...restProps,
            ...convertDatesTounix(values, date_fields),
            empty_pickup_location_id: empty_pickup_location?.id,
            empty_return_location_id: empty_return_location?.id,
            surveyor_company_id: surveyor?.party_company?.id,
            surveyor_address_id: surveyor?.party_address?.id,
          };
          updateOceanTransportOrder({
            variables: { ocean_transport_order: { id: oto.id, ...payload } },
          });
        }}
        initialValues={{
          ...cutoffEdit.beforeUpdate,
          surveyor: {
            party_company: cutoffEdit?.beforeUpdate?.surveyor_company,
            party_address: cutoffEdit?.beforeUpdate?.surveyor_address,
          },
        }}
      >
        <Card
          id="cargos"
          title="Cutoff And Empty Pickup Details"
          extra={[
            <Button
              style={{ marginRight: '5px' }}
              key="cancel"
              disabled={loading}
              onClick={() => setCutoffEdit({ edit: false })}
            >
              Cancel
            </Button>,
            <Button loading={loading} key="save" htmlType="submit" type="primary">
              Save
            </Button>,
          ]}
        >
          {error && errorMessageHandlerGraphQL(error)}
          <Row gutter={ROW_GUTTER}>
            <BookingOrderNewDesign
              loadType={oto.load_type}
              bookingType={oto.booking_type}
              colSpan={
                oto.booking_type !== BOOKING_TYPE_VENDOR &&
                oto.booking_type !== BOOKING_TYPE_SHIPPING_LINE
                  ? 24 / 3
                  : 24 / 2
              }
              disableEmptyPickupLocation={oto.isAnyContainerPickedUp()}
              areCutoffRequired={oto.is_confirmed && oto.isShippingLineBooking}
            />
          </Row>
          <BookingOrderConfirmation
            etd={etd}
            disableCutoff={oto.getDisabledCutoffDateMapping()}
            areCutoffRequired={oto.is_confirmed && oto.isShippingLineBooking}
            load_type={oto.load_type}
            disableEmptyPickupLocation={oto.isAnyContainerPickedUp()}
            booking_type={oto.booking_type}
          />
        </Card>
      </Form>
    );
  }

  const cutoffPickupDetails = getCutoffPickupDetails(oto);
  return (
    <Card
      title="Cutoff And Empty Pickup Details"
      className="no-padding-description"
      extra={
        <Tooltip title={disableReason}>
          <Button
            disabled={disabled}
            onClick={() => {
              setCutoffEdit({
                edit: true,
                beforeUpdate: oto,
              });
            }}
          >
            Edit
          </Button>
        </Tooltip>
      }
    >
      <Descriptions column={4} colon={false} size="default" layout="vertical">
        {cutoffPickupDetails.map((desc) => (
          <Descriptions.Item key={desc.key} label={desc.label}>
            {desc.value}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Card>
  );
});

export default CutoffEmptyPickupDetailsCard;
