import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import DescriptionItem from './DescriptionItem';
import { ItemValue } from 'common/models/Item';
import { Divider, Space, Tag } from '@shipmnts/pixel-hub';
import { QtyUomTypeRenderer } from 'common/report_manager/components/Renderer/cellRenderer';
import { TagColorType } from '@shipmnts/pixel-hub/dist/components/tag/types';

interface ProductPropertyProps {
  product: ItemValue;
}
export default function ProductProperty(props: ProductPropertyProps) {
  const product_property = props?.product?.product_property;
  const mapping: {
    icon: React.JSX.Element;
    type: TagColorType;
  } = {
    icon: <CheckCircleOutlined />,
    type: 'info',
  };

  const checkBoxData = [
    {
      label: 'Product Property',
      content: (
        <Space wrap>
          {product_property?.is_temp_controlled && <Tag {...mapping}>Temperature Controlled</Tag>}
          {product_property?.is_hazardous && <Tag {...mapping}>Hazardous</Tag>}
          {product_property?.is_tiltable && <Tag {...mapping}>Tiltable</Tag>}
          {product_property?.is_perishable && <Tag {...mapping}>Perishable</Tag>}
          {product_property?.is_palletisable && <Tag {...mapping}>Palletizable</Tag>}
          {product_property?.is_battery && <Tag {...mapping}>Battery</Tag>}
          {product_property?.is_stackable && <Tag {...mapping}>Stackable</Tag>}
        </Space>
      ),
      span: 2,
    },
  ];
  const hazardousData = [
    { label: 'UNDG Number', content: product_property?.un_number || '' },
    { label: 'IMO Class', content: product_property?.un_imo_class || '' },
    { label: 'Proper Shipping Name', content: product_property?.proper_shipping_name || '' },
    { label: 'Technical Name', content: product_property?.technical_name || '' },
    { label: 'Packaging Group', content: product_property?.packaging_group || '' },
    {
      label: 'Flash Point',
      content: (
        <QtyUomTypeRenderer
          qty={product_property?.flash_point}
          uom={product_property?.flash_point_unit}
        />
      ),
    },
  ];

  const tempControlledData = [
    { label: 'Type Of Cooling', content: product_property?.cooling_type || '' },
    {
      label: 'Min Temperature',
      content: (
        <QtyUomTypeRenderer
          qty={product_property?.min_temperature}
          uom={product_property?.temperature_unit}
        />
      ),
    },
    {
      label: 'Max Temperature',
      content: (
        <QtyUomTypeRenderer
          qty={product_property?.max_temperature}
          uom={product_property?.temperature_unit}
        />
      ),
    },
  ];

  const batteryData = [{ label: 'Battery Type', content: product_property?.battery_type || '' }];
  return (
    <div style={{ padding: '15px', borderBottom: '1px solid #9FB1BD' }}>
      <DescriptionItem title="Product Properties" data={checkBoxData} />
      {product_property?.is_hazardous && (
        <>
          <Divider />
          <DescriptionItem title="Hazardous" data={hazardousData} />
        </>
      )}
      {product_property?.is_temp_controlled && (
        <>
          <Divider />
          <DescriptionItem title="Temperature Controlled" data={tempControlledData} />
        </>
      )}
      {product_property?.is_battery && (
        <>
          <Divider />
          <DescriptionItem title="Battery" data={batteryData} />
        </>
      )}
    </div>
  );
}
