/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import BranchAccount from './BranchAccount';

export const NODE_TYPE_WAREHOUSE = 'warehouse';
export const NODE_TYPE_STORAGE = 'storage';
export const NODE_TYPE_RACK = 'rack';
export const NODE_TYPE_SHELF = 'shelf';
export const NODE_TYPE_BIN = 'bin';

const WarehouseUnit = types.model({
  id: types.identifier,
  branch: types.maybe(types.maybeNull(types.late((): IAnyModelType => BranchAccount))),
  node_name: types.maybe(types.string),
  node_code: types.maybe(types.string),
  node_type: types.union(
    types.literal(NODE_TYPE_WAREHOUSE),
    types.literal(NODE_TYPE_STORAGE),
    types.literal(NODE_TYPE_RACK),
    types.literal(NODE_TYPE_SHELF),
    types.literal(NODE_TYPE_BIN)
  ),
  storage_capacity: types.model({
    max_gross_weight_unit: types.maybe(types.maybeNull(types.string)),
    max_gross_weight: types.maybe(types.maybeNull(types.number)),
    max_volume: types.maybe(types.maybeNull(types.number)),
    max_volume_unit: types.maybe(types.maybeNull(types.string)),
    max_storage_area: types.maybe(types.maybeNull(types.number)),
    max_storage_area_unit: types.maybe(types.maybeNull(types.string)),
    teu: types.maybe(types.maybeNull(types.string)),
  }),
  parent_unit: types.maybe(types.maybeNull(types.late((): IAnyModelType => WarehouseUnit))),
  parent_unit_id: types.maybe(types.maybeNull(types.string)),
  child_unit_ids: types.array(types.string),
  all_parent_unit_ids: types.array(types.string),
  warehouse_type: types.maybe(types.maybeNull(types.string)),
  type_of_storage: types.maybe(types.maybeNull(types.string)),
  customs_ref_code: types.maybe(types.maybeNull(types.string)),
  is_two_step: types.maybe(types.maybeNull(types.boolean)),
  default_receiving_location: types.maybe(
    types.maybeNull(types.late((): IAnyModelType => WarehouseUnit))
  ),
  default_delivery_location: types.maybe(
    types.maybeNull(types.late((): IAnyModelType => WarehouseUnit))
  ),
  default_damage_location: types.maybe(
    types.maybeNull(types.late((): IAnyModelType => WarehouseUnit))
  ),
  storage_charge: types.maybe(types.maybeNull(types.string)),
});

export interface WarehouseUnitValue extends Instance<typeof WarehouseUnit> {}
export interface WarehouseUnitIn extends SnapshotIn<typeof WarehouseUnit> {}
export interface WarehouseUnitOut extends SnapshotOut<typeof WarehouseUnit> {}

export default WarehouseUnit;
