import React, { useState, useCallback } from 'react';
import {
  Timeline,
  Row,
  Col,
  Input,
  Button,
  Select,
  Typography,
  Modal,
  Form,
  GlobalSearch,
} from '@shipmnts/pixel-hub';
import { VesselSearch } from 'common';
import { DeleteOutlined, PlusOutlined } from '@shipmnts/pixel-hub';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import VoyageSchedule, {
  VoyageScheduleInitialValue,
  VoyageScheduleValue,
} from 'operations/models/VoyageSchedule';
import { LocationType } from 'operations/models/Location';
import _pick from 'lodash/pick';
import { omit as _omit } from 'lodash';
import {
  ROUTING_TYPE_MAIN_CARRIAGE,
  RoutingLegValue,
  ROUTING_TYPE_PICKUP_DELIVERY,
  ROUTING_TYPE_DELIVERY,
  ROUTING_TYPE_ON_CARRIAGE,
  ROUTING_TYPE_PRE_CARRIAGE,
} from 'operations/models/RoutingLeg';
import { BOOKING_TYPE_SELF } from 'operations/baseConstants';
import { FreightType } from 'operations/modules/shipment/constants';
import { Draggable } from 'react-beautiful-dnd';
import { RoutingLegCallback, RoutingNodeCallback, RoutingNodeKeys } from './helpers';
import NodeAndLegsInfo from './NodeAndLeg';
import { RoutingNodesHashValue } from './RoutingDetails';
import VoyageNumberInput from 'operations/modules/booking/components/BookingSchedules/VoyageNumberInput';

const { Option } = Select;
const { Text } = Typography;

const GreyIcon = () => (
  <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="12" height="12" fill="#CCCCCC" rx="4" />
  </svg>
);

const RoutingLegRender = React.memo(function RoutingLegRender(props: {
  routing_leg: RoutingLegValue;
  routing_nodes: RoutingNodesHashValue;
  previous_routing_leg?: RoutingLegValue;
  index: number;
  onRoutingLegFieldChange: RoutingLegCallback;
  onRoutingNodeFieldChange: RoutingNodeCallback;
  total_legs: number;
  addHop: () => void;
  removeHop: (route_order: number) => void;
  showTerminal?: boolean;
  freightType?: FreightType;
  locationSearchType?: Array<LocationType>;
  errorsPresent?: boolean;
  disableNodes?: number[];
  disableRoutingLegs?: number[];
  disableAddRemoveTranshipmentHop?: boolean;
  validateVesselVoyage?: boolean;
  globalCarrierId?: string;
  isReeferContainer?: boolean;
  bookingType?: string | null;
  allowVoyageScheduleSearch?: boolean;
  routing_type?: string;
  showWaitingTime?: boolean;
  enableDragAndDrop?: boolean;
  disableVoyageUpdate?: boolean;
  showDpd?: boolean;
}): JSX.Element {
  const {
    routing_leg,
    previous_routing_leg,
    routing_nodes,
    index,
    routing_type,
    onRoutingLegFieldChange,
    onRoutingNodeFieldChange,
    total_legs,
    addHop,
    removeHop,
    showTerminal,
    locationSearchType,
    errorsPresent,
    disableNodes,
    disableRoutingLegs,
    disableAddRemoveTranshipmentHop,
    validateVesselVoyage,
    globalCarrierId,
    isReeferContainer,
    bookingType,
    disableVoyageUpdate,
    showDpd = false,
    showWaitingTime = false,
    enableDragAndDrop = false,
  } = props;
  const isFirstMainCarriageHop =
    routing_leg.routing_type === ROUTING_TYPE_MAIN_CARRIAGE && index === 0;
  const showVoyageScheduleSearch = isFirstMainCarriageHop;
  const [deleteHighlight, setDeleteHighlight] = useState(false);
  const [showEditVoyageSchedule, setShowEditVoyageSchedule] = useState(false);
  const VoyageNumberLableMapping: { [key: string]: string } = {
    air: 'Flight number',
    road: 'Truck number',
    rail: 'Train number',
  };
  const voyageNumberLabel =
    (routing_leg?.mode_of_transit && VoyageNumberLableMapping[routing_leg?.mode_of_transit]) ||
    'Voyage number';

  const showVoyageCutoffMissingWarning = useCallback(() => {
    Modal.warning({
      title: 'Voyage Schedule Cutoff Date Missing!',
      content:
        'you need to edit voyage schedule details for selected voyage number to prefill cutoff dates',
    });
  }, []);
  const disabled = disableRoutingLegs?.includes(index);
  const routingTypeIsNotPickupDelivery =
    routing_type !== ROUTING_TYPE_PICKUP_DELIVERY && routing_type !== ROUTING_TYPE_DELIVERY;

  return (
    <>
      <Draggable draggableId={index.toString()} index={index} isDragDisabled={!enableDragAndDrop}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              userSelect: 'none',
              padding: 16,
              margin: '0 0 8px 0',
              background: 'white',
              ...provided.draggableProps.style,
            }}
          >
            <Timeline.Item
              dot={<GreyIcon />}
              key={index + '_origin'}
              style={{ padding: 0 }}
              className={deleteHighlight ? 'item-hover-bg' : ''}
            >
              <NodeAndLegsInfo
                node={routing_nodes[routing_leg.origin_id || '']}
                incoming_edge={previous_routing_leg}
                outgoing_edge={routing_leg}
                onRoutingLegFieldChange={onRoutingLegFieldChange}
                onRoutingNodeFieldChange={onRoutingNodeFieldChange}
                index={index}
                routing_type={routing_type}
                disableNode={disableNodes?.includes(index)}
                disabled={disabled}
                showTerminal={showTerminal}
                locationSearchType={locationSearchType}
                errorsPresent={errorsPresent}
                validateVesselVoyage={validateVesselVoyage}
                disableVoyageUpdate={disableVoyageUpdate}
                showWaitingTime={showWaitingTime}
              />
              {(routing_type === ROUTING_TYPE_PICKUP_DELIVERY ||
                routing_type === ROUTING_TYPE_DELIVERY) && (
                <div style={{ marginLeft: '15px', marginBottom: '15px' }}>
                  {index === total_legs - 1 && (
                    <Button
                      disabled={disableAddRemoveTranshipmentHop}
                      icon={<PlusOutlined />}
                      onClick={addHop}
                      size="small"
                      style={{ marginRight: '8px' }}
                    >
                      Add Transhipment Hop
                    </Button>
                  )}
                  {index !== 0 && (
                    <Button
                      onMouseEnter={() => setDeleteHighlight(true)}
                      onMouseLeave={() => setDeleteHighlight(false)}
                      type={deleteHighlight ? 'primary' : 'default'}
                      onClick={() => removeHop(index)}
                      danger={deleteHighlight}
                      size="small"
                      icon={<DeleteOutlined />}
                      disabled={disableAddRemoveTranshipmentHop}
                    >
                      Delete Transhipment Hop
                    </Button>
                  )}
                </div>
              )}
            </Timeline.Item>
          </div>
        )}
      </Draggable>

      {routingTypeIsNotPickupDelivery && (
        <Timeline.Item
          className={deleteHighlight ? 'item-hover-bg' : ''}
          dot={<GreyIcon />}
          key={index + '_edge'}
        >
          <Row style={{ marginLeft: '5px' }} gutter={16}>
            {[ROUTING_TYPE_ON_CARRIAGE, ROUTING_TYPE_PRE_CARRIAGE].includes(
              routing_leg?.routing_type || ''
            ) && (
              <Col span={6}>
                <Form.Item label="Mode of Transit">
                  <Select
                    value={routing_leg?.mode_of_transit || ''}
                    onChange={(val) => onRoutingLegFieldChange('mode_of_transit', val, index)}
                    placeholder="Transit through"
                    disabled={disabled || disableVoyageUpdate}
                  >
                    <Option key="road" value="road">
                      Road
                    </Option>
                    <Option key="rail" value="rail">
                      Rail
                    </Option>
                    <Option key="river" value="river">
                      River
                    </Option>
                    <Option key="air" value="air">
                      Air
                    </Option>
                    <Option key="sea" value="sea">
                      Sea
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            {['sea', 'river', 'air'].includes(routing_leg?.mode_of_transit || '') && (
              <Col span={6}>
                {routing_leg?.mode_of_transit !== 'air' ? (
                  <Form.Item
                    label={'Vessel'}
                    required={
                      index === 0 && validateVesselVoyage && bookingType !== BOOKING_TYPE_SELF
                    }
                    validateStatus={
                      index === 0 && errorsPresent && validateVesselVoyage && !routing_leg.vessel
                        ? 'error'
                        : 'success'
                    }
                  >
                    <VesselSearch
                      value={routing_leg.vessel}
                      onChange={(value: any) => onRoutingLegFieldChange('vessel', value, index)}
                      disabled={disabled || disableVoyageUpdate}
                    />
                    {index === 0 && errorsPresent && validateVesselVoyage && !routing_leg.vessel ? (
                      <Text type="danger">Required</Text>
                    ) : (
                      <span />
                    )}
                  </Form.Item>
                ) : (
                  <Form.Item label={'Carrier'}>
                    <GlobalSearch
                      doc_type="Global::Carrier"
                      value={routing_leg?.global_carrier || undefined}
                      searchProps={{ carrier_type: ['air'] }}
                      onChange={(val) => onRoutingLegFieldChange('global_carrier', val, index)}
                      selectMode={undefined}
                    />
                    {index === 0 &&
                    errorsPresent &&
                    validateVesselVoyage &&
                    !routing_leg.global_carrier ? (
                      <Text type="danger">Required</Text>
                    ) : (
                      <span />
                    )}
                  </Form.Item>
                )}
              </Col>
            )}
            <Col span={showVoyageScheduleSearch ? 12 : 6}>
              <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.voyage_schedule_id !== currentValues.voyage_schedule_id ||
                  prevValues.carrier?.id !== currentValues.carrier?.id
                }
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const fieldErrorValidation =
                    index === 0 &&
                    errorsPresent &&
                    validateVesselVoyage &&
                    !routing_leg.voyage_number &&
                    bookingType !== BOOKING_TYPE_SELF ? (
                      <Text type="danger">Required</Text>
                    ) : (
                      <span />
                    );
                  const voyage_schedule_id = getFieldValue('voyage_schedule_id');
                  const carrier = getFieldValue('carrier');
                  const vessel = routing_leg?.vessel || undefined;
                  const node = routing_nodes[routing_leg.origin_id || ''];
                  const origin_id = node?.id || node?._id;
                  const routing_legs = [
                    {
                      sequence_number: 1,
                      mode_of_transit: routing_leg?.mode_of_transit,
                      estimated_time_of_departure: routing_leg?.estimated_time_of_departure,
                      origin: { ..._omit(node, ['id']), _id: origin_id },
                      origin_id,
                    },
                  ] as Array<RoutingLegValue>;
                  const initialVoyageScheduleFormData: VoyageScheduleInitialValue = {
                    carriers: carrier ? [carrier] : [],
                    vessel,
                    routing_legs,
                  };

                  const onVoyageScheduleChange = (
                    _voyageSchedule: VoyageScheduleValue | undefined | null,
                    isPartialMatch?: boolean
                  ) => {
                    _voyageSchedule = _voyageSchedule
                      ? VoyageSchedule?.create(_voyageSchedule)
                      : null;
                    onRoutingLegFieldChange('voyage_number', _voyageSchedule?.voyage_number, index);
                    onRoutingLegFieldChange('vessel', _voyageSchedule?.vessel, index);
                    const location_id = node?.location?.id?.toString();
                    if (!isPartialMatch)
                      onRoutingLegFieldChange(
                        'estimated_time_of_departure',
                        _voyageSchedule?.getETDByOriginLocation(location_id),
                        index
                      );
                    const routingNode =
                      _voyageSchedule?.getRoutingNodeByOriginLocation(location_id) ||
                      ({} as RoutingNodeValue);
                    if (!isPartialMatch)
                      onRoutingNodeFieldChange(
                        'terminal',
                        routingNode?.terminal,
                        node.id || node._id || ''
                      );
                    const fields: RoutingNodeKeys[] = [
                      'gate_open_date',
                      'gate_close_date',
                      'si_cutoff_date',
                      'doc_cutoff_date',
                      'vgm_cutoff_date',
                    ];
                    const formValues = _pick(routingNode, fields);

                    if (isReeferContainer) {
                      formValues['gate_open_date'] = routingNode['reefer_gate_open_date'];
                      formValues['gate_close_date'] = routingNode['reefer_gate_close_date'];
                    }
                    if (
                      _voyageSchedule &&
                      !isPartialMatch &&
                      !(formValues.gate_open_date && formValues.gate_close_date)
                    ) {
                      showVoyageCutoffMissingWarning();
                    }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    formValues['voyage_schedule_id'] = _voyageSchedule?.id;
                    setFieldsValue(formValues);
                  };

                  const onVoyageNumberChange = (voyage_number: string) => {
                    if (!voyage_number) setFieldsValue({ voyage_schedule_id: null });
                    onRoutingLegFieldChange('voyage_number', voyage_number, index);
                  };

                  return (
                    <Form.Item
                      label={
                        showVoyageScheduleSearch ? (
                          <span onClick={(e) => e.preventDefault()}>
                            {voyageNumberLabel}
                            <Button
                              type="link"
                              size="small"
                              disabled={!voyage_schedule_id}
                              onClick={() => setShowEditVoyageSchedule(true)}
                              style={{ float: 'right' }}
                            >
                              Edit
                            </Button>
                          </span>
                        ) : (
                          voyageNumberLabel
                        )
                      }
                      required={
                        index === 0 && validateVesselVoyage && bookingType !== BOOKING_TYPE_SELF
                      }
                      validateStatus={
                        index === 0 &&
                        errorsPresent &&
                        validateVesselVoyage &&
                        !routing_leg.voyage_number &&
                        bookingType !== BOOKING_TYPE_SELF
                          ? 'error'
                          : 'success'
                      }
                    >
                      {showVoyageScheduleSearch ? (
                        <VoyageNumberInput
                          value={routing_leg.voyage_number || undefined}
                          showEditVoyageSchedule={showEditVoyageSchedule}
                          voyageScheduleId={getFieldValue('voyage_schedule_id')}
                          onEditCloseDrawer={() => setShowEditVoyageSchedule(false)}
                          initialVoyageScheduleFormData={initialVoyageScheduleFormData}
                          disabled={disabled || disableVoyageUpdate}
                          onChange={onVoyageNumberChange}
                          onVoyageChange={(
                            _voyageSchedule: VoyageScheduleValue | undefined | null,
                            isPartialMatch?: boolean
                          ) => {
                            onVoyageScheduleChange(_voyageSchedule, isPartialMatch);
                          }}
                          searchProps={{
                            vessel: vessel,
                            carrier: carrier || {
                              id: globalCarrierId,
                            },
                            location: node?.location,
                          }}
                        />
                      ) : (
                        <Input
                          value={routing_leg.voyage_number || ''}
                          placeholder={voyageNumberLabel}
                          onChange={(e) =>
                            onRoutingLegFieldChange('voyage_number', e.target.value, index)
                          }
                          disabled={disabled || disableVoyageUpdate}
                        />
                      )}
                      {fieldErrorValidation}
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            {index !== 0 && (
              <Col style={{ display: 'flex', alignItems: 'center' }} span={4}>
                <Button
                  onMouseEnter={() => setDeleteHighlight(true)}
                  onMouseLeave={() => setDeleteHighlight(false)}
                  type={deleteHighlight ? 'primary' : 'default'}
                  onClick={() => removeHop(index)}
                  danger={deleteHighlight}
                  size="small"
                  icon={<DeleteOutlined />}
                  disabled={disableAddRemoveTranshipmentHop}
                >
                  Delete Transhipment Hop
                </Button>
              </Col>
            )}
          </Row>
          {index === total_legs - 1 && (
            <Button
              disabled={disableAddRemoveTranshipmentHop}
              icon={<PlusOutlined />}
              onClick={addHop}
              size="small"
            >
              Add Transhipment Hop
            </Button>
          )}
        </Timeline.Item>
      )}
      {index === total_legs - 1 && (
        <Draggable
          key={index + '_destination_drag'}
          draggableId={(index + 1).toString()}
          index={index + 1}
          isDragDisabled={!enableDragAndDrop}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                userSelect: 'none',
                padding: 16,
                margin: '0 0 8px 0',
                background: 'white',
                ...provided.draggableProps.style,
              }}
            >
              <Timeline.Item
                dot={<GreyIcon />}
                key={index + '_destination'}
                // className="ant-timeline-item-last"
                style={{ padding: 0 }}
              >
                <NodeAndLegsInfo
                  node={routing_nodes[routing_leg.destination_id || '']}
                  incoming_edge={routing_leg}
                  onRoutingLegFieldChange={onRoutingLegFieldChange}
                  onRoutingNodeFieldChange={onRoutingNodeFieldChange}
                  index={index}
                  showTerminal={showTerminal}
                  disableNode={disableNodes?.includes(total_legs)}
                  disabled={disableRoutingLegs?.includes(total_legs)}
                  locationSearchType={locationSearchType}
                  errorsPresent={errorsPresent}
                  routing_type={routing_type}
                  disableVoyageUpdate={disableVoyageUpdate}
                  showDpd={showDpd}
                  showWaitingTime={showWaitingTime}
                />
              </Timeline.Item>
            </div>
          )}
        </Draggable>
      )}
    </>
  );
});

export default RoutingLegRender;
