import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Tabs, Divider, Layout, TabsProps } from '@shipmnts/pixel-hub';
import ShipmentSummary from './ShipmentSummary';
import Statuses from './Overview/Statuses';
import {
  SHIPMENT_TYPE_GENERAL,
  SHIPMENT_TYPE_WAREHOUSE,
} from 'operations/modules/reports/constants';
import { useSession } from 'common';
import { ShipmentTabType } from './ShipmentDetailLayout';
import { ShipmentValue } from 'operations/models/Shipment';
import { PERMISSION_ACCOUNTING_TAB, PERMISSION_ESTIMATES } from 'operations/permissions';
import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_HOUSE,
  TRADE_TYPE_EXPORT,
} from '../../constants';
import { SHIPMENT_STATUS_PLANNED } from 'operations/modules/reports/constants';
import { hasPermission } from '@shipmnts/pixel-hub';
import { LOAD_TYPE_FCL } from 'operations/baseConstants';

const ShipmentProductsTab = React.lazy(
  () => import('operations/modules/orderManagement/OMS/Tabs/ShipmentProductsTab')
);
const CargoDetailsTab = React.lazy(() => import('./Cargo/CargoTab'));
const BookingTab = React.lazy(
  () => import('operations/modules/orderManagement/OMS/Tabs/BookingTab')
);
const FsuTrackingTab = React.lazy(() => import('./Overview/FsuTrackingTab'));
const OverviewTab = React.lazy(() => import('./Overview/OverviewTab'));
const ManifestTab = React.lazy(() => import('./Manifests/ManifestTab'));
const DocumentTab = React.lazy(() => import('./Documents/DocumentTab'));
const AttachedHousesTab = React.lazy(() => import('./AttachedHouse/AttachedHousesTab'));
const AccountsTab = React.lazy(() => import('./Accounts/AccountsTab'));
const CustomsTab = React.lazy(() => import('./Customs/CustomsTab'));
const ShipmentEstimatesTab = React.lazy(() => import('./Estimates/ShipmentEstimatesTab'));
const ContainerTab = React.lazy(() => import('./Containers/ContainerTab'));

const { Content } = Layout;

interface ShipmentDetailContentProps {
  externalLink?: boolean;
  data: any;
  tab?: ShipmentTabType;
  shipment: ShipmentValue;
  id: string;
}

const TabComponentMap = new Map();
TabComponentMap.set('overview', OverviewTab);
TabComponentMap.set('attached_houses', AttachedHousesTab);
TabComponentMap.set('containers', ContainerTab);
TabComponentMap.set('cargo_details', CargoDetailsTab);
TabComponentMap.set('customs', CustomsTab);
TabComponentMap.set('documents', DocumentTab);
TabComponentMap.set('estimates', ShipmentEstimatesTab);
TabComponentMap.set('accounts', AccountsTab);
TabComponentMap.set('products', ShipmentProductsTab);
TabComponentMap.set('manifests', ManifestTab);
TabComponentMap.set('status', FsuTrackingTab);
TabComponentMap.set('cargo_details', CargoDetailsTab);
TabComponentMap.set('bookings', BookingTab);
TabComponentMap.set('status', FsuTrackingTab);

const ShipmentDetailContent = (props: ShipmentDetailContentProps) => {
  const { externalLink, data, tab, shipment, id } = props;
  const sessionData = useSession();
  const summaryRef = useRef<null | HTMLDivElement>(null);
  const currentTab =
    sessionData.company_account.primary_business === 'manufacturer' ||
    sessionData.company_account.primary_business === 'trader'
      ? 'products'
      : shipment.freight_type === FREIGHT_TYPE_OCEAN && shipment.trade_type === TRADE_TYPE_EXPORT
      ? 'bookings'
      : 'documents';
  const [activeTab, setActivetab] = useState<ShipmentTabType>(tab || currentTab);
  useEffect(() => {
    const height = summaryRef?.current?.scrollHeight;
    if (height) window.scrollBy(0, height);
  }, [activeTab]);
  useEffect(() => {
    setActivetab(tab || currentTab);
  }, [id, tab, currentTab]);
  const getTab = () => {
    const tabs: TabsProps['items'] = [];
    if (
      shipment.isOceanShipment() &&
      sessionData.company_account.primary_business !== 'manufacturer' &&
      sessionData.company_account.primary_business !== 'trader'
    )
      tabs.push({ label: 'Bookings', key: 'bookings' });
    if (
      sessionData.company_account?.subscriptions?.some((sub: any) => sub?.app?.name === 'Eawb') &&
      data?.shipment?.freight_type === FREIGHT_TYPE_AIR &&
      data?.shipment?.job_number &&
      data?.shipment?.shipment_type !== SHIPMENT_TYPE_HOUSE
    ) {
      tabs.push({ label: 'Status', key: 'status' });
    }

    if (
      sessionData.company_account.primary_business === 'manufacturer' ||
      sessionData.company_account.primary_business === 'trader'
    ) {
      TabComponentMap.set('products', ShipmentProductsTab);
      tabs.push({ label: 'Product & Invoices', key: 'products' });
      tabs.push({ label: 'Documents', key: 'documents' });
    }

    if (
      shipment.freight_type !== FREIGHT_TYPE_ROAD &&
      (data?.shipment.shipment_type === SHIPMENT_TYPE_CONSOL ||
        data?.shipment.shipment_type === SHIPMENT_TYPE_DIRECT ||
        data?.shipment.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK)
    )
      tabs.push({ label: 'Attached Houses', key: 'attached_houses' });

    if (shipment.freight_type === FREIGHT_TYPE_ROAD && !shipment.isRoadSplitShipmentExecuted()) {
      if (!data?.shipment.split_from_order_id) {
        if (!data?.shipment.job_number)
          // road customer order
          tabs.push({ label: 'Linked Trips', key: 'attached_houses' });
        if (data?.shipment.shipment_type === SHIPMENT_TYPE_CONSOL)
          tabs.push({ label: 'Linked Shipments', key: 'attached_houses' });
      } else {
        // trips case
        let showLinkedShipmentInTrips = data?.shipment.shipment_type !== SHIPMENT_TYPE_HOUSE;
        if (data?.shipment?.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK) {
          showLinkedShipmentInTrips =
            data?.shipment.cargos.length || data?.shipment?.shipment_containers?.length;
        }

        if (showLinkedShipmentInTrips) tabs.push({ label: 'Linked CNs', key: 'attached_houses' });
      }
    }

    if (
      data?.shipment.freight_type === FREIGHT_TYPE_ROAD &&
      data?.shipment.load_type === LOAD_TYPE_FCL
    )
      tabs.push({ label: 'Containers', key: 'containers' });
    else if (data?.shipment.freight_type === FREIGHT_TYPE_OCEAN) {
      tabs.push({ label: 'Containers', key: 'containers' });
    }
    if (data?.shipment.freight_type === FREIGHT_TYPE_ROAD)
      tabs.push({ label: 'Cargo Details', key: 'cargo_details' });
    if (
      (![SHIPMENT_TYPE_CONSOL, SHIPMENT_TYPE_GENERAL, SHIPMENT_TYPE_WAREHOUSE].includes(
        data?.shipment?.shipment_type
      ) &&
        data?.shipment?.status !== SHIPMENT_STATUS_PLANNED &&
        data?.shipment?.job_number &&
        data?.shipment?.freight_type !== FREIGHT_TYPE_ROAD) ||
      (data.shipment?.freight_type === FREIGHT_TYPE_ROAD &&
        [null, SHIPMENT_TYPE_DIRECT].includes(data?.shipment?.shipment_type))
    )
      tabs.push({ label: 'Customs', key: 'customs' });
    if (
      data?.shipment?.status !== SHIPMENT_STATUS_PLANNED &&
      data.shipment?.shipment_type !== SHIPMENT_TYPE_GENERAL &&
      data.shipment?.shipment_type !== SHIPMENT_TYPE_WAREHOUSE
    )
      tabs.push({ label: 'Manifests', key: 'manifests' });
    if (
      sessionData.company_account.primary_business !== 'manufacturer' &&
      sessionData.company_account.primary_business !== 'trader'
    )
      tabs.push({ label: 'Documents', key: 'documents' });
    if (
      hasPermission(sessionData.permissions, {
        name: PERMISSION_ESTIMATES,
        docType: 'Shipment::Shipment',
      })
    )
      tabs.push({ label: 'Estimates', key: 'estimates' });
    if (
      hasPermission(sessionData.permissions, {
        name: PERMISSION_ACCOUNTING_TAB,
        docType: 'Shipment::Shipment',
      })
    )
      tabs.push({ label: 'Accounts', key: 'accounts' });

    return tabs;
  };

  useEffect(() => {
    const { search } = window.location;
    const search_query = new URLSearchParams(search);
    const tab = search_query.get('tab') as ShipmentTabType;
    if (tab) setActivetab(tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.shipment?.shipment_type]);
  const TabComponent = TabComponentMap.get(activeTab);
  return (
    <Content style={{ padding: '16px 24px', paddingTop: externalLink ? '12px' : '16px' }}>
      <div ref={summaryRef}>
        <ShipmentSummary />
        {data.shipment?.shipment_type !== SHIPMENT_TYPE_GENERAL &&
          data.shipment?.shipment_type !== SHIPMENT_TYPE_WAREHOUSE && <Statuses />}
        <Divider style={{ marginBottom: '0px' }} />
      </div>
      <Tabs
        activeKey={activeTab}
        onTabClick={(key: string) => {
          setActivetab(key as ShipmentTabType);
        }}
        tabBarStyle={{
          paddingLeft: '10px',
        }}
        items={getTab()}
      ></Tabs>
      <Content style={{ minHeight: '500px' }}>
        <div style={{ height: '100%', overflow: 'auto' }}>
          <Suspense fallback={<div>Loading...</div>}>
            {(TabComponent && <TabComponent externalLink={externalLink} />) || <></>}
          </Suspense>
        </div>
      </Content>
    </Content>
  );
};

export default ShipmentDetailContent;
