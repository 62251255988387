import { Drawer, DrawerFooter, FormInstance } from '@shipmnts/pixel-hub';

import React, { MutableRefObject, useRef } from 'react';
import { useLocation } from 'wouter';
import { GridOptions } from '@ag-grid-community/core';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';
import { WarehouseTransactionProductValue } from 'operations/models/WarehouseTransactionProduct';
import { omit } from 'lodash';
import StockSummaryTable from './StockSummaryTable';
import { preComputationProduct } from '../helpers';
interface FetchStockSummaryDrawerProps {
  transaction?: WarehouseTransactionValue;
  nextTransaction?: string;
  purposeOfTransfer?: string;
  transaction_type?: string;
  setAllocatedProducts?: (value: WarehouseTransactionProductValue[]) => void;
  isVisible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  setIsVisible?: (value: boolean) => void;
  gridRef: MutableRefObject<GridOptions | undefined>;
  customer?: any;
  branch: any;
  form?: FormInstance;
}

const FetchStockSummaryDrawer = (props: any) => {
  const {
    transaction,
    purposeOfTransfer,
    transaction_type,
    nextTransaction,
    onClose,
    setAllocatedProducts,
    isVisible,
    setIsVisible,
    gridRef,
    customer,
    branch,
    form,
  } = props;
  const { 1: navigate } = useLocation();

  const closeDrawer = () => {
    onClose && onClose();
    setIsVisible && setIsVisible(false);
  };
  const onSaveDrawer = () => {
    const selectedProducts = gridRef?.current?.api?.getSelectedRows() || [];
    const updatedData: any = selectedProducts.map((obj: any) =>
      Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key.startsWith('wms_stock_summary_') ? key.replace('wms_stock_summary_', '') : key,
          value,
        ])
      )
    );
    const old_products = form?.getFieldValue('warehouse_transaction_products') || [];
    const products: any[] = updatedData
      .map((product: any) => {
        return {
          ...omit(product, [
            'childCount',
            'key_for_group',
            'pretty_display',
            'warehouse_unit_id',
            'product_id',
            'customer_company_id',
            'transaction_date',
            'receipt_id',
            'id',
          ]),
          receipt_date: product?.receipt_date,
          compare_id: product?.id,
          receipt_id: product?.receipt_id?.record_details?.id,
          receipt_number: product?.receipt_id?.record_details?.transaction_number,
          customer: product?.customer_company_id?.record_details,
          product_name: product?.product_id?.record_details,
          product_id: product?.product_id?.id,
          from_location: {
            id: product?.warehouse_unit_id?.id,
            node_name: product?.warehouse_unit_id?.record_details,
          },
          ...preComputationProduct(product),
        };
      })
      .filter(
        (product: any) =>
          !old_products.some((old_product: any) => old_product.compare_id === product.compare_id)
      );

    if (!!setAllocatedProducts) setAllocatedProducts([...old_products, ...products]);
    else {
      const value = {
        ...transaction,
        purpose_of_transfer: purposeOfTransfer,
        warehouse_transaction_products: [...old_products, ...products],
      };
      navigate(`~/form/${nextTransaction}/new`, {
        state: {
          transaction: JSON.stringify(value),
        },
      });
    }
    closeDrawer();
  };

  return (
    <Drawer
      title={'Stock Ledger'}
      width={'90%'}
      onClose={closeDrawer}
      open={isVisible}
      footer={<DrawerFooter saveText="Add Products" onSave={onSaveDrawer} onClose={closeDrawer} />}
    >
      <StockSummaryTable
        rowSelection={'multiple'}
        gridRef={gridRef}
        transaction={transaction}
        purposeOfTransfer={purposeOfTransfer}
        transaction_type={transaction_type}
        customer={customer}
        branch={branch}
      />
    </Drawer>
  );
};

export default FetchStockSummaryDrawer;

export const FetchStockSummaryDrawerWrapper = (props: FetchStockSummaryDrawerProps) => {
  const gridRef = useRef<GridOptions>();
  return (
    <FetchStockSummaryDrawer
      {...props}
      branch={props?.transaction?.branch?.id}
      gridRef={gridRef}
    ></FetchStockSummaryDrawer>
  );
};
