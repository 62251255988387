import React from 'react';
import {
  Card,
  Row,
  Col,
  Input,
  Form,
  DatePicker,
  Select,
  InfoCircleOutlined,
} from '@shipmnts/pixel-hub';
import { AddressCompanySearch } from 'common';
import {
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_VENDOR,
  LOAD_TYPE_LCL,
  LOAD_TYPE_FCL,
  BOOKING_TYPE_SELF,
} from 'operations/baseConstants';
import { CarrierType } from 'operations/models/Carrier';
import BookingOrderNewDesign from './BookingOrderNewDesign';
import {
  OCEAN_TRANSPORT_ORDER_RATE_TYPES,
  SHIPPING_LINE_SERVICE_TYPES,
  TRADE_TYPE_IMPORT,
} from 'operations/modules/shipment/constants';
import { ShipmentValue } from 'operations/models/Shipment';
import { GlobalSearch } from '@shipmnts/pixel-hub';
import { RadioOptionProps, SelectOption } from 'operations/commonTypeDefs';
import { FieldDisableReason } from 'operations/commonTypeDefs';
import BookingOrderNumber from './BookingOrderNumber';
import { FormInstance } from 'rc-field-form';
const ROW_GUTTER = 16;

const BookingOrderBasicDetails = React.memo(function BookingOrderBasicDetails(props: {
  booking_type: string;
  disabledFields?: Record<string, FieldDisableReason>;
  load_type?: string;
  areCutoffRequired?: boolean;
  disableEmptyPickupLocation?: boolean;
  shipment?: ShipmentValue;
  form?: FormInstance;
}): JSX.Element {
  const {
    shipment,
    booking_type,
    disabledFields,
    load_type,
    areCutoffRequired,
    disableEmptyPickupLocation,
    form,
  } = props;
  const carrier_types: CarrierType[] = ['ocean', 'nvocc'];
  if (load_type === LOAD_TYPE_LCL) carrier_types.push('coloader');
  return (
    <Card title="Basic Details">
      <Row gutter={ROW_GUTTER}>
        <Col span={12}>
          <Form.Item
            required={booking_type === BOOKING_TYPE_SHIPPING_LINE || load_type === LOAD_TYPE_LCL}
            rules={[
              {
                required:
                  booking_type === BOOKING_TYPE_SHIPPING_LINE || load_type === LOAD_TYPE_LCL,
              },
            ]}
            name="carrier"
            label={load_type === LOAD_TYPE_LCL ? 'Carrier/Co-loader' : 'Carrier'}
            tooltip={
              disabledFields?.carrier?.reason
                ? {
                    title: disabledFields?.carrier?.reason,
                    icon: <InfoCircleOutlined />,
                  }
                : undefined
            }
          >
            <GlobalSearch
              doc_type="Global::Carrier"
              disabled={disabledFields?.['carrier']?.disable}
              searchProps={{ carrier_type: carrier_types }}
            />
          </Form.Item>
          {booking_type === BOOKING_TYPE_VENDOR && load_type === LOAD_TYPE_FCL && (
            <Form.Item name="vendor" required rules={[{ required: true }]} label="Booking Vendor">
              <AddressCompanySearch
                companySearchProps={{
                  searchProps: { is_vendor: true },
                }}
              />
            </Form.Item>
          )}
          <Form.Item required rules={[{ required: true }]} name="customer" label="Booking Party">
            <AddressCompanySearch companySearchProps={{ disabled: true }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.confirmed_booking !== currentValues.confirmed_booking
            }
          >
            {({ getFieldValue }) => {
              const confirmed_booking = getFieldValue('confirmed_booking');
              const required =
                (booking_type === BOOKING_TYPE_SHIPPING_LINE || load_type === LOAD_TYPE_LCL) &&
                confirmed_booking;
              if (booking_type === BOOKING_TYPE_SELF) return <></>;
              return (
                <BookingOrderNumber required={required} label={'Booking Number'} form={form} />
              );
            }}
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="booking_date"
                label="Booking date"
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                label="Service Type"
                name={'service_type'}
              >
                <Select placeholder="Select Port to Port, ICD to Port...">
                  {SHIPPING_LINE_SERVICE_TYPES.map((option: RadioOptionProps, index: number) => (
                    <Select.Option key={index} value={option.key}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item required rules={[{ required: true }]} name="sales_person" label="Order By">
            <GlobalSearch doc_type="Network::SalesPerson" />
          </Form.Item>
          {booking_type !== BOOKING_TYPE_SELF && (
            <Form.Item name="rate_type" label="Rate Type">
              <Select placeholder="Rate Type">
                {OCEAN_TRANSPORT_ORDER_RATE_TYPES.map((option: SelectOption, index: number) => (
                  <Select.Option key={index} label={option.label} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {load_type === LOAD_TYPE_FCL && (
            <Form.Item name="contract_number" label="RA number">
              <Input placeholder="RA number" />
            </Form.Item>
          )}
        </Col>
      </Row>

      {shipment?.trade_type !== TRADE_TYPE_IMPORT && (
        <Row gutter={ROW_GUTTER}>
          <BookingOrderNewDesign
            bookingType={booking_type}
            loadType={load_type}
            colSpan={24 / 2}
            areCutoffRequired={areCutoffRequired}
            disableEmptyPickupLocation={disableEmptyPickupLocation}
            tradeType={shipment?.trade_type}
          />
        </Row>
      )}
    </Card>
  );
});

export default BookingOrderBasicDetails;
