import React from 'react';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';
import WMSProducts from '../Components/WMSProducts';
interface WMSProductTabsProps {
  warehouseTransaction: WarehouseTransactionValue;
}
export default function ProductsTabs(props: WMSProductTabsProps) {
  const { warehouseTransaction } = props;
  // let qty = 0;
  // let next_qty = 0;
  // let available_qty = 0;
  // let issued_qty = 0;
  // if (
  //   warehouseTransaction.type === TRANSACTION_TYPE_INCOMING ||
  //   warehouseTransaction.type === TRANSACTION_TYPE_OUTGOING ||
  //   warehouseTransaction.type === TRANSACTION_TYPE_DELIVERY
  // ) {
  //   warehouseTransaction?.warehouse_transaction_products?.forEach((element) => {
  //     qty += element.qty;
  //     available_qty += element.available_qty;
  //     next_qty += element.issued_qty;
  //   });
  // } else {
  //   warehouseTransaction?.warehouse_transaction_products?.forEach((element) => {
  //     qty += element.qty;
  //     available_qty += element.available_qty;
  //     element.linked_products.forEach((product: any) => {
  //       if (product.warehouse_transaction_type === 'Wms::OutgoingShipment')
  //         issued_qty += product.qty;
  //       else next_qty += product.qty;
  //     });
  //   });
  // }

  // const divStyle = {
  //   fontWeight: '500',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  // };

  // const name_mapping = {
  //   qty: {
  //     incoming_shipment: 'Requested / Ordered Qty',
  //     outgoing_shipment: 'Issued',
  //     receipt: 'Received',
  //   },
  //   next_qty: {
  //     incoming_shipment: 'Received',
  //     outgoing_shipment: 'Delivered',
  //     receipt: 'Delivered',
  //   },
  //   available_qty: {
  //     incoming_shipment: 'Pending - To Be Received',
  //     outgoing_shipment: 'Not Delivered',
  //     receipt: 'Available',
  //   },
  // };
  return (
    <>
      {/* {warehouseTransaction?.type !== TRANSACTION_TYPE_DELIVERY && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '58px',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              width: '60%',
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={divStyle}>
              <span style={{ fontSize: '20px' }}>{`${
                name_mapping.qty[warehouseTransaction.type]
              } Qty : ${qty}`}</span>
            </div>

            <Divider type={'vertical'} style={{ height: '50%', borderLeft: '1px solid black' }} />
            <div style={divStyle}>
              <span style={{ fontSize: '20px' }}>{`${
                name_mapping.next_qty[warehouseTransaction.type]
              } Qty : ${next_qty}`}</span>
            </div>

            {warehouseTransaction.type === TRANSACTION_TYPE_RECEIPT && (
              <>
                <Divider
                  type={'vertical'}
                  style={{ height: '50%', borderLeft: '1px solid black' }}
                />
                <div style={divStyle}>
                  <span style={{ fontSize: '20px' }}>{`Issued Qty : ${issued_qty}`}</span>
                </div>
              </>
            )}

            <Divider type={'vertical'} style={{ height: '50%', borderLeft: '1px solid black' }} />
            <div style={divStyle}>
              <span style={{ fontSize: '20px' }}>{`${
                name_mapping.available_qty[warehouseTransaction.type]
              } Qty : ${available_qty}`}</span>
            </div>
          </div>
        </div>
      )} */}
      <WMSProducts
        type={warehouseTransaction?.type}
        warehouseTransactionProducts={warehouseTransaction?.warehouse_transaction_products || []}
      />
    </>
  );
}
