import { convertValuesToDayJs, dayjs } from '@shipmnts/pixel-hub';
import { convertToDayJs } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  filter as _filter,
  keyBy as _keyBy,
  omit as _omit,
  pick as _pick,
  uniqueId as _uniqueId,
  groupBy as _groupBy,
  startCase,
} from 'lodash';
import {
  BUSINESS_TYPE_DIRECT,
  CARGO_TYPE_CONTAINER,
  DUTY_FREE,
  FREIGHT_TYPE_OCEAN,
  HOME_BILL,
  MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
  RELEASE_TYPE_SOB,
  SERVICE_TYPE_PORT_TO_PORT,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_DIRECT,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
} from '../../constants';
import {
  BOOKING_TYPE_SELF,
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_VENDOR,
  LOAD_TYPE_FCL,
  STUFFING_LOCATION_TYPE_CFS,
  STUFFING_LOCATION_TYPE_FACTORY,
  STUFFING_LOCATION_TYPE_ICD,
  VOLUME_UNIT_CBM,
  WEIGHT_UNIT_KGS,
} from 'operations/baseConstants';
import { getRoutingDetails } from '../ShipmentForm/helpers';
import { MODE_OF_TRANSIT_RAIL, RoutingLegValue } from 'operations/models/RoutingLeg';
import { RoutingDetailsValue, RoutingNodesHashValue } from 'operations/components/RoutingDetails/';
import { InquiryOptionValue } from 'operations/models/InquiryOption';
import { convertNumber } from 'sales_hub/utils/dimensionHelper';
import { FREIGHT_TYPE_AIR, LOAD_TYPE_LOOSE } from 'operations/utils/constants';
import {
  RATE_CLASS_QUANTITY,
  SHIPMENT_DOCUMENT_TYPE_HOUSE,
  SHIPMENT_DOCUMENT_TYPE_MASTER,
} from 'operations/modules/reports/constants';
import { InquiryValue } from 'operations/models/Inquiry';
import { SessionDataValue } from 'operations/models/SessionData';
import { getNewRoutingNode } from 'operations/components/RoutingDetails/';
import { RoutingNodeTag, RoutingNodeValue } from 'operations/models/RoutingNode';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { getInitialValueForCargoProperties } from '../DetailLayout/Cargo/CargoNewComponent/helpers';
const getRoutingDetailsFormValue = (
  routingLegs: RoutingLegValue[],
  noCopyLeg?: boolean
): RoutingDetailsValue => {
  const routing_nodes: RoutingNodesHashValue = {};
  let routing_legs = routingLegs ? routingLegs : [];
  routing_legs = routing_legs.map((rl) => {
    if (rl?.origin?.id)
      routing_nodes[rl.origin.id] = { ...rl.origin, _id: rl.origin.id, id: undefined };
    if (rl?.destination?.id)
      routing_nodes[rl.destination.id] = {
        ...rl.destination,
        _id: rl.destination.id,
        id: undefined,
      };
    return {
      ...{ ...rl, _id: rl.id, id: undefined },
      origin: undefined,
      destination: undefined,
      origin_id: rl?.origin?.id,
      destination_id: rl?.destination?.id,
    };
  });
  const omitFields = [
    'estimated_time_of_arrival',
    'actual_time_of_arrival',
    'estimated_time_of_departure',
    'actual_time_of_departure',
    'vessel',
    'voyage_number',
    'wagon_number',
  ];
  return {
    routing_legs: noCopyLeg
      ? (routing_legs.map((rl) => _omit<RoutingLegValue>(rl, omitFields)) as RoutingLegValue[])
      : routing_legs,
    routing_nodes: routing_nodes,
  };
};

const getDefaultInitialValue = (extraValue: any, sessionData: any) => {
  const is_clearance_shipment = extraValue?.clearance_shipment;
  const is_freight_forwarding_shipment = extraValue?.freight_forwarding_shipment;
  return {
    business_type: BUSINESS_TYPE_DIRECT,
    movement_mode: MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
    load_type: LOAD_TYPE_FCL,
    cargo_type: CARGO_TYPE_CONTAINER,
    freight_type: FREIGHT_TYPE_OCEAN,
    trade_type: TRADE_TYPE_EXPORT,
    shipment_type: SHIPMENT_TYPE_DIRECT,
    shipment_containers: [{}, {}],
    valid_till_date: dayjs().add(3, 'day').endOf('day'),
    cargos: [
      {
        outer_package_type: 'Box',
        weight_unit: WEIGHT_UNIT_KGS,
        volume_unit: VOLUME_UNIT_CBM,
      },
    ],
    booking_party: {
      party_company: sessionData?.company_account?.default_company,
    },
    services: {
      freight_forwarding: is_freight_forwarding_shipment ? true : false,
      cargo_insurance: false,
      empty_container_insurance: false,
      origin: {
        clearance:
          extraValue?.trade_type === TRADE_TYPE_EXPORT && is_clearance_shipment ? true : false,
        transport: false,
        fumigation: false,
        palletization: false,
      },
      destination: {
        clearance:
          extraValue?.trade_type !== TRADE_TYPE_EXPORT && is_clearance_shipment ? true : false,
        transport: false,
      },
    },
  };
};

const getDefaultParties = (extraValue: any, sessionData: SessionDataValue | undefined) => {
  const is_clearance_shipment = extraValue?.clearance_shipment;
  const is_freight_forwarding_shipment = extraValue?.freight_forwarding_shipment;
  return {
    origin_clearance_agent: {
      party_company:
        is_clearance_shipment && extraValue?.trade_type === TRADE_TYPE_EXPORT
          ? sessionData?.company_account?.default_company
          : undefined,
    },
    destination_clearance_agent: {
      party_company:
        is_clearance_shipment && extraValue?.trade_type !== TRADE_TYPE_EXPORT
          ? sessionData?.company_account?.default_company
          : undefined,
    },
    freight_forwarding_agent: {
      party_company: is_freight_forwarding_shipment
        ? sessionData?.company_account?.default_company
        : undefined,
    },
    origin_agent: {
      party_company:
        extraValue?.trade_type === TRADE_TYPE_EXPORT
          ? sessionData?.company_account?.default_company
          : undefined,
    },
    switch_bl_issuing_agent: {
      party_company:
        extraValue?.trade_type === TRADE_TYPE_EXPORT
          ? sessionData?.company_account?.default_company
          : undefined,
    },
    destination_agent: {
      party_company:
        extraValue?.trade_type === TRADE_TYPE_IMPORT
          ? sessionData?.company_account?.default_company
          : undefined,
    },
  };
};

export function getRoutingValues(
  shipment?: ShipmentValue | undefined | null,
  oto?: OceanTransportOrderValue | undefined | null
) {
  const routing_legs = shipment?.routing_legs || oto?.routing_legs;
  if (!routing_legs) return {};
  let stuffing_node = null;
  let destuffing_node = null;

  const pickup_stuffing_node = routing_legs.find((leg) => leg.routing_type === 'pickup')?.origin;
  if (pickup_stuffing_node?.tags?.includes('stuffing_location')) {
    stuffing_node = pickup_stuffing_node;
  }
  const delivery_destuffing_node = routing_legs.find(
    (leg) => leg.routing_type === 'delivery'
  )?.destination;
  if (delivery_destuffing_node?.tags?.includes('destuffing_location')) {
    destuffing_node = delivery_destuffing_node;
  }
  if (!stuffing_node) {
    const pre_carriage_legs = (routing_legs || [])
      .filter((leg) => leg.routing_type === 'pre_carriage')
      ?.sort((a, b) => (a?.sequence_number || 0) - (b?.sequence_number || 0));
    if (pre_carriage_legs) {
      const pcfl_origin = pre_carriage_legs[0]?.origin;
      if (pcfl_origin?.tags?.includes('stuffing_location')) {
        stuffing_node = pcfl_origin;
      }
    }
  }

  if (!destuffing_node) {
    const on_carriage_legs = (routing_legs || [])
      .filter((leg) => leg.routing_type === 'on_carriage')
      ?.sort((a, b) => (a?.sequence_number || 0) - (b?.sequence_number || 0));
    if (on_carriage_legs) {
      const onll_dest = on_carriage_legs[on_carriage_legs.length - 1]?.destination;
      if (onll_dest?.tags?.includes('destuffing_location')) {
        destuffing_node = onll_dest;
      }
    }
  }
  const destination_cfs = routing_legs.find(
    (leg) => leg.routing_type === 'pod_buffer'
  )?.destination;
  const origin_cfs = routing_legs.find((leg) => leg.routing_type === 'pol_buffer')?.origin;
  const routing_values: any = {};
  if (origin_cfs) {
    routing_values['pol_buffer_location'] = origin_cfs.location;
  }
  if (destination_cfs) {
    routing_values['pod_buffer_location'] = destination_cfs.location;
  }
  if (stuffing_node) {
    let stuffing_type = undefined;
    if (stuffing_node?.location?.type === 'CFS') {
      stuffing_type = STUFFING_LOCATION_TYPE_CFS;
    } else if (stuffing_node?.location?.type === 'ICD') {
      stuffing_type = STUFFING_LOCATION_TYPE_ICD;
    } else if (stuffing_node?.address) {
      stuffing_type = STUFFING_LOCATION_TYPE_FACTORY;
    }
    routing_values['stuffing_type'] = stuffing_type;
    if (stuffing_node?.address) {
      routing_values['stuffing_location'] = {
        party_address: stuffing_node?.address,
        party_company: stuffing_node?.company,
      };
    } else {
      routing_values['stuffing_location'] = stuffing_node?.location;
    }
  }
  if (destuffing_node) {
    let destuffing_type = undefined;
    if (destuffing_node?.location?.type === 'CFS') {
      destuffing_type = STUFFING_LOCATION_TYPE_CFS;
    } else if (destuffing_node?.location?.type === 'ICD') {
      destuffing_type = STUFFING_LOCATION_TYPE_ICD;
    } else if (destuffing_node?.address) {
      destuffing_type = STUFFING_LOCATION_TYPE_FACTORY;
    }
    routing_values['destuffing_type'] = destuffing_type;
    if (destuffing_node?.address) {
      routing_values['destuffing_location'] = {
        party_address: destuffing_node?.address,
        party_company: destuffing_node?.company,
      };
    } else {
      routing_values['destuffing_location'] = destuffing_node?.location;
    }
  }
  return routing_values;
}

const getGenericInitialValue = (
  shipment: ShipmentValue | undefined | null,
  extraValue?: any,
  sessionData?: SessionDataValue | undefined
) => {
  const buyer_id = shipment?.shipment_parties?.find((party) => party.name === 'buyer')
    ?.party_company?.id;
  const seller_id = shipment?.shipment_parties?.find((party) => party.name === 'seller')
    ?.party_company?.id;
  const shipper_id = shipment?.shipment_parties?.find((party) => party.name === 'shipper')
    ?.party_company?.id;
  const consignee_id = shipment?.shipment_parties?.find((party) => party.name === 'consignee')
    ?.party_company?.id;
  const notify_party_id = shipment?.shipment_parties?.find(
    (party) => party.name === 'notify_party_1'
  )?.party_company?.id;
  const notify_party_1_house = shipment?.shipment_parties?.find(
    (party) => party.name === 'notify_party_1_house'
  )?.party_company?.id;
  const customer_id = shipment?.customer_company?.id;
  const job_date =
    new Date(shipment?.job_date || '').getTime() / 1000 || dayjs.unix(Date.now() / 1000);
  const default_master_document_values = {
    shipment_document: {
      release_type: RELEASE_TYPE_SOB,
      charge_terms: {
        freight_terms: 'prepaid',
        other_charges_terms: 'prepaid',
      },
      document_status:
        extraValue?.trade_type === TRADE_TYPE_EXPORT ? 'pending_creation' : 'draft_received',
      bl_type: 'original',
      shipper_on_document:
        extraValue?.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK ? 'origin_agent' : 'shipper',
      consignee_on_document:
        extraValue?.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK
          ? 'destination_agent'
          : 'consignee',
    },
  };
  const ocean_transport_order = shipment?.ocean_transport_orders?.[0];
  const default_house_document_values = {
    shipment_document: {
      release_type: RELEASE_TYPE_SOB,
      charge_terms: {
        freight_terms: 'prepaid',
        other_charges_terms: 'prepaid',
      },
      consignee_on_document: 'consignee',
      document_status:
        extraValue?.trade_type === TRADE_TYPE_EXPORT ? 'pending_creation' : 'draft_received',
      bl_type: 'original',
      shipper_on_document: 'shipper',
    },
  };
  const exportClearance = _filter(shipment?.shipment_custom_details || [], {
    trade_type: 'export',
  });
  const importClearance = _filter(shipment?.shipment_custom_details || [], {
    trade_type: 'import',
  });
  const date_fields = [
    'valid_till_date',
    'gate_open_date',
    'si_cutoff_date',
    'vgm_cutoff_date',
    'ams_cutoff_date',
    'icd_cutoff_date',
    'gate_close_date',
    'doc_cutoff_date',
    'booking_date',
  ];

  const oto_initial_value = ocean_transport_order
    ? {
        ...ocean_transport_order,
        booking_party: {
          party_company: ocean_transport_order?.customer_company,
          party_address: ocean_transport_order?.customer_address,
        },
        vendor: {
          party_company:
            ocean_transport_order?.vendor_company ||
            shipment?.getShipmentPartyByName('origin_agent')?.party_company,
          party_address:
            ocean_transport_order?.vendor_address ||
            shipment?.getShipmentPartyByName('origin_agent')?.party_address,
        },
        ...convertValuesToDayJs(ocean_transport_order as OceanTransportOrderValue, date_fields),
        rate_type:
          ocean_transport_order?.booking_type !== BOOKING_TYPE_SELF
            ? ocean_transport_order?.rate_type
            : undefined,
      }
    : {};

  const initialValue = {
    ...getDefaultInitialValue(extraValue, sessionData),
    will_issue_switch_bl: shipment?.will_issue_switch_bl,
    business_vertical: shipment?.business_vertical,
    load_type: extraValue?.freight_type === FREIGHT_TYPE_AIR ? LOAD_TYPE_LOOSE : LOAD_TYPE_FCL,
    vendor:
      extraValue?.trade_type === TRADE_TYPE_IMPORT
        ? {
            party_company: sessionData?.company_account?.default_company,
            party_address: null,
          }
        : {},
    booking_type:
      ocean_transport_order?.booking_type ||
      getBookingType(extraValue?.trade_type, extraValue?.loadType),
    job_date: convertToDayJs(job_date),
    ...oto_initial_value,
    ...shipment,
    ...getRoutingValues(shipment),
    consignee_party_name: shipment ? shipment?.consignee_party_name : 'consignee',
    estimated_time_of_arrival_at_fpod: convertToDayJs(shipment?.estimated_time_of_arrival_at_fpod),
    buyer_same_as_consignee: buyer_id && consignee_id && consignee_id === buyer_id ? true : false,
    seller_same_as_shipper: seller_id && shipper_id && shipper_id === seller_id ? true : false,
    shipper_same_as_customer:
      shipper_id && customer_id && shipper_id === customer_id ? true : false,
    consignee_same_as_customer:
      consignee_id && customer_id && consignee_id === customer_id ? true : false,
    notify_party_house_same_as_consignee:
      notify_party_1_house && consignee_id && consignee_id === notify_party_1_house ? true : false,
    notify_party_same_as_consignee:
      notify_party_id && consignee_id && consignee_id === notify_party_id ? true : false,
    priority: shipment ? shipment?.priority : 'Medium',
    customer: shipment?.customer_company
      ? {
          party_company: shipment?.customer_company,
          party_address: shipment?.customer_address,
        }
      : undefined,
    valid_till_date: dayjs().add(3, 'day').endOf('day'),
    involved_branch_id: shipment?.involved_branch?.id,
    vessel: shipment?.ocean_vessel,
    lfd_at_carrier: convertToDayJs(shipment?.lfd_at_carrier),
    lfd_at_empty_return: convertToDayJs(shipment?.lfd_at_empty_return),
    lfd_at_pocd: convertToDayJs(shipment?.lfd_at_pocd),
    lfd_at_pod: convertToDayJs(shipment?.lfd_at_pod),
    shipping_bill_details:
      exportClearance.length > 0
        ? exportClearance.map((sb: any) => ({
            id: sb.id,
            document_number: sb.custom_document_number,
            document_date: convertToDayJs(sb.custom_document_date),
            country: sb.country || sb.custom_clearance_location?.country_code,
            custom_clearance_location: sb.custom_clearance_location,
            trade_type: sb.trade_type,
          }))
        : extraValue?.clearance_shipment && extraValue?.trade_type === TRADE_TYPE_EXPORT
        ? [{}]
        : null,
    import_custom_details:
      importClearance.length > 0
        ? importClearance.map((sb: any) => ({
            id: sb.id,
            document_number: sb.custom_document_number,
            document_date: convertToDayJs(sb.custom_document_date),
            country: sb.country || sb.custom_clearance_location?.country_code,
            custom_clearance_location: sb.custom_clearance_location,
            trade_type: sb.trade_type,
          }))
        : extraValue?.clearance_shipment && extraValue?.trade_type === TRADE_TYPE_IMPORT
        ? [{}]
        : null,
    shipment_invoices: (shipment?.shipment_invoices || []).map((si) => ({
      id: si.id,
      document_number: si.invoice_number,
      document_date: convertToDayJs(si.invoice_date),
      document_currency: si.invoice_currency,
      document_amount: si.invoice_amount,
    })),
    party: shipment?.shipment_parties
      ? {
          ..._keyBy(shipment?.shipment_parties, 'name'),
        }
      : getDefaultParties(extraValue, sessionData),
    shipment_events: getShipmentEventInitialValue(shipment),
    ...extraValue,
    ...getDOInitialValues(shipment, oto_initial_value),
  };
  if (shipment) {
    initialValue['routing_details'] = getRoutingDetails(shipment);
    initialValue['cargo_properties'] = getInitialValueForCargoProperties(shipment?.cargos);
  }
  if (!shipment?.ocean_transport_orders) {
    initialValue['booking_date'] =
      new Date(shipment?.job_date || '').getTime() / 1000 || dayjs.unix(Date.now() / 1000);
    initialValue['service_type'] = SERVICE_TYPE_PORT_TO_PORT;
  }
  if (!!extraValue?.clearance_shipment && !!shipment?.getOtoBookingFromShipment()) {
    initialValue['ocean_transport_order'] = shipment?.getOtoBookingFromShipment();
  }
  if (
    shipment?.shipment_documents &&
    shipment?.shipment_documents.find((doc) => doc.document_type === SHIPMENT_DOCUMENT_TYPE_MASTER)
  ) {
    const document = shipment?.shipment_documents.find(
      (doc) => doc.document_type === SHIPMENT_DOCUMENT_TYPE_MASTER
    );
    initialValue['master'] = {
      shipment_document: {
        ...(shipment?.shipment_documents && document),
        shipment_number:
          shipment?.shipment_documents &&
          (shipment.freight_type === 'air'
            ? document?.shipment_number?.slice(3)
            : document?.shipment_number),
        shipment_date: shipment?.shipment_documents && convertToDayJs(document?.shipment_date),
        id: shipment?.shipment_documents && document?.id,
        bl_type: shipment?.shipment_documents && document?.bl_type ? document?.bl_type : 'original',
      },
    };
  } else {
    initialValue['master'] = default_master_document_values;
  }
  if (
    shipment?.shipment_documents &&
    shipment?.shipment_documents.find((doc) => doc.document_type === SHIPMENT_DOCUMENT_TYPE_HOUSE)
  ) {
    const document = shipment?.shipment_documents.find(
      (doc) => doc.document_type === SHIPMENT_DOCUMENT_TYPE_HOUSE
    );
    initialValue['house'] = {
      shipment_document: {
        ...(shipment?.shipment_documents && document),
        shipment_number:
          shipment?.shipment_documents &&
          (shipment.freight_type === 'air'
            ? document?.shipment_number?.slice(3)
            : document?.shipment_number),
        shipment_date: document && convertToDayJs(document.shipment_date),
        id: document && document.id,
        bl_type: document && document.bl_type ? document.bl_type : 'original',
      },
    };
  } else {
    initialValue['house'] = default_house_document_values;
  }
  return initialValue;
};

export const getGenericInitialValueForDuplicate = (
  shipment: any,
  extraValue?: any,
  sessionData?: SessionDataValue | undefined
) => {
  const oto =
    shipment?.ocean_transport_orders?.find(
      (oto: any) => oto?.booking_type === BOOKING_TYPE_SHIPPING_LINE
    ) ?? shipment?.ocean_transport_orders?.[0];
  const job_date = new Date().getTime() / 1000;
  const ocean_transport_order = shipment?.ocean_transport_orders?.[0];
  const containerMap: any = _groupBy(shipment?.shipment_containers, 'container_type_code');
  // eslint-disable-next-line no-empty-pattern
  let shipmentContainerQuantity: any[] | undefined = ({} = []);
  Object.keys(containerMap).forEach((key) => {
    shipmentContainerQuantity?.push({
      _id: _uniqueId('cr_'),
      container_type_code: containerMap[key][0].container_type_code,
      container_type: containerMap[key][0].container_type,
      quantity: containerMap[key].length,
      container_settings: containerMap[key][0].container_settings,
    });
  });
  if (shipmentContainerQuantity.length === 0) shipmentContainerQuantity = undefined;

  const exportClearance = _filter(shipment?.shipment_custom_details || [], {
    trade_type: 'export',
  });
  const importClearance = _filter(shipment?.shipment_custom_details || [], {
    trade_type: 'import',
  });
  const initialValue: any = {
    ...getDefaultInitialValue(extraValue, sessionData),
    ...getRoutingValues(shipment),
    ...extraValue,
    business_vertical: shipment?.business_vertical,
    customer: shipment?.customer_company
      ? {
          party_company: shipment?.customer_company,
          party_address: shipment?.customer_address,
        }
      : undefined,
    load_type: shipment?.load_type,
    booking_type:
      ocean_transport_order?.booking_type ||
      getBookingType(shipment?.trade_type, shipment?.load_type),
    consignee_party_name: shipment ? shipment?.consignee_party_name : 'consignee',
    valid_till_date: dayjs().add(3, 'day').endOf('day'),
    job_date: convertToDayJs(job_date) || dayjs(),
    business_type: shipment?.business_type || BUSINESS_TYPE_DIRECT,
    involved_branch_id: shipment?.involved_branch?.id,
    involved_branch: shipment?.involved_branch,
    sales_agent: shipment?.sales_agent,
    incoterms: shipment?.incoterms,
    lfd_at_pod: convertToDayJs(shipment?.lfd_at_pod),
    lfd_at_carrier: convertToDayJs(shipment?.lfd_at_carrier),
    lfd_at_empty_return: convertToDayJs(shipment?.lfd_at_empty_return),
    lfd_at_pocd: convertToDayJs(shipment?.lfd_at_pocd),
    destination_carrier_free_days: shipment?.destination_carrier_free_days,
    destination_port_free_days: shipment?.destination_port_free_days,
    origin_carrier_free_days: shipment?.origin_carrier_free_days,
    cargos: (shipment?.cargos || []).map(({ id, ...cargo }: any) => ({
      ...cargo,
      ..._pick(cargo, [
        'dimension_unit',
        'gross_volume',
        'height',
        'length',
        'outer_package_qty',
        'outer_per_packet_wt',
        'outer_package_type',
        'width',
      ]),
    })) || [
      {
        outer_package_type: 'Box',
        weight_unit: WEIGHT_UNIT_KGS,
        volume_unit: VOLUME_UNIT_CBM,
      },
    ],
    cargo_properties: getInitialValueForCargoProperties(shipment?.cargos),
    master: {
      shipment_document: {
        release_type: RELEASE_TYPE_SOB,
        charge_terms: {
          freight_terms: 'prepaid',
          other_charges_terms: 'prepaid',
        },
        consignee_on_document: 'consignee',
        document_status:
          shipment?.trade_type === TRADE_TYPE_EXPORT ? 'pending_creation' : 'draft_received',
        bl_type: 'original',
        shipment_date:
          shipment?.shipment_documents &&
          convertToDayJs(shipment?.shipment_documents[0]?.shipment_date),
      },
    },
    house: {
      shipment_document: {
        release_type: RELEASE_TYPE_SOB,
        charge_terms: {
          freight_terms: 'prepaid',
          other_charges_terms: 'prepaid',
        },
        consignee_on_document: 'consignee',
        document_status:
          shipment?.trade_type === TRADE_TYPE_EXPORT ? 'pending_creation' : 'draft_received',
        bl_type: 'original',
        shipment_date:
          shipment?.shipment_documents &&
          convertToDayJs(shipment?.shipment_documents[1]?.shipment_date),
      },
    },
    shipping_bill_details:
      exportClearance.length > 0
        ? exportClearance.map((sb: any) => ({
            document_number: sb.custom_document_number,
            document_date: convertToDayJs(sb.custom_document_date),
            country: sb.country || sb.custom_clearance_location?.country_code,
            custom_clearance_location: sb.custom_clearance_location,
            trade_type: sb.trade_type,
          }))
        : null,
    import_custom_details:
      importClearance.length > 0
        ? exportClearance.map((sb: any) => ({
            document_number: sb.custom_document_number,
            document_date: convertToDayJs(sb.custom_document_date),
            country: sb.country || sb.custom_clearance_location?.country_code,
            custom_clearance_location: sb.custom_clearance_location,
            trade_type: sb.trade_type,
          }))
        : null,
    shipment_invoices: (shipment?.shipment_invoices || []).map((si: any) => ({
      document_number: si.invoice_number,
      document_date: convertToDayJs(si.invoice_date),
      document_currency: si.invoice_currency,
      document_amount: si.invoice_amount,
    })),
    shipment_container_quantity: shipment?.shipment_container_quantity
      ? shipment?.shipment_container_quantity
      : shipmentContainerQuantity,
    party: shipment?.shipment_parties
      ? {
          ..._keyBy(
            shipment?.shipment_parties
              ?.filter((party: any) => !party.name.includes('switch_bl'))
              .map((sp: any) => _omit(sp, ['id'])),
            'name'
          ),
        }
      : getDefaultParties(extraValue, sessionData),
    voyage_schedule_id: oto?.voyage_schedule_id,
    carrier: shipment?.carrier,
    shipment_events: getShipmentEventInitialValue(shipment, true),
    service_type: oto?.service_type || shipment?.service_type || SERVICE_TYPE_PORT_TO_PORT,
    ...getDOInitialValues(shipment, oto),
  };
  if (!!extraValue?.clearance_shipment && !!shipment.getOtoBookingFromShipment()) {
    initialValue['ocean_transport_order'] = {
      ..._omit(shipment.getOtoBookingFromShipment(), 'id'),
    };
  }
  if (shipment?.services) {
    initialValue['services'] = shipment?.services;
  }

  if (shipment?.routing_legs) {
    const routingDetails = getRoutingDetailsFormValue(shipment?.routing_legs);

    if (routingDetails?.routing_legs?.length) {
      const firstMainCarriageLeg = routingDetails.routing_legs
        .filter((rl: any) => rl.routing_type === 'main_carriage')
        .sort((a: any, b: any) => (a.sequence_number || 0) - (b.sequence_number || 0))[0];

      if (firstMainCarriageLeg) {
        const estimatedTimeOfDeparture = dayjs(firstMainCarriageLeg.estimated_time_of_departure);
        const currentDate = dayjs();

        if (estimatedTimeOfDeparture.isBefore(currentDate)) {
          firstMainCarriageLeg.estimated_time_of_departure = null;
          firstMainCarriageLeg.voyage_number = null;
          initialValue['voyage_schedule_id'] = null;
        }
      }
    }
    initialValue['routing_details'] = {
      routing_nodes: routingDetails?.routing_nodes,
      routing_legs: (routingDetails?.routing_legs || []).map((rl: RoutingLegValue) =>
        _omit(rl, 'id')
      ),
    };
  }
  return initialValue;
};

const getShipmentEventInitialValue = (
  shipment: ShipmentValue | undefined | null,
  isDuplicate?: boolean
) => {
  const shipment_events: any = {};
  shipment?.shipment_events?.forEach((event: any) => {
    if (!(isDuplicate && event.name === 'bank_release_order')) {
      shipment_events[event.name] = {
        event_date: convertToDayJs(event?.event_date),
        event_number: event?.event_number,
      };
    }
  });
  return shipment_events;
};

export const getInitialValue = (
  shipment: ShipmentValue | undefined,
  extraValue: any,
  isDup: boolean,
  inquiryOption?: InquiryOptionValue,
  sessionData?: SessionDataValue
) => {
  if (inquiryOption) {
    return getIntialValuesFromInquiryOption(inquiryOption, extraValue?.shipment_type, sessionData);
  }
  if (isDup) {
    return getGenericInitialValueForDuplicate(shipment, extraValue, sessionData);
  }
  return getGenericInitialValue(shipment, extraValue, sessionData);
};

export const getIntialValuesFromInquiryOption = (
  inquiryOption: InquiryOptionValue,
  shipmentType: string,
  sessionData: any
) => {
  const default_company = sessionData?.company_account?.default_company;
  const inquiry = inquiryOption.inquiry;
  const default_master_document_values = {
    shipment_document: {
      release_type: RELEASE_TYPE_SOB,
      charge_terms: {
        freight_terms: 'prepaid',
        other_charges_terms: 'prepaid',
      },
      document_status: inquiry?.trade_type ? 'pending_creation' : 'draft_received',
      bl_type: 'original',
      shipper_on_document: shipmentType === SHIPMENT_TYPE_BACK_TO_BACK ? 'origin_agent' : 'shipper',
      consignee_on_document:
        shipmentType === SHIPMENT_TYPE_BACK_TO_BACK ? 'destination_agent' : 'consignee',
    },
  };
  const default_house_document_values = {
    shipment_document: {
      release_type: RELEASE_TYPE_SOB,
      charge_terms: {
        freight_terms: 'prepaid',
        other_charges_terms: 'prepaid',
      },
      consignee_on_document: 'consignee',
      document_status: inquiry?.trade_type ? 'pending_creation' : 'draft_received',
      bl_type: 'original',
      shipper_on_document: 'shipper',
    },
  };
  const fulfilled_container_map = JSON.parse(inquiry?.fulfilled_container_map);
  const inquiryValues: any = _pick(inquiry, [
    'freight_type',
    'trade_type',
    'load_type',
    'involved_branch',
    'incoterms',
    'customer_company',
    'customer_address',
    'billing_party',
    'billing_party_address',
    'business_received_through',
    'services',
    'origin_custom_clearance_location',
    'destination_custom_clearance_location',
    'freight_terms',
    'other_terms',
    'container_requests',
    'business_type',
    'gross_weight',
    'gross_volume',
    'shipment_documents',
    'total_number_of_packages',
    'cargos',
    'priority',
    'service_type',
    'movement_mode',
    'preferred_carriers',
    'shipment_parties',
  ]);

  const inquiryOptionValues: any = _pick(inquiryOption, [
    'ocean_vessel',
    'voyage_number',
    'carrier',
    'chargeable_weight',
    'tariff_rate',
    'carrier_product',
    'destination_carrier_free_days',
    'destination_port_free_days',
    'origin_carrier_free_days',
    'lfd_at_carrier',
    'lfd_at_empty_return',
    'lfd_at_pocd',
    'lfd_at_pod',
    'shipment_product_ratings',
    'port_of_loading',
    'port_of_discharge',
  ]);
  inquiryValues.business_type = inquiryValues.business_received_through;
  if (inquiryValues?.customer_address?.entity_type !== 'billing') {
    inquiryValues.customer_address = null;
  }
  inquiryValues['party'] = {};
  inquiryValues['priority'] = startCase(inquiryOption?.inquiry?.priority);

  inquiryValues.pickup_address_print = '';
  inquiryValues.final_place_of_delivery_print = '';

  const routing_remarks = JSON.parse(inquiry?.routing_remarks || '{}');
  if (inquiry?.freight_type === 'ocean') {
    if (inquiry?.origin && inquiry?.origin?.company) {
      // incase origin is address in inquiry
      inquiryValues['party']['pickup'] = {
        party_address: inquiry?.origin,
        party_company: inquiry?.origin?.company,
      };
    } else if (inquiry?.origin && !inquiry?.origin?.company) {
      // incase it is location type
      inquiryValues.pickup_address_print += `Pickup Location: ${inquiry?.origin?.name}\n`;
    }
    if (routing_remarks?.origin)
      inquiryValues.pickup_address_print += `${routing_remarks?.origin}\n`;

    if (
      inquiry?.destination &&
      !inquiry?.destination?.company &&
      inquiry?.destination?.type === 'City'
    ) {
      // if destination is a city, we have to add the final place of delivery
      inquiryValues.final_place_of_delivery = inquiry?.destination;
    } else if (inquiry?.destination?.company) {
      // else add it in remarks
      inquiryValues.final_place_of_delivery_print += `Final place of delivery:\n ${
        inquiry?.destination?.print_address || inquiry?.destination?.name
      }\n`;
    } else if (inquiry?.destination?.type !== 'City') {
      inquiryValues.final_place_of_delivery_print += `Final place of delivery: ${inquiry?.destination?.name}\n`;
    }
    if (routing_remarks?.destination)
      inquiryValues.final_place_of_delivery_print += `${routing_remarks?.destination}\n`;
  }

  if (inquiryValues.business_received_through !== 'direct') {
    const business_received_through_party_name =
      inquiryValues.business_received_through === 'agent_nomination'
        ? 'overseas_agent'
        : inquiryValues.business_received_through;
    inquiryValues['party'][`${business_received_through_party_name}`] = {
      party_company: inquiryValues.billing_party,
      party_address: inquiryValues.billing_party_address,
    };
  }
  if (inquiryValues?.services?.includes('origin_custom_clearance')) {
    inquiryValues['party']['origin_clearance_agent'] = {
      party_company: default_company,
    };
  }

  if (inquiryValues?.services?.includes('destination_custom_clearance')) {
    inquiryValues['party']['destination_clearance_agent'] = {
      party_company: default_company,
    };
  }

  if (inquiryValues?.services?.includes('freight_forwarding')) {
    inquiryValues['party']['freight_forwarding_agent'] = {
      party_company: default_company,
    };
  }

  if (inquiryValues?.trade_type === TRADE_TYPE_EXPORT) {
    inquiryValues['party']['origin_agent'] = {
      party_company: default_company,
    };
  }

  if (inquiryValues?.trade_type === TRADE_TYPE_IMPORT) {
    inquiryValues['party']['destination_agent'] = {
      party_company: default_company,
    };
  }

  if (inquiryValues.freight_type === FREIGHT_TYPE_OCEAN) {
    const pol_node = getNewRoutingNode({
      tags:
        inquiryOption?.place_of_carrier_receipt?.id &&
        inquiryOption?.port_of_loading?.id !== inquiryOption?.place_of_carrier_receipt?.id
          ? ['port_of_loading']
          : (['port_of_loading', 'place_of_carrier_receipt'] as Array<RoutingNodeTag>),
      location: inquiryOption?.port_of_loading,
    } as RoutingNodeValue);

    const pod_node = getNewRoutingNode({
      tags:
        inquiryOption?.place_of_carrier_delivery?.id &&
        inquiryOption?.port_of_discharge?.id !== inquiryOption?.place_of_carrier_delivery?.id
          ? (['port_of_discharge'] as Array<RoutingNodeTag>)
          : (['port_of_discharge', 'place_of_carrier_delivery'] as Array<RoutingNodeTag>),
      location: inquiryOption?.port_of_discharge,
    } as RoutingNodeValue);
    const main_carriage_leg: RoutingLegValue[] = [
      {
        routing_type: 'main_carriage',
        origin_id: pol_node?._id,
        destination_id: pod_node?._id,
        mode_of_transit:
          inquiryValues?.freight_type === 'ocean' ? 'sea' : inquiryValues?.freight_type,
        sequence_number: 2.1,
        estimated_time_of_arrival: convertToDayJs(inquiryOption?.estimated_time_of_arrival),
        estimated_time_of_departure: convertToDayJs(inquiryOption?.estimated_time_of_departure),
        vessel: inquiryOption?.ocean_vessel,
        voyage_number: inquiryOption?.voyage_number,
      } as RoutingLegValue,
    ];

    const routing_nodes = { [pol_node._id || '']: pol_node, [pod_node._id || '']: pod_node };
    let routing_legs = [...main_carriage_leg];

    if (
      inquiryOption?.place_of_carrier_receipt?.id &&
      inquiryOption?.port_of_loading?.id !== inquiryOption?.place_of_carrier_receipt?.id
    ) {
      const pcr_node = getNewRoutingNode({
        tags: ['place_of_carrier_receipt'] as Array<RoutingNodeTag>,
        location: inquiryOption?.place_of_carrier_receipt,
      } as RoutingNodeValue);
      const pre_carriage_leg = [
        {
          routing_type: 'pre_carriage',
          origin_id: pcr_node._id,
          destination_id: pol_node._id,
          mode_of_transit: MODE_OF_TRANSIT_RAIL,
          sequence_number: 1.1,
        } as RoutingLegValue,
      ];
      routing_legs = [...pre_carriage_leg, ...routing_legs];
      routing_nodes[pcr_node._id || ''] = pcr_node;
    }
    if (
      inquiryOption?.place_of_carrier_delivery?.id &&
      inquiryOption?.port_of_discharge?.id !== inquiryOption?.place_of_carrier_delivery?.id
    ) {
      const pcd_node = getNewRoutingNode({
        tags: ['place_of_carrier_delivery'] as Array<RoutingNodeTag>,
        location: inquiryOption?.place_of_carrier_delivery,
      } as RoutingNodeValue);
      const on_carriage_leg = [
        {
          routing_type: 'on_carriage',
          origin_id: pod_node._id,
          destination_id: pcd_node._id,
          mode_of_transit: MODE_OF_TRANSIT_RAIL,
          sequence_number: 3.1,
        } as RoutingLegValue,
      ];
      routing_legs = [...routing_legs, ...on_carriage_leg];
      routing_nodes[pcd_node._id || ''] = pcd_node;
    }
    inquiryValues['routing_details'] = {
      routing_nodes: routing_nodes,
      routing_legs: routing_legs,
    };
  }
  const weight_unit = inquiry?.cargos?.[0]?.weight_unit || 'kg';
  inquiryValues.gross_weight = convertNumber(
    sumValue(inquiry.cargos, 'gross_weight'),
    weight_unit,
    'kg'
  );
  inquiryValues.gross_volume = sumValue(inquiry.cargos, 'gross_volume');

  //  For b2b shipment we will prefill terms only for HBL
  if (shipmentType === 'back_to_back') {
    inquiryValues.house = default_house_document_values;
  }
  inquiryValues.master = default_master_document_values;
  inquiryValues.total_number_of_packages = sumValue(inquiry.cargos, 'total_packages');
  if (inquiry.freight_type === FREIGHT_TYPE_AIR) {
    inquiryOptionValues.shipment_product_ratings = [
      {
        id: null,
        cargo_type: inquiry.load_type,
        quantity: 1,
        rate_class: RATE_CLASS_QUANTITY.key,
        chargeable_weight: inquiryOption.chargeable_weight || inquiry.gross_weight,
        gross_weight: inquiry.gross_weight || inquiryOption.chargeable_weight,
        tariff_rate: inquiryOption.tariff_rate,
      },
    ];
  }
  const services = getServicesPayloadFromInquiry(inquiryValues);
  const customDetails = [];
  if (inquiryValues.origin_custom_clearance_location) {
    customDetails.push({
      country: inquiryValues.origin_custom_clearance_location.country_code,
      custom_clearance_location: inquiryValues.origin_custom_clearance_location,
      trade_type: 'export',
    });
  }
  if (inquiryValues.destination_custom_clearance_location) {
    customDetails.push({
      country: inquiryValues.destination_custom_clearance_location.country_code,
      custom_clearance_location: inquiryValues.destination_custom_clearance_location,
      trade_type: 'export',
    });
  }

  inquiryValues['cargos'] = inquiryValues['cargos'].map((c: any) => {
    const updatedCargo = _omit(c, ['id']);
    return updatedCargo;
  });

  inquiryValues['commodity_description'] = inquiryValues['cargos']?.[0]?.commodity_description;

  if (!!inquiryValues['shipment_parties']) {
    inquiryValues['party'] = {
      ...(inquiryValues['party'] || {}),
      ...(inquiryValues?.['shipment_parties'] || []).reduce((acc: any, party: any) => {
        const updatedParty = _omit(party, ['id']);
        acc[party.name] = updatedParty;
        return acc;
      }, {}),
    };

    delete inquiryValues['shipment_parties'];
  }

  return {
    ...getDefaultInitialValue({ freight_forwarding_shipment: true }, sessionData),
    ...inquiryValues,
    ...inquiryOptionValues,
    customer: {
      party_company: inquiryValues?.customer_company,
      party_address: inquiryValues?.customer_address,
    },
    booking_type: getBookingType(inquiryValues?.trade_type, inquiryValues?.load_type),
    shipment_custom_details: customDetails,
    estimated_time_of_departure: convertToDayJs(inquiryOption?.estimated_time_of_departure),
    estimated_time_of_arrival: convertToDayJs(inquiryOption?.estimated_time_of_arrival),
    sales_agent: inquiry.sales_person,
    business_vertical: inquiry.business_vertical,
    services: services,
    inquiry_option: inquiryOption,
    job_date: dayjs.unix(Date.now() / 1000),
    shipment_container_quantity: (inquiryOption.inquiry.container_requests || []).map((cr: any) => {
      return {
        ..._omit(cr, ['__typename', 'id']),
        quantity: cr.quantity - (fulfilled_container_map?.[cr.container_type_code] || 0),
        container_settings: _omit(cr.container_settings, '__typename'),
      };
    }),
    shipping_bill_details: [
      {
        trade_type: TRADE_TYPE_EXPORT,
        shipping_bill_type: DUTY_FREE,
        custom_clearance_location: inquiryValues?.origin_custom_clearance_location,
        country: inquiryOption?.port_of_loading?.country_code,
      },
    ],
    import_custom_details: [
      {
        trade_type: TRADE_TYPE_IMPORT,
        bill_of_entry_type: HOME_BILL,
        custom_clearance_location: inquiryValues?.destination_custom_clearance_location,
        country: inquiryOption?.port_of_discharge?.country_code,
      },
    ],
  };
};

function sumValue(arr: any[], attribute: string) {
  return arr.reduce((sum, cargo) => {
    sum += cargo[attribute] || 0;
    return sum;
  }, 0);
}

function getServicesPayloadFromInquiry(
  inquiry: InquiryValue
): Record<string, Record<string, boolean>> {
  const inquiryServiceMap: Record<string, boolean> = {};

  (inquiry.services || []).forEach((service) => {
    inquiryServiceMap[service] = true;
  });

  const shipmentServices: any = {};

  for (const key in INQUIRY_OPTION_SERVICES_MAP) {
    const value = INQUIRY_OPTION_SERVICES_MAP[key];
    if (Array.isArray(value)) {
      shipmentServices[key] = {};
      value.forEach((service) => {
        if (typeof service === 'string') {
          shipmentServices[key][service] = !!inquiryServiceMap[service];
        } else {
          const from = service['from'];
          const to = service['to'];
          shipmentServices[key][to] = !!inquiryServiceMap[from];
        }
      });
    } else {
      shipmentServices[key] = !!inquiryServiceMap[key];
    }
  }

  return shipmentServices;
}

type ServiceMapping = { from: string; to: string } | string;
interface InquiryOptionServicesMap {
  [key: string]: ServiceMapping[] | boolean;
}

export const INQUIRY_OPTION_SERVICES_MAP: InquiryOptionServicesMap = {
  origin: [
    { from: 'origin_custom_clearance', to: 'clearance' },
    { from: 'origin_transport', to: 'transport' },
    'palletization',
    'fumigation',
  ],
  destination: [
    { from: 'destination_transport', to: 'transport' },
    { from: 'destination_custom_clearance', to: 'clearance' },
  ],
  cargo_insurance: true,
  freight_forwarding: true,
  empty_container_insurance: true,
};

/**
 * Get booking type for the shipment
 * @param trade_type : string | undefined
 * @param load_type : string | undefined
 * @returns Booking type for the shipment
 */
export const getBookingType = (trade_type: string | undefined, load_type: string | undefined) => {
  return trade_type === TRADE_TYPE_EXPORT && load_type === LOAD_TYPE_FCL
    ? BOOKING_TYPE_SHIPPING_LINE
    : BOOKING_TYPE_VENDOR;
};

export const getDOInitialValues = (
  shipment?: ShipmentValue | null,
  oto?: OceanTransportOrderValue
) => {
  return {
    carrier_do_number: shipment?.carrier_do_number,
    carrier_validity_date: convertToDayJs(shipment?.carrier_validity_date),
    empty_return_location: oto?.empty_return_location,
    surveyor: {
      party_company: oto?.surveyor_company,
      party_address: oto?.surveyor_address,
    },
  };
};
